import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { KTSVG } from '../../_metronic/helpers'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { usePageData } from '../../_metronic/layout/core';
import { Spinner, Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DatePicker, {registerLocale} from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it"; // the locale you want

registerLocale("it", it); // register it with the name you want


const SystemLogList = () => {
  const [workCycles, setWorkCycles] = useState();
  //const [modalShow, setModalShow] = useState();  
  //const [modalMessage, setModalMessage] = useState();  

  const [isError, setIsError] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();

  const { setPageTitle, pageMachines } = usePageData();
  const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();

  const [modalFilterShow, setModalFilterShow] = useState(false);  

  const [tempFilter, setTempFilter]= useState();

  let emptyFilter = {
    id:'',
    messaggio:'',
    dataInizio:'',
    dataFine:'',
    livello:''
  };

  const [filter, setFilter] = useState(emptyFilter);    

  //Pagination Handling
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const paginationComponentOptions = {
    rowsPerPageText: 'Elementi per pagina',
    rangeSeparatorText: 'di',
    selectAllRowsItem: false
  };

  const columns = [
    {
      name: 'Id',
      selector: row => row.id,
      width: '100px',
      cell: (row, index, column, id) => {
        return (<Link to={`/settings/systemLog/${row?.id}`} className='text-dark text-hover-primary fs-6'>{row?.id}</Link>
        )
      }
    },
    {
      name: 'Messaggio',
      selector: row => row.message,
      cell: (row, index, column, id) => {
        return (<Link to={`/settings/systemLog/${row?.id}`} className='text-dark text-hover-primary fs-6'>{row?.message}</Link>
        )
      }
    },
    {
      name: 'Data',
      selector: row => row.timeStampStr,
      maxWidth: '160px',
      cell: (row, index, column, id) => {
        return (<Link to={`/settings/systemLog/${row?.id}`} className='text-dark text-hover-primary fs-6'>{row?.timeStampStr ? row?.timeStampStr : '-'}</Link>
        )
      }
    },
    {
      name: 'Livello',
      selector: row => row.level,
      maxWidth: '130px',
      cell: (row, index, column, id) => {
        return (<Link to={`/settings/systemLog/${row?.id}`} className='text-dark text-hover-primary fs-6'>{row?.level}</Link>
        )
      }
    },
    {
      name: 'Azioni',
      maxWidth: '100px',
      right: true,
      ignoreRowClick: true,
      cell: (row, index, column, id) => {
        return (
          <div className='row'>
            <Link to={`/settings/systemLog/${row?.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <i className="fas fa-eye"></i>
            </Link>
          </div>)
      }
    },
  ];
  //Setting Page BreadCrumb
  useEffect(() => {      
    
    setPageTitle("Impostazioni");
    setPageBreadcrumbs(["Log di sistema"]);

  }, [])

  useEffect(() => {

    getSystemLogList(1, perPage, filter);

  }, [])

  const handleFilterClose = () => {
    
    //Se la finestra viene chiusa senza ne applicare ne resettare il filtro, allora i filtri impostati dal momento dell'apertura 
    //della finestra del filtro vengono persi e reimpostati quelli precedentemente applicati
    setFilter(tempFilter);
 
   setModalFilterShow(false);
  }    

  const showFilterModal = () => {

    //Salvataggio in memoria del filtro attuale. 
    //Questo viene fatto perchè se non viene applicato o resettato, il filtro viene reimpostato
    //A quello al momento dell'apertura della finestra

    setTempFilter(filter);

    setModalFilterShow(true);
   }  

   const resetFilter = () => {

    setFilter(emptyFilter);
    getSystemLogList(1, perPage, emptyFilter);

    setModalFilterShow(false);
   }       

   const applyFilter = () => {
    getSystemLogList(1, perPage, filter);
    setModalFilterShow(false);
  }  

   const setFilterProperty = (attribute, value, event) => {

      if (attribute == "id") 
      {
        if(event.target.validity.valid)
        {
          setFilter({...filter, id:value});
        }
      }
      else if (attribute == "messaggio") 
      {
          setFilter({...filter, messaggio:value});
      }
      else if (attribute == "dataInizio") 
      {
          setFilter({...filter, dataInizio:value});
      }
      else if (attribute == "dataFine") 
      {
          setFilter({...filter, dataFine:value});
      }
      else if (attribute == "livello") 
      {
          setFilter({...filter, livello:value});
      }
   }

  const getSystemLogList = (page, pageSize, pageFilter) => {

    console.log(pageFilter);

    setIsLoading(true);

    let isMounted = true;
    const controller = new AbortController();

    let systemLogListRequest =
    {
      page: page,
      pageSize: pageSize,
      filterId: pageFilter.id,
      filterMessaggio: pageFilter.messaggio,
      filterDataInizio: pageFilter.dataInizio,
      filterDataFine: pageFilter.dataFine,
      filterLivello: pageFilter.livello
    }

    const fetchWorkCycleList = async () => {

      try {
        const response = await axiosPrivate.post(`/systemlogs/getAll`,  
        JSON.stringify(systemLogListRequest), 
        {
            headers: {'Content-Type':'application/json'},
            withCredentials: true,
            signal:controller.signal
        });

        //console.log(response.data);
        if (isMounted) {
          setWorkCycles(response.data.data);
          setTotalRows(response.data.total);
          setIsLoading(false);
          setIsError(false);
        }

      }
      catch (err) {
        console.error(err);
        setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
        setIsLoading(false);
        setIsError(true);

        // if(err.response?.status === 403) 
        // {
        //     navigate('/login', {state : {from: location }, replace: true});
        // }
      }
    }

    fetchWorkCycleList();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }

  const handlePageChange = page => {
		getSystemLogList(page, perPage, filter);
    setCurrentPage(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {

    getSystemLogList(page, newPerPage, filter);
    setPerPage(newPerPage);
	};  


  return (
    <div id="kt_content_container" className="container-xxl py-2">

      {/* begin::Spinner */}
      {isLoading &&
        <div className="row d-flex justify-content-center m-20">
          <Spinner animation="border" variant="primary" />
        </div>
      }
      {/* end::Spinner */}

      {/* begin::Error message */}
      {!isLoading && isError &&

        <div className="row d-flex justify-content-center m-20">

          <div className="alert alert-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

            <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
              </svg>
            </span>

            <div className="text-center">
              <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
              <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
              <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
            </div>
          </div>

        </div>

      }
      {/* end::Error message */}

      {/* begin::Table */}
      {!isLoading && !isError &&
        <>

          <div className='card mb-5 mt-5 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Elenco Log di sistema</span>
              </h3>
              <div className="card-toolbar">

                <div className='btn btn-primary me-2 mt-1' onClick={(e) => showFilterModal()}>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-search"></i>
                  </span>
                  Filtro
                </div>

                <div className='btn btn-secondary me-2 mt-1' onClick={(e) => getSystemLogList(1, perPage, filter)}>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-sync"></i>
                  </span>
                  Aggiorna Elenco
                </div>
              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>

              <div className='row mb-2'>
                <DataTable
                  columns={columns}
                  data={workCycles}
                  pagination
                  paginationServer

                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationDefaultPage={currentPage}
                  paginationPerPage={perPage}
                  paginationComponentOptions={paginationComponentOptions}
                  paginationRowsPerPageOptions={[10, 25, 50, 100]}

                />
              </div>
            </div>
          </div>
        </>
      }

      <Modal show={modalFilterShow} onHide={handleFilterClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filtri di Ricerca</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">

            <div className="row">
              <div className="mb-10 col-6">
                <label className="form-label">ID</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="ID"
                  pattern="[0-9]*"
                  value={filter?.id} 
                  onChange={(e) => setFilterProperty('id', e.target.value, e)}
                  />
              </div>
              <div className="mb-10 col-6">
              <label className="form-label">Messaggio</label>
              <input type="text"
                  className='form-control form-control-solid'
                  placeholder="Messaggio"
                  value={filter?.messaggio} 
                  onChange={(e) => setFilterProperty('messaggio', e.target.value, e)}
                  />
              </div>                

            </div>
                 
            <div className="row">
              <div className="mb-10 col-6">
                <label className="form-label">Da</label>
                <DatePicker
                  placeholderText="Scegli Data"
                  locale="it"
                  className='form-control form-control-solid'
                  name="startDate"
                  dateFormat="dd/MM/yyyy"
                  selected={filter?.dataInizio} 
                  onChange={(date) => setFilterProperty('dataInizio', date, null)}                  
                />
              </div>

              <div className="mb-10 col-6">
                <label className="form-label">A</label>
                <DatePicker
                  placeholderText="Scegli Data"
                  locale="it"
                  className='form-control form-control-solid'
                  name="endDate"
                  dateFormat="dd/MM/yyyy"
                  selected={filter?.dataFine} 
                  onChange={(date) => setFilterProperty('dataFine', date, null)}                  
                />
              </div>               
            </div>

            <div className="row">

            <div className="col-6">
                <label className="form-label">Livello</label>
                <select 
                  className='form-select' 
                  value={filter?.livello}
                  onChange={(e) => setFilterProperty('livello', e.target.value, e)}>
                      <option value=''>Tutti</option>
                      <option value='Fatal'>Fatal</option>
                      <option value='Error'>Error</option>
                      <option value='Warning'>Warning</option>
                      <option value='Information'>Information</option>
                      <option value='Debug'>Debug</option>
                      <option value='Verbose'>Verbose</option>
                  </select>
              </div>                 

            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetFilter}>
            Reset
          </Button> 
          <Button variant="primary" onClick={applyFilter}>
            Applica
          </Button>         
        </Modal.Footer>
      </Modal>           

    </div>
  )
}

export default SystemLogList