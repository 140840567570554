import clsx from 'clsx'

export function setTotals(wc) 
{
    var totals = [];
    var totalKey = "S0";

    totals[totalKey] = {
        articolo:"TOTALE", 
        colore: "TOTALE", 
        scelta:"TOTALE", 
        numeroLotto:"TOTALE", 
        numeroPacchi:0, 
        superficie:0, 
        numeroPelli:0,
        isArticleTotal:false,
        isSceltaTotal:false,
        isLottoTotal:false,
        isTotal:true,
    };

    //Calcolo totali
    wc.packingListRows.forEach(function(tableRow) 
    {
        //Viene fatta questa assegnazione perchè gli oggetti usati per la visualizzazione e la creazione del packing list (lato server) sono diversi.
        //Quindi per usare la stessa fuzione vengono usate delle variabili di appoggio.
        
        var numeroPelli = getNumberValueOrDefault(tableRow.numeroPelli);
        var superficie = getNumberValueOrDefault(tableRow.superficie); 
        var numeroPacchi = getNumberValueOrDefault(tableRow.numeroPacchi);

        //Con lo zero vengono indicati i totali complessivi
        totals[totalKey].numeroPelli += numeroPelli;
        totals[totalKey].superficie += superficie;
        totals[totalKey].numeroPacchi += numeroPacchi;

        //Conteggio totali per scelta
        var articoloValue =  getPropertyValue(tableRow.descrizioneArticolo);
        var coloreValue =  getPropertyValue(tableRow.colore);
        var numeroLottoValue =  getPropertyValue(tableRow.numeroLotto);

        var sceltaValue = getPropertyValue(tableRow.scelta);
        
        //Totali per Scelta
        if(typeof totals[sceltaValue] === 'undefined')
        {
            totals[sceltaValue] = 
            {
                numeroPelli:numeroPelli,
                superficie:superficie, 
                numeroPacchi:numeroPacchi,

                articolo:"",
                colore:"",
                scelta:"TOTALE SCELTA "+sceltaValue,
                numeroLotto:"",

                isArticleTotal: false,
                isSceltaTotal: true,
                isLottoTotal:false,
                isTotal: false,
                isBlank:false,

                lotti:[],
                articoli:[]
            };
        }
        else
        {
            totals[sceltaValue].numeroPelli += numeroPelli;
            totals[sceltaValue].superficie += superficie;
            totals[sceltaValue].numeroPacchi += numeroPacchi;
        }

        //Conteggio totali per Scelta/Articolo/Colore
        var artColValue = articoloValue+"/"+coloreValue+"/"+numeroLottoValue;

        //Se non esiste, viene creato. Altrimenti, aggiornato.
        if(typeof totals[sceltaValue].articoli[artColValue] === 'undefined')
        {
            totals[sceltaValue].articoli[artColValue] = 
            {
                numeroPelli:numeroPelli,
                superficie:superficie, 
                numeroPacchi:numeroPacchi,

                articolo:articoloValue,
                colore:coloreValue,
                scelta:sceltaValue,
                numeroLotto:numeroLottoValue,

                isArticleTotal: true,
                isSceltaTotal: false,
                isLottoTotal:false,
                isTotal: false,
                isBlank:false,
            };
        }
        else
        {
            totals[sceltaValue].articoli[artColValue].numeroPelli += numeroPelli;
            totals[sceltaValue].articoli[artColValue].superficie += superficie;
            totals[sceltaValue].articoli[artColValue].numeroPacchi += numeroPacchi;
        }

        //Conteggio totali per Lotto/Articolo/Colore
        if(typeof totals[artColValue] === 'undefined')
        {
            totals[artColValue] = 
            {
                numeroPelli:numeroPelli,
                superficie:superficie, 
                numeroPacchi:numeroPacchi,

                articolo:articoloValue,
                colore:coloreValue,
                scelta:"TOTALE SCELTA "+sceltaValue,
                numeroLotto:numeroLottoValue,

                isArticleTotal: false,
                isSceltaTotal: false,
                isLottoTotal:true,
                isTotal: false,
                isBlank:false,
            };
        }
        else
        {
            totals[artColValue].numeroPelli += numeroPelli;
            totals[artColValue].superficie += superficie;
            totals[artColValue].numeroPacchi += numeroPacchi;

            //Mettere SCELTA 1,2,3 nella riga della tabella (così si è in linea con quello che già c'è)

            //Se la scelta in oggetto non è nell'elenco delle scelte dei totali, allora viene aggiunta
            //In questo modo viene data indicazione di tutte le scelte presenti in quel lotto
            //Viene usato includes perchè ogni scelta è univoca e il testo non è 'contenuto' nel testo di in un altra scelta (Le scelte sono 0,1,2,3,4,5)
            if(totals[artColValue].scelta.includes(sceltaValue) == false)
            {
                totals[artColValue].scelta += ", "+sceltaValue;
            } 
        } 
    });


    //console.log(totals);

    //Aggiornamento totali
    wc.packingListTotalRow = [];
    var index = 0;

    //Aggiunta totali per scelta
    for( var key in totals ) 
    {
        var value = totals[key];

        //Vengono esclusi i totali generali che verranno aggiunti in coda
        if(value.isTotal == false && value.isLottoTotal == false)
        {
            var sceltaStr = key;
            
            if(sceltaStr == "")
            {
                sceltaStr = "ND";
            }

            //Vengono inseriti prima i totali per singoli articoli e poi il totale della scelta
            for( var key in value.articoli ) 
            {
                var subTotal = value.articoli[key];
                
                wc.packingListTotalRow.push({
                    id:index++, 
                    colore:subTotal.colore,
                    articolo:subTotal.articolo,
                    scelta:subTotal.scelta,
                    numeroLotto:subTotal.numeroLotto,
                    numeroPacchi:""+subTotal.numeroPacchi, 
                    superficie:""+subTotal.superficie, 
                    numeroPelli:""+subTotal.numeroPelli,
                    isArticleTotal: subTotal.isArticleTotal,
                    isSceltaTotal: subTotal.isSceltaTotal,
                    isLottoTotal: subTotal.isLottoTotal,
                    isTotal: subTotal.isTotal,
                    isBlank:false,
                });
            }

            wc.packingListTotalRow.push({
                id:index++, 
                colore:value.colore,
                articolo:value.articolo,
                scelta:value.scelta,
                numeroLotto:value.numeroLotto,

                numeroPacchi:""+value.numeroPacchi, 
                superficie:""+value.superficie, 
                numeroPelli:""+value.numeroPelli,

                isArticleTotal: value.isArticleTotal,
                isSceltaTotal: value.isSceltaTotal,
                isLottoTotal: value.isLottoTotal,
                isTotal: value.isTotal,
                isBlank:false,
            });
        }
    }

    //Aggiunta di una riga vuota per seperare visivamente i totali per scelta e per articolo (indipendentemente dalla scelta)
    wc.packingListTotalRow.push({
        id:index++, 
        colore:"",
        articolo:"",
        scelta:"",
        numeroLotto:"",
        numeroPacchi:"-",
        superficie:"-",
        numeroPelli:"-",
        isArticleTotal: false,
        isSceltaTotal:false,
        isLottoTotal: false,
        isTotal:false,
        isBlank:true,
    });

    //Aggiunta dei totali per lotto
    for( var key in totals ) 
    {
        var value = totals[key];

        if(value.isLottoTotal == true)
        {
            wc.packingListTotalRow.push({
                id:index++, 
                colore:value.colore,
                articolo:value.articolo,
                scelta:value.scelta,
                numeroLotto:value.numeroLotto,

                numeroPacchi:""+value.numeroPacchi, 
                superficie:""+value.superficie, 
                numeroPelli:""+value.numeroPelli,

                isArticleTotal: value.isArticleTotal,
                isSceltaTotal: value.isSceltaTotal,
                isTotal: value.isTotal,
                isBlank: false
            });


            var numPelli = getNumberValueOrDefault(value.numeroPelli);
            var superficie = getNumberValueOrDefault(value.superficie);
            var avgSuperficie = 0;
            var avgSuperficieStr = "0";

            if(numPelli > 0)
            {
                avgSuperficie = (superficie / numPelli).toFixed(2);
                avgSuperficieStr = (avgSuperficie+"").replace(".",",");
            }

            //Aggiunta della media
            wc.packingListTotalRow.push({
                id:index++, 
                colore:value.colore,
                articolo:value.articolo,
                scelta:"MEDIA "+value.scelta,
                numeroLotto:value.numeroLotto,

                numeroPacchi:"", 
                superficie:avgSuperficie, 
                numeroPelli:"",

                isArticleTotal: value.isArticleTotal,
                isSceltaTotal: value.isSceltaTotal,
                isTotal: value.isTotal,
                isBlank: false
            });


        }
    }

    

    //Aggiunta dei totali generali
    wc.packingListTotalRow.push({
        id:index++, 
        articolo:"",
        colore:"",
        scelta:"TOTALE PACKING LIST",
        numeroLotto:"",

        numeroPacchi:""+totals[totalKey].numeroPacchi,
        superficie:""+totals[totalKey].superficie,
        numeroPelli:""+totals[totalKey].numeroPelli,
    
        isArticleTotal: totals[totalKey].isArticleTotal,
        isSceltaTotal: totals[totalKey].isSceltaTotal,
        isTotal: totals[totalKey].isTotal},
        );

    //Aggiunta media dei totali generali
    numPelli = getNumberValueOrDefault(totals[totalKey].numeroPelli);
    superficie = getNumberValueOrDefault(totals[totalKey].superficie);
    avgSuperficie = 0;
    avgSuperficieStr = "0";

    if(numPelli > 0)
    {
        avgSuperficie = (superficie / numPelli).toFixed(2);
        avgSuperficieStr = (avgSuperficie+"").replace(".",",");
    }

    wc.packingListTotalRow.push({
        id:index++, 
        articolo:"",
        colore:"",
        scelta:"MEDIA PACKING LIST",
        numeroLotto:"",

        numeroPacchi:"",
        superficie:avgSuperficieStr,
        numeroPelli:"",
    
        isArticleTotal: totals[totalKey].isArticleTotal,
        isSceltaTotal: totals[totalKey].isSceltaTotal,
        isTotal: totals[totalKey].isTotal},
        );        

    wc.packingListTotalRow.forEach(function(tableRow) {

        if(tableRow.isBlank == false)
        {
            if(tableRow.numeroPelli != "")
            {
                tableRow.numeroPelli = Intl.NumberFormat('it-IT').format(tableRow.numeroPelli);
            }

            if(tableRow.superficie != "")
            {
                tableRow.superficie = Intl.NumberFormat('it-IT').format(tableRow.superficie);
            }

            if(tableRow.numeroPacchi != "")
            {
                tableRow.numeroPacchi = Intl.NumberFormat('it-IT').format(tableRow.numeroPacchi);
            }
        }
    });

    return wc;
}

function getPropertyValue(property)
{
    var output = "";
        
    //Questo controllo è stato inserito per gestire sia la pagina di visualizzazione che di creazione/modifica
    //Perchè nella pagina di creazione/modifica il singolo campo (es. scelta) è un oggetto, la cui proprietà value contiene il valore (scelta.value)
    //Mentre nella pagina di visualizzazione il valore è contenuto nella proprietà stessa (scelta)

    if(typeof property.value === 'undefined')
    {
        output = property;
    }
    else
    {
        output = property.value
    }

    return output;
} 

//Restituisce il valore della variabile. Funzione di appoggio
//Altrimenti "zero" se è una stringa vuota
function getNumberValueOrDefault(parameter) 
{
    var output = 0;
    var outputStr = "0";

    if(typeof parameter.value === 'undefined')
    {    
        if(parameter != "")
        {
            outputStr = parameter;
        }
    }
    else
    {
        if(parameter.value != "")
        {
            outputStr = parameter.value;
        }
    }

    output = Number(outputStr);

    return output;
}

export const totalColumns = [
    {
        name: 'Articolo',
        selector: row => row.articolo
    },
    {
        name: 'Lotto',
        selector: row => row.numeroLotto
    },
    {
        name: 'Colore',
        selector: row => row.colore          
    },
    {
        name: 'Scelta',
        selector: row => row.scelta,
        cell: (row) => {
            return (
                <div className={clsx({'my-5 fs-3':row.isTotal} )} >
                    {
                        row.isSceltaTotal || row.isTotal ? 
                            <strong> {row.scelta}</strong>
                            :
                            <>{row.scelta}</>
                    }
                </div>
              )
          }                
    },
    {
        name: 'Num. Pacchi',
        selector: row => row.numeroPacchi,
        width: '110px',
        cell: (row, index, column, id) => {
            return (
                <div className={clsx('text-end w-100 me-2', {'my-5 fs-3':row.isTotal} )} >
                {
                    row.isSceltaTotal || row.isTotal ? 
                        <strong> {row.numeroPacchi}</strong>
                        :
                        <>{row.numeroPacchi}</>
                }
                </div>
              )
          } 
    },
    {
        name: 'Superficie',
        selector: row => row.superficie,
        width: '100px',
        cell: (row, index, column, id) => {
            return (
                <div className={clsx('text-end w-100 me-2', {'my-5 fs-3':row.isTotal} )} >
                {
                    row.isSceltaTotal || row.isTotal ? 
                        <strong> {row.superficie}</strong>
                        :
                        <>{row.superficie}</>
                }
                </div>
              )
          } 
    },
    {
        name: 'Num. Pelli',
        selector: row => row.numeroPelli,
        width: '100px',
        cell: (row, index, column, id) => {
            return (
                <div className={clsx('text-end w-100 me-2', {'my-5 fs-3':row.isTotal} )} >
                {
                    row.isSceltaTotal || row.isTotal ? 
                        <strong> {row.numeroPelli}</strong>
                        :
                        <>{row.numeroPelli}</>
                }
                </div>
              )
          } 
    }
];


//  Internally, customStyles will deep merges your customStyles with the default styling.
export const totalsTableStyles = { 
    head: {
        style: {
            display: 'none',
        },
    }
};