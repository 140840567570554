import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from '../../_metronic/layout/components/aside/AsideDefault'
import {Footer} from '../../_metronic/layout/components/Footer'
import {HeaderWrapper} from '../../_metronic/layout/components/header/HeaderWrapper'
import {Toolbar} from '../../_metronic/layout/components/toolbar/Toolbar'
import {RightToolbar} from '../../_metronic/partials/layout/RightToolbar'
import {ScrollTop} from '../../_metronic/layout/components/ScrollTop'
import {Content} from '../../_metronic/layout/components/Content'
import {PageDataProvider} from '../../_metronic/layout/core'
import {useLocation} from 'react-router-dom'
import {DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan} from '../../_metronic/partials'
import {MenuComponent} from '../../_metronic/assets/ts/components'
import {MasterInit} from '../../_metronic/layout/MasterInit'

const LayoutNavBar = () => {
	const location = useLocation()
	useEffect(() => {
	  setTimeout(() => {
		MenuComponent.reinitialization()
	  }, 500)
	}, [])
  
	useEffect(() => {
	  setTimeout(() => {
		MenuComponent.reinitialization()
	  }, 500)
	}, [location.key])

return (
  <>
    {/* <PageDataProvider> */}
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          {/* <Toolbar /> */}

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          
              <Outlet />

          </div>

          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer />
      <RightToolbar />
      <DrawerMessenger /> */}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      {/* <Main />
      <InviteUsers />
      <UpgradePlan /> */}
      {/* end:: Modals */}
      {/* <ScrollTop /> */}

      {/* <MasterInit /> */}
    {/* </PageDataProvider> */}
    </>

  )
}

export default LayoutNavBar