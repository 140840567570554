import FileSaver from "file-saver";

export function isEditMode(location) 
{
    var output = false;

    if(location.pathname.indexOf("/edit") > -1)
    {
        output = true;
    }
   
    return output;
}


export function isViewMode (location)
{
    var output = false;

    if(location.pathname.indexOf("/view") > -1)
    {
        output = true;
    }
   
    return output;
}    

export function isCreateMode(location)
{
    var output = false;

    if(location.pathname.indexOf("/create") > -1)
    {
        output = true;
    }
   
    return output;
}     


export function validateProperty (property)
{
    //Validazione
    if (property.isMandatory) {
        var propValue = "";

        if (property.dataType == "Date") {
            propValue = property.value;
        }
        else if (property.dataType == "Select") {
            if(property.value != null)
            {
                propValue = property.value.value;
            }
        }
        else {
            propValue = property.value?.trim();
        }

        if (propValue == "" || propValue == null) {
            property.isError = true;
            property.errorMessage = "Il campo non può essere vuoto";
        }
        else {
            property.isError = false;
            property.errorMessage = "";
        }
    }

    return property;
}

//Validazione completa dell'oggetto Lavorazione
export function validateEntity(entity) 
{
    entity.date = validateProperty(entity.date);
    entity.batchNumber = validateProperty(entity.batchNumber);
    entity.articleId = validateProperty(entity.articleId);
    // entity.supplierId = validateProperty(entity.supplierId);
    // entity.articleId = validateProperty(entity.articleId);
    entity.quantity = validateProperty(entity.quantity);

    return entity;
}

export function checkValidationErrors (entity)
{
    var output = false;

    if(
        entity.date.isError
        || entity.batchNumber.isError
        || entity.articleId.isError
        // || entity.supplierId.isError
        // || entity.articleId.isError
        || entity.quantity.isError
    )
    {
        output = true;
    }

    return output;
}

export function GetHandlerPayload(entityModel, inventoryId)
{
    var output = "";
    
    var model = {...entityModel};

    //L'id articolo è gestito tramite una select, che richiede che il valore sia una oggetto chiave-valore, 
    //Mentre lato server occorre solo la chiave, ovver l'id
    model.articleId.value = ''+model.articleId.value.value;

    //Lo stesso vale per il campo relavito al fornitore
    //model.supplierId.value = model.supplierId.value.value;

    model.inventoryId = inventoryId;

    model.articles = null;
    model.suppliers = null;

    output = JSON.stringify(model)

    return output;
}


export function normalizeEntity(entityModel) 
{
    if (entityModel.date.value != "") {
        entityModel.date.value = new Date(entityModel.date.value);
    }

    //Trasformazione delle liste per renderle compatibili con le relative select
    entityModel.articles = entityModel.articles.reduce((res, item) => {
        
        let label = '';

        if (item.code != '' && (! (typeof item.code === 'undefined'))) {
          label += '[' + item.code + '] - ';
        }
        if (item.name != '' && (! (typeof item.name === 'undefined'))) {
          label += item.name + ' ';
        }
  
        if (item.color != '' && (! (typeof item.color === 'undefined'))) {
          label += ' ' + item.color;
        }
  
          res.push({ 
            value: item.id, 
            label: label, 
            articleCode: item.code,
            articleName: item.name,
            colorName: item.color,
          });


        return res;
    }, []);

    // entityModel.suppliers = entityModel.suppliers.reduce((res, item) => {
    //     res.push({ value: item.key, label: item.value});
    //     return res;
    // }, []);


    // if(entityModel.supplierId.value != "")
    // {
    //     var selectEntry = {};
    //     entityModel.suppliers.forEach(entry=>{
    
    //         if(entry.value == entityModel.supplierId.value)
    //         {
    //             selectEntry = entry;
    //         }
    
    //     })
    
    //     entityModel.supplierId.value = selectEntry;
    // }
    
    if(entityModel.articleId.value != "")
    {
        var selectEntry = {};
        entityModel.articles.forEach(entry=>{
    
            if(entry.value == entityModel.articleId.value)
            {
                selectEntry = entry;
            }
        })
    
        entityModel.articleId.value = selectEntry;
    }

    //La tipologia di movimento viene indicata dal client al server. 
    //Per il server è un movimento generico e deve essere specificato dal client di che tipologia si tratta
    
    entityModel.type = "LOAD";

    return entityModel;
}


export function downloadPdf (entityId, axiosPrivate, isPdfInProgress, setIsPdfInProgress, setModalMessage, setSaveResult, setModalShow ) {

    if(isPdfInProgress)
    {
      return;
    }

    setIsPdfInProgress(true);

    let isMounted = true;
    const controller = new AbortController();

    const getPdf = () => 
    {
        axiosPrivate.get(`/inventoryMovement/getEntityPdf/${entityId}`,
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
            responseType: "blob", // important!
            signal: controller.signal
        })
        .then(res => {

            let fileName = `InventoryMov_${entityId}.pdf`;

            const blob = new Blob([res.data], {
                type: 'application/pdf'
            })

            FileSaver.saveAs(blob, fileName);
            
            setModalMessage("");
            setSaveResult(true);
            setIsPdfInProgress(false);
        })
        .catch(err => {
            console.log("ERR:",err.response);
            setModalMessage(err?.response?.statusText);
            setSaveResult(false);
            setModalShow(true);
            setIsPdfInProgress(false);
        });
    }

    getPdf();

    return () => {
      isMounted = false;
      controller.abort();
    }
}
