
export function isEditMode(location) 
{
    var output = false;

    if(location.pathname.indexOf("/edit") > -1)
    {
        output = true;
    }
   
    return output;
}


export function isViewMode (location)
{
    var output = false;

    if(location.pathname.indexOf("/view") > -1)
    {
        output = true;
    }
   
    return output;
}    

export function isCreateMode(location)
{
    var output = false;

    if(location.pathname.indexOf("/create") > -1)
    {
        output = true;
    }
   
    return output;
}     


export function validateProperty (property)
{
    //Validazione
    if (property.isMandatory) {
        var propValue = "";

        if (property.dataType == "Date") {
            propValue = property.value;
        }
        else if (property.dataType == "Select") {
            if(property.value != null)
            {
                propValue = property.value.value;
            }
        }
        else {
            propValue = property.value?.trim();
        }

        if (propValue == "" || propValue == null) {
            property.isError = true;
            property.errorMessage = "Il campo non può essere vuoto";
        }
        else {
            property.isError = false;
            property.errorMessage = "";
        }
    }

    return property;
}

//Validazione completa dell'oggetto Lavorazione
export function validateEntity(entity) 
{
    entity.date = validateProperty(entity.date);
    entity.batchNumber = validateProperty(entity.batchNumber);
    entity.articleId = validateProperty(entity.articleId);
    entity.quantity = validateProperty(entity.quantity);
    entity.quantity1 = validateProperty(entity.quantity1);
    entity.quantity2 = validateProperty(entity.quantity2);
    entity.quantity3 = validateProperty(entity.quantity3);
    entity.quantity4 = validateProperty(entity.quantity4);
    entity.waste = validateProperty(entity.waste);
    entity.productionOrderNumber = validateProperty(entity.productionOrderNumber);

    return entity;
}

export function checkValidationErrors (entity)
{
    var output = false;

    if(
        entity.date.isError
        || entity.batchNumber.isError
        // || entity.articleId.isError
        || entity.quantity.isError
        || entity.quantity1.isError
        || entity.quantity2.isError
        || entity.quantity3.isError
        || entity.quantity4.isError
        || entity.waste.isError
        || entity.productionOrderNumber.isError
    )
    {
        output = true;
    }

    return output;
}

export function GetHandlerPayload(entityModel, inventoryId)
{
    var output = "";
    
    var model = {...entityModel};

    //L'id articolo è gestito tramite una select, che richiede che il valore sia una oggetto chiave-valore, 
    //Mentre lato server occorre solo la chiave, ovver l'id
    model.articleId.value = ''+model.articleId.value.value;

    model.inventoryId = inventoryId;

    model.articles = null;
    model.suppliers = null;

    output = JSON.stringify(model)

    return output;
}


export function normalizeEntity(entityModel) 
{
    if (entityModel.date.value != "") {
        entityModel.date.value = new Date(entityModel.date.value);
    }

    //Trasformazione delle liste per renderle compatibili con le relative select
    entityModel.articles = entityModel.articles.reduce((res, item) => {
        
        let label = '';

        if (item.code != '' && (! (typeof item.code === 'undefined'))) {
          label += '[' + item.code + '] - ';
        }
        if (item.name != '' && (! (typeof item.name === 'undefined'))) {
          label += item.name + ' ';
        }
  
        if (item.color != '' && (! (typeof item.color === 'undefined'))) {
          label += ' ' + item.color;
        }
  
          res.push({ 
            value: item.id, 
            label: label, 
            articleCode: item.code,
            articleName: item.name,
            colorName: item.color,
          });


        return res;
    }, []);  

    if(entityModel.articleId.value != "")
    {
        var selectEntry = {};
        entityModel.articles.forEach(entry=>{
    
            if(entry.value == entityModel.articleId.value)
            {
                selectEntry = entry;
            }
    
        })
    
        entityModel.articleId.value = selectEntry;
    }

    //La tipologia di movimento viene indicata dal client al server. 
    //Per il server è un movimento generico e deve essere specificato dal client di che tipologia si tratta
    entityModel.type = "UNLOAD";

    return entityModel;
}