import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { KTSVG } from '../../../_metronic/helpers'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { usePageData } from '../../../_metronic/layout/core';
import { Spinner, Modal, Button} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import DataTable from 'react-data-table-component';
import DatePicker, {registerLocale} from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it"; // the locale you want
import FileSaver from "file-saver";
import useCache from '../../../hooks/useCache';
import useAuth from "../../../hooks/useAuth";
import clsx from 'clsx'
import {UnitJobCard, UnitJobsMobilePagination, EndSingleJobConfirmationModal} from "./UnitComponents";


registerLocale("it", it); // register it with the name you want

const UnitProdList = () => 
{
  const [workCycles, setWorkCycles] = useState();
  const [modalShow, setModalShow] = useState();  
  const [modalMessage, setModalMessage] = useState();  
  const [modalResultShow, setModalResultShow] = useState();
  const [modalEndSingleJobConfirmShow, setModalEndSingleJobConfirmShow] = useState();
  const [endSingleJobId, setEndSingleJobId] = useState(0);
    
  const [saveResult, setSaveResult] = useState();
  
  const [isError, setIsError] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  let { unitId } = useParams();

  const { setPageTitle, pageMachines } = usePageData();
  const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();
  const [unit, setUnit] = useState();
  const [selectedMachineId, setSelectedMachineId] = useState(0);

  const [modalFilterShow, setModalFilterShow] = useState(false);  
  const [modalNewJobShow, setModalNewJobShow] = useState(false);  
  
  const [tempFilter, setTempFilter]= useState();
  const [showExportProgress, setShowExportProgress] = useState('off');

  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);  
  const [endJobInProgress, setEndJobInProgress] = useState(false);

  const {auth} = useAuth();
  var emptyFilterMyJobsOnly = '0';
  var emptyFilterStato = 'ALL';

  //Per default, l'operatore vede solo le sue lavorazioni aperte
  if (auth?.roles?.length == 1 && auth?.roles[0] == 100) {
    emptyFilterMyJobsOnly = '1';
    emptyFilterStato = 'NOT_DONE';
  }
  //Mentre, Per default, il supervisore vede tutte le lavorazioni aperte
  else if (auth?.roles?.length == 2 && auth?.roles[0] == 100 && auth?.roles[1] == 200) {
    emptyFilterMyJobsOnly = '0';
    emptyFilterStato = 'NOT_DONE';
  }


  console.log(auth)

    //---START Gestione Filtri---//
    const { filters } = useCache();
    const [filter, setFilter] = useState({});

    //Il filtro myJobsOnly deve essere attivo di default se l'utente corrente è un Operatore

    let emptyFilter = {
      id:'',
      dataInizio:'',
      dataFine:'',
      dataInizioDa:'',
      dataInizioA:'',
      dataFineDa:'',    
      dataFineA:'',    
      stato: emptyFilterStato,
      numeroOrdine:'',
      codiceArticolo:'',
      cliente:'',
      colore:'',
      idMacchina:'0',
      myJobsOnly: emptyFilterMyJobsOnly,
      sortField:'Id',
      sortDirection:'desc'
    };
    //---END Gestione Filtri---//

  //Pagination Handling
  const [perPage, setPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  //Sorting handling
  const [defaultSortAsc, setDefaultSortAsc] = useState(false);
  const [defaultSortFieldId, setDefaultSortFieldId] = useState(1);  

  const paginationComponentOptions = {
    rowsPerPageText: 'Elementi per pagina',
    rangeSeparatorText: 'di',
    selectAllRowsItem: false
  };

  const columns = [
    // {
    //   name: 'Id',
    //   selector: row => row.id,
    //   width: '100px',
    //   sortable: true,
    //   sortField:'Id',
    //   sortFieldId:1,
    //   cell: (row, index, column, id) => {
    //     return getProdViewLink(row, row?.id);
    //   }
    // },
    {
      name: 'Cartellino',
      maxWidth: '150px',
      selector: row => row.orderNumber,
      sortFieldId:1,
      cell: (row, index, column, id) => {
        return getProdViewLink(row, row?.orderNumber);        
      }
    },
    {
      name: 'Cliente',
      selector: row => row.customer,
      sortable: true,
      sortField:'Customer',
      sortFieldId:2,
      cell: (row, index, column, id) => {
        return getProdViewLink(row, row?.customer);        
      }
    },
    {
      name: 'Articolo',
      selector: row => row.article,
      sortable: true,
      sortField:'article',
      sortFieldId:3,
      cell: (row, index, column, id) => {
        return getProdViewLink(row, row?.article);        
      }
    }, 
    {
      name: 'Colore',
      selector: row => row.color,
      sortable: true,
      sortField:'color',
      sortFieldId:4,
      cell: (row, index, column, id) => {
        return getProdViewLink(row, row?.color);        
      }
    }, 
    {
      name: 'Num. Pelli',
      maxWidth: '150px',
      selector: row => row.leathersNumber,
      sortFieldId:5,
      cell: (row, index, column, id) => {
        return getProdViewLink(row, row?.leathersNumber);        
      }
    },
    {
      name: 'Macchina',
      selector: row => row.machineName,
      sortFieldId:6,
      cell: (row, index, column, id) => {
        return getProdViewLink(row, row?.machineName);        
      }
    }, 
    {
      name: 'Stato',
      selector: row => row.status,
      maxWidth: '180px',
      sortable: false,
      sortField:'status',  
      sortFieldId:7,
      cell: (row, index, column, id) => {
        //return getProdViewLink(row, row?.startDateStr ? row?.startDateStr : '-');   
        var bgClass = '';
        var statusName = '';

        if (row.startDate != null && row.endDate == null) {
          bgClass = 'bg-warning';
          statusName = 'In Corso';
        }
        else if (row.endDate != null) {
          bgClass = 'bg-success';
          statusName = 'Completo';
        }

        return (<span className={clsx(`badge ${bgClass}`)}>{statusName}</span>);
      }
    },
    {
      name: 'Data Inizio',
      selector: row => row.startDateStr,
      maxWidth: '110px',
      sortable: true,
      sortField:'StartDate',  
      sortFieldId:8,      
      cell: (row, index, column, id) => {
        return getProdViewLink(row, row?.startDateStr ? row?.startDateStr : '-');        
      }
    },
    {
      name: 'Data Fine',
      selector: row => row.endDateStr,
      maxWidth: '110px',
      sortable: true,
      sortField:'EndDate',  
      sortFieldId:9,      
      cell: (row, index, column, id) => {
        return getProdViewLink(row, row?.endDateStr ? row?.endDateStr : '-');        
      }
    },
    {
      name: 'Azioni',
      maxWidth: '100px',
      right: true,
      ignoreRowClick: true,
      cell: (row, index, column, id) => {
        return (
          <div className='row'>

            <Link to={`/units/${unitId}/jobs/${row?.machineId}/viewJob/${row?.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <i className="fas fa-eye"></i>
            </Link>

            <Link to={`/units/${unitId}/jobs/${row?.machineId}/editJob/${row?.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <i className="fas fa-pencil-alt"></i>
            </Link>
          </div>)
      }
    }
  ];

//Setting Page BreadCrumb
useEffect(()=>{ 

  let isMounted = true;
  const controller = new AbortController();
  
  //Se non è stata trovata nessuna macchine viene impostato un nome di defualt 'No Name'
  let unitName = "No Name";

  const getUnit = async () => {
      try {
        const response = await axiosPrivate.get(`/machines/getUnitById/${unitId}`, {
          signal: controller.signal
        });

        if (isMounted) {
          unitName = response.data.name;
          setUnit(response.data);
        }
      }
      catch (err) {
        console.error(err);
      }

      setPageTitle('Reparto '+unitName);
      setPageBreadcrumbs(['Lavorazioni']);
    }

    getUnit();


  return () => {
      isMounted = false;
      controller.abort();
  }

},[unitId])

  useEffect(() => {

    let initFilter = {};
  
    //Se il filtro non esiste in cache, allora viene impostato quello vuoto da utilizzare e salvato in cache per la pagina corrente
    //Altrimenti viene preso il filtro in cache
    if((typeof filters[location.pathname] === 'undefined'))
    {
      initFilter = emptyFilter;
    }
    else
    {
      initFilter = filters[location.pathname];
    }
  
    setTableFiltering(initFilter);

    getWorkCycleList(1, perPage, initFilter);

  }, [])

  //Dato che il filtro in cache è stato introdotto successivamente, viene fatta una doppia operazione
  //Sia sul filtro in cache che in quello locale. Quindi è stato raggruppato nello stesso medoto
  const setTableFiltering = (inputFilter) =>
  {
    filters[location.pathname] = inputFilter;
    setFilter(inputFilter);
  }  

  const getProdViewLink = (row, text) => {
    let rowId = row?.id;
    let machineId = row?.machineId;
    let machineCode = row?.machineCode;

    return <Link to={`/units/${unitId}/jobs/${machineId}/viewJob/${rowId}`} className='text-dark text-hover-primary fs-6'>{text}</Link>;    
  }    

  const handleSort = async (column, sortDirection) => {

    if(sortDirection == "asc" || sortDirection == "")
    {
      setDefaultSortAsc(true);
    }
    else
    {
      setDefaultSortAsc(false);
    }

    setDefaultSortFieldId(column.sortFieldId);

    let sortedFilter = {...filter, sortField:column.sortField, sortDirection, sortDirection};
    //setFilter(sortedFilter);
    setTableFiltering(sortedFilter);

    getWorkCycleList(1, perPage, sortedFilter);
  };  

  const handleFilterClose = () => {
    
    //Se la finestra viene chiusa senza ne applicare ne resettare il filtro, allora i filtri impostati dal momento dell'apertura 
    //della finestra del filtro vengono persi e reimpostati quelli precedentemente applicati
    setFilter(tempFilter);
 
   setModalFilterShow(false);
  }   
  
  const handleNewJobClose = () => {
    setSelectedMachineId(0);
   setModalNewJobShow(false);
  }    

  const showFilterModal = () => {

    //Salvataggio in memoria del filtro attuale. 
    //Questo viene fatto perchè se non viene applicato o resettato, il filtro viene reimpostato
    //A quello al momento dell'apertura della finestra

    setTempFilter(filter);

    setModalFilterShow(true);
   }  

   const showNewJobModal = () => {

    setModalNewJobShow(true); 
   }  
   


   const resetFilter = () => {

    setTableFiltering(emptyFilter);
    getWorkCycleList(1, perPage, emptyFilter);

    setModalFilterShow(false);
   }       

   const applyFilter = () => {
    setTableFiltering(filter);

    getWorkCycleList(1, perPage, filter);
    setModalFilterShow(false);
  }  

   const setFilterProperty = (attribute, value, event) => {

      if (attribute == "id") 
      {
        if(event.target.validity.valid)
        {
          setFilter({...filter, id:value});
        }
      }
      else if (attribute == "numeroOrdine") 
      {
          setFilter({...filter, numeroOrdine:value});
      }     
      else if (attribute == "codiceArticolo") 
      {
          setFilter({...filter, codiceArticolo:value});
      } 
      else if (attribute == "dataInizioDa") 
      {
          setFilter({...filter, dataInizioDa:value});
      }   
      else if (attribute == "dataInizioA") 
      {
          setFilter({...filter, dataInizioA:value});
      }      
      else if (attribute == "dataFineDa") 
      {
          setFilter({...filter, dataFineDa:value});
      }   
      else if (attribute == "dataFineA") 
      {
          setFilter({...filter, dataFineA:value});
      }                            
      else if (attribute == "dataInizio") 
      {
          setFilter({...filter, dataInizio:value});
      }
      else if (attribute == "dataFine") 
      {
          setFilter({...filter, dataFine:value});
      }
      else if (attribute == "stato") 
      {
          setFilter({...filter, stato:value});
      }
      else if (attribute == "cliente") 
      {
          setFilter({...filter, cliente:value});
      }
      else if (attribute == "colore") 
      {
          setFilter({...filter, colore:value});
      }
      else if (attribute == "idMacchina") 
      {
          setFilter({...filter, idMacchina:value});
      }
      else if (attribute == "myJobsOnly") 
      {
        setFilter({ ...filter, myJobsOnly: value });
      }
   }

   const getWorkCycleListRequest = (page, pageSize, pageFilter)=> {

    let workCycleListRequest =
    {
      unitId: unitId,
      idMacchina: pageFilter.idMacchina,
      page: page,
      pageSize: pageSize,
      filterId: pageFilter.id,
      filterOrderNumber: pageFilter.numeroOrdine,
      filterArticleCode: pageFilter.codiceArticolo,
      filterStartDate: pageFilter.dataInizio,
      filterEndDate: pageFilter.dataFine,

      filterStartDateFrom: pageFilter.dataInizioDa,
      filterStartDateTo: pageFilter.dataInizioA,
      filterEndDateFrom: pageFilter.dataFineDa,
      filterEndDateTo: pageFilter.dataFineA,

      filterStatus: pageFilter.stato,
      filterCustomer: pageFilter.cliente,
      filterArticleColor: pageFilter.colore,
      filterMyJobsOnly: pageFilter.myJobsOnly,
      sortField: pageFilter.sortField,
      sortDirection: pageFilter.sortDirection
    }

    return workCycleListRequest;
   }

  const getWorkCycleList = (page, pageSize, pageFilter) => {

    setIsLoading(true);

    let isMounted = true;
    const controller = new AbortController();

    let workCycleListRequest = getWorkCycleListRequest(page, pageSize, pageFilter);

    const fetchWorkCycleList = async () => {

      try 
      {
        const response = await axiosPrivate.post(`/workcycles/getWorkCycles`,  
        JSON.stringify(workCycleListRequest), 
        {
            headers: {'Content-Type':'application/json'},
            withCredentials: true,
            signal:controller.signal
        });

        //console.log(response.data);
        if (isMounted) {
          setWorkCycles(response.data.data);
          setTotalRows(response.data.total);
          setIsLoading(false);
          setIsError(false);
          clearSelection();
        }

      }
      catch (err) {
        console.error(err);
        setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
        setIsLoading(false);
        setIsError(true);

        // if(err.response?.status === 403) 
        // {
        //     navigate('/login', {state : {from: location }, replace: true});
        // }
      }
    }

    fetchWorkCycleList();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }

  const goToNewJobPage = () => {
    if (selectedMachineId > 0) {
      navigate(`/units/${unitId}/jobs/${selectedMachineId}/createJob`);
    }
  }

  const clearSelection = (wc) => {
    setClearSelectedRows(!clearSelectedRows);
    setSelectedRows([]);
  }

  const goToNextPage = () => {

    var nextPage = currentPage + 1;
    getWorkCycleList(nextPage, perPage, filter);

    setCurrentPage(nextPage);
  }  

  const goToPrevPage = () => {

    var prevPage = currentPage - 1;
    getWorkCycleList(prevPage, perPage, filter);

    setCurrentPage(prevPage);
  }    

  const goToDetailPage = (job) => {
    navigate(`/units/${unitId}/jobs/${job?.machineId}/viewJob/${job?.id}`)
  }      

  const onEndSingleJobRequest = (job) => {

    if(endJobInProgress == true)
    {
      return;
    }

    setEndSingleJobId(job.id);
    setModalEndSingleJobConfirmShow(true);
  }

  const endSingleJobConfirmationModalOnHide = () =>
  {
    setEndSingleJobId(0);
    setModalEndSingleJobConfirmShow(false);
  }

  const endSingleJobConfirmationModalOnConfirm = () => {

    let isMounted = true;
    const controller = new AbortController();
    
    setModalEndSingleJobConfirmShow(false);
    setEndJobInProgress(true);

    const endSingleJob = async () => {
        try {

          const response = await axiosPrivate.get(`/workCycles/endJob/${endSingleJobId}`,
            {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true,
              signal: controller.signal
            }
          );

          if (isMounted) {
            setSaveResult(true);
            setModalMessage("");
            getWorkCycleList(currentPage, perPage, filter);
          }
        }
        catch (err) {
          setSaveResult(false);
          setModalMessage(`${err?.response?.data?.message}`);
          setModalResultShow(true);
        }

        setEndJobInProgress(false);
      }
  
      endSingleJob();

    return () => {
        isMounted = false;
        controller.abort();
    }

  }  
  
  const onSelectedRowsChange = (state) => {

    var selectedRows = [];

    state.selectedRows.forEach(function (tableRow) {
      selectedRows.push(tableRow.id);
    });
    
    setSelectedRows(selectedRows);

  };  

  const handleConfirmationClose = () => {
    setConfirmationModalShow(false);
  }

  const showEndJobModal = () => {

    if(endJobInProgress || selectedRows.length == 0)
    {
        return;
    }
    
    setConfirmationModalShow(true); 
   }

   const confirmEndJob = () => {

    let isMounted = true;
    const controller = new AbortController();
    
    setConfirmationModalShow(false);
    setEndJobInProgress(true);

    const endJobs = async () => {
        try {

          var payload = selectedRows;

          const response = await axiosPrivate.post(`/workCycles/endJobs/`,
            JSON.stringify(payload),
            {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true,
              signal: controller.signal
            }
          );

          if (isMounted) {
            setSaveResult(true);
            setModalMessage("");
            getWorkCycleList(currentPage, perPage, filter);
          }
        }
        catch (err) {
          setSaveResult(false);
          setModalMessage(`${err?.response?.data?.message}`);
          setModalResultShow(true);
        }

        setEndJobInProgress(false);
        setConfirmationModalShow(false);
      }
  
      endJobs();

    return () => {
        isMounted = false;
        controller.abort();
    }
   }

  const downloadReport = () => {

    if(showExportProgress == 'on')
    {
      return;
    }

    setShowExportProgress('on');

    let isMounted = true;
    const controller = new AbortController();

    let workCycleListRequest = getWorkCycleListRequest(0, perPage, filter);

    const fetchReport = () => {

       axiosPrivate.post(`/workcycles/downloadReport`,  
        JSON.stringify(workCycleListRequest), 
        {
            headers: {'Content-Type':'application/json'},
            withCredentials: true,
            signal:controller.signal
        })
        .then(res => {
          // let extension = 'zip';
          // let tempFileName = `${this.state['selectedCommunity']}`
          // let fileName = `${tempFileName}.${extension}`;
          let fileName = `Export.csv`;

          const blob = new Blob([res.data], {
              type: 'application/octet-stream'
          })

          FileSaver.saveAs(blob, fileName);
          setShowExportProgress('off');
        })
        .catch(err => {
            console.log(err.message);
            setShowExportProgress('off');
            alert(err?.response?.data?.message);
        });
    }

    fetchReport();

    return () => {
      isMounted = false;
      controller.abort();
    }

   }


  const handlePageChange = page => {
		getWorkCycleList(page, perPage, filter);
    setCurrentPage(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {

    getWorkCycleList(page, newPerPage, filter);
    setPerPage(newPerPage);
	};  


  return (
    <div id="kt_content_container" className="mx-5">

      {/* begin::Spinner */}
      {isLoading &&
        <div className="row d-flex justify-content-center m-20">
          <Spinner animation="border" variant="primary" />
        </div>
      }
      {/* end::Spinner */}

      {/* begin::Error message */}
      {!isLoading && isError &&

        <div className="row d-flex justify-content-center m-20">

          <div className="alert alert-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

            <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
              </svg>
            </span>

            <div className="text-center">
              <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
              <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
              <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
            </div>
          </div>

        </div>

      }
      {/* end::Error message */}

      {/* begin::Table */}
      {!isLoading && !isError &&
        <>
          <div className="row d-none d-md-block" >
            <div className='card mb-5 mt-5 mb-xl-8'>

              {/* begin::Header */}
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Elenco Lavorazioni</span>
                </h3>

                {/* hide on screens larger than md*/}
                {/* <div className="col-12 card-toolbar d-block d-md-none"> 

                  <div onClick={(e) => showNewJobModal()}
                  className='btn btn-success me-2 w-100'>
                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-solid fa-plus"></i>
                    </span>
                    Nuova(M)
                  </div>

                  <div 
                    onClick={(e) => showEndJobModal()}
                    className="btn btn-danger me-2 mt-5 w-100"
                    {...endJobInProgress && {disabled:true}}
                    >

                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-solid fa-stop"></i>
                    </span>
                    Termina (M)
                    
                    {endJobInProgress &&
                      <span className="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                    }
                    </div> 

                  <div className='btn btn-primary me-2 mt-5 w-100' onClick={(e) => showFilterModal()}>
                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-search"></i>
                    </span>
                    Filtro
                  </div>                                                

                </div> */}

                <div className="card-toolbar d-none d-md-block"> {/* hide on screens smaller than md*/}

                  <div className='btn btn-primary me-2 mt-1' onClick={(e) => showFilterModal()}>
                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-search"></i>
                    </span>
                    Filtro
                  </div>                

                  <div className='btn btn-secondary me-2 mt-1' onClick={(e) => getWorkCycleList(1, perPage, filter)}>
                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-sync"></i>
                    </span>
                    Aggiorna
                  </div>
                  
                  <div 
                    className='btn btn-secondary me-2 mt-1' 
                    onClick={(e) => downloadReport()} 
                    data-kt-indicator={showExportProgress}
                  >

                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-download"></i>
                    </span>

                    <span className="indicator-label" >Esporta</span>
                    <span className="indicator-progress" > Esporta
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>                  
                  </div> 

                  <div 
                    onClick={(e) => showEndJobModal()}
                    className="btn btn-danger me-2 mt-1"
                    {...endJobInProgress && {disabled:true}}
                    >

                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-solid fa-stop"></i>
                    </span>
                    Termina
                    
                    {endJobInProgress &&
                      <span className="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                    }
                  </div>
                  
                  <div onClick={(e) => showNewJobModal()}
                  className='btn btn-success me-2 mt-1'>
                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-solid fa-plus"></i>
                    </span>
                    Nuova
                  </div>

                </div>
              </div>
              {/* end::Header */}
              
              {/* begin::Body */}
              <div className='card-body py-3'>

                <div className='row mb-2'>
                  <DataTable
                    columns={columns}
                    data={workCycles}
                    pagination
                    paginationServer

                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationDefaultPage={currentPage}
                    paginationPerPage={perPage}
                    paginationComponentOptions={paginationComponentOptions}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}

                    onSort={handleSort}
                    sortServer
                    defaultSortFieldId={defaultSortFieldId}
                    defaultSortAsc={defaultSortAsc} 
                    

                    selectableRows
                    highlightOnHover
                    striped
                    selectableRowsVisibleOnly
                    selectableRowsHighlight
                    selectableRowsNoSelectAll

                    // selectableRows
                    // selectableRowsHighlight
                    // selectableRowsSingle
                    // clearSelectedRows = {clearSelectedRows}
                    onSelectedRowsChange={onSelectedRowsChange}

                  />
              </div>
              </div>
            </div>
          </div>


        {/* begin::Mobile job List */}
        <div className="row d-block d-md-none" >

          <div className="col-12">
            <div className='card mb-5 mt-5 mb-xl-8'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Elenco Lavorazioni</span>
                </h3>
                <div className="card-toolbar w-100">

                <div onClick={(e) => showNewJobModal()}
                  className='btn btn-success w-100'>
                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-solid fa-plus"></i>
                    </span>
                    Nuova
                  </div>

                  {/* <div 
                    onClick={(e) => showEndJobModal()}
                    className="btn btn-danger mt-5 w-100"
                    {...endJobInProgress && {disabled:true}}
                    >

                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-solid fa-stop"></i>
                    </span>
                    Termina
                    
                    {endJobInProgress &&
                      <span className="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                    }
                    </div>  */}

                  <div className='btn btn-primary mt-5 w-100' onClick={(e) => showFilterModal()}>
                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-search"></i>
                    </span>
                    Filtro
                  </div>                   

                  {/* <div className='btn btn-primary me-2 mt-1' onClick={(e) => showFilterModal()}>
                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-search"></i>
                    </span>
                    Filtro
                  </div> */}


                </div>
              </div>

            </div>
          </div>

          {workCycles?.map((wc, i) =>
            
              <div  key={wc.id}  className="col-12">
                <UnitJobCard 
                productionOrder={wc} 
                onGoToDetailPage={goToDetailPage}
                onEndJob={onEndSingleJobRequest} />
              </div>
            )
          }

          <UnitJobsMobilePagination
            currentPage={currentPage}
            perPage={perPage}
            totalRows={totalRows}
            onGoToPrevPage={goToPrevPage}
            onGoToNextPage={goToNextPage} />

        </div>          
        {/* end::Mobile job List */}

        </>
      }

      <Modal show={modalFilterShow} onHide={handleFilterClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filtri di Ricerca</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">

            <div className="row">
              <div className="mb-10 col-6">
                <label className="form-label">ID</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="ID"
                  pattern="[0-9]*"
                  value={filter?.id} 
                  onChange={(e) => setFilterProperty('id', e.target.value, e)}
                  />
              </div>

              <div className="mb-10 col-6">
                <label className="form-label">Stato</label>
                  <select
                    className='form-select' 
                    value={filter?.stato}
                    onChange={(e) => setFilterProperty('stato', e.target.value, e)}>
                        <option value='ALL'>Tutti</option>
                        <option value='IN_PROGRESS'>In Corso</option>
                        <option value='DONE'>Completo</option>
                        <option value='NOT_DONE'>Non Completo</option>
                    </select>
              </div>

            </div>
                 
            <div className="row">
              <div className="mb-10 col-6">
                <label className="form-label">Data Inizio - Da</label>
                <DatePicker
                  placeholderText="Scegli Data"
                  locale="it"
                  className='form-control form-control-solid'
                  name="startDate"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                  selected={filter?.dataInizioDa} 
                  onChange={(date) => setFilterProperty('dataInizioDa', date, null)}                  
                />
              </div>

              <div className="mb-10 col-6">
                <label className="form-label">Data Inizio - A</label>
                <DatePicker
                  placeholderText="Scegli Data"
                  locale="it"
                  className='form-control form-control-solid'
                  name="endDate"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                  selected={filter?.dataInizioA} 
                  onChange={(date) => setFilterProperty('dataInizioA', date, null)}                  
                />
              </div>               
            </div>

            <div className="row">
              <div className="mb-10 col-6">
                <label className="form-label">Data Fine - Da</label>
                <DatePicker
                  placeholderText="Scegli Data"
                  locale="it"
                  className='form-control form-control-solid'
                  name="startDate"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                  selected={filter?.dataFineDa} 
                  onChange={(date) => setFilterProperty('dataFineDa', date, null)}                  
                />
              </div>

              <div className="mb-10 col-6">
                <label className="form-label">Data Fine - A</label>
                <DatePicker
                  placeholderText="Scegli Data"
                  locale="it"
                  className='form-control form-control-solid'
                  name="endDate"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                  selected={filter?.dataFineA} 
                  onChange={(date) => setFilterProperty('dataFineA', date, null)}                  
                />
              </div>               
            </div>            

            <div className="row">
              <div className="mb-10 col-6">

                <label className="form-label">Cartellino</label>
                <input type="text"
                    className='form-control form-control-solid'
                    placeholder="Cartellino"
                    value={filter?.numeroOrdine} 
                    onChange={(e) => setFilterProperty('numeroOrdine', e.target.value, e)}
                    />
              </div>    
              <div className="mb-10 col-6">

                <label className="form-label">Cliente</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="Cliente"
                  value={filter?.cliente}
                  onChange={(e) => setFilterProperty('cliente', e.target.value, e)}
                />
              </div>            
            </div>

            <div className="row">
              <div className="mb-10 col-6">

                <label className="form-label">Articolo</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="Codice Articolo"
                  value={filter?.codiceArticolo}
                  onChange={(e) => setFilterProperty('codiceArticolo', e.target.value, e)}
                />
              </div>    
              <div className="mb-10 col-6">

                <label className="form-label">Colore</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="Colore"
                  value={filter?.colore}
                  onChange={(e) => setFilterProperty('colore', e.target.value, e)}
                />
              </div>            
            </div>

            <div className="row">

              <div className="mb-10 col-12">
                <label className="form-label">Macchina</label>
                <select
                  className='form-select'
                  value={filter?.idMacchina}
                  onChange={(e) => setFilterProperty('idMacchina', e.target.value)}>
                  <option value='0'>Tutte</option>

                  {unit?.machines.map(item => {
                    return (<option key={item.id} value={item.id}>{item.name}</option>);
                  })}

                </select>

              </div>

              <div className="mb-10 col-12">
                <label className="form-label">Mostra solo le mie lavorazioni</label>

                <select
                  className='form-select'
                  value={filter?.myJobsOnly}
                  onChange={(e) => setFilterProperty('myJobsOnly', e.target.value)}>
                  <option value='0'>No</option>
                  <option value='1'>Si</option>
                </select>
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetFilter}>
            Reset
          </Button> 
          <Button variant="primary" onClick={applyFilter}>
            Applica
          </Button>         
        </Modal.Footer>
      </Modal>
     
      <Modal show={modalNewJobShow} onHide={handleNewJobClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nuova lavorazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">

            <div className="row">

              <div className="mb-10 col-12">
                <label className="form-label">Macchina</label>
                  <select
                    className='form-select' 
                    value={selectedMachineId}
                    onChange={(e) => setSelectedMachineId(e.target.value)}>
                        <option value='0'>Scegli</option>
                        
                        {unit?.machines.map(item => {
                              return (<option key={item.id} value={item.id}>{item.name}</option>);
                          })}

                    </select>

              </div>

            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={goToNewJobPage}>
            Continua
          </Button>
        </Modal.Footer>
      </Modal>

        {/* START - Confirmation Modal */}     
        <Modal show={confirmationModalShow} onHide={handleConfirmationClose}>
          <Modal.Header closeButton>
            <Modal.Title>Conferma Operazione</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <p>Sei sicuro di voler continuare?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleConfirmationClose}>Annulla</Button>
            <Button variant="primary" onClick={confirmEndJob}>Conferma</Button>            
          </Modal.Footer>
        </Modal>

        <Modal show={modalResultShow} onHide={(e) => setModalResultShow(false) }>
          <Modal.Header closeButton>
            <Modal.Title>Esito Operazione</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
                {saveResult &&
                    <div className="row d-flex justify-content-center">

                        <div className="d-flex flex-center flex-column py-10 px-10 px-lg-20">

                            <span className="svg-icon svg-icon-2hx svg-icon-success mb-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                    <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                </svg>
                            </span>

                            <div className="text-center">
                                {/* <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div> */}
                                <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: modalMessage }}></div>
                            </div>
                        </div>

                    </div>         
                }

                {!saveResult &&
                    <div className="row d-flex justify-content-center">

                        <div className="d-flex flex-center flex-column py-10 px-10 px-lg-20">

                            <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                    <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                </svg>
                            </span>

                            <div className="text-center">
                                <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: modalMessage }}></div>
                            </div>
                        </div>

                    </div>                
                }                
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={(e) => setModalResultShow(false)}>
              Chiudi
            </Button>
          </Modal.Footer>
        </Modal>


        {/* END - Confirmation Modal */}      

      <EndSingleJobConfirmationModal
        show={modalEndSingleJobConfirmShow}
        onHide={endSingleJobConfirmationModalOnHide}
        onConfirm={endSingleJobConfirmationModalOnConfirm}
      />

    </div>
  )
}

export default UnitProdList