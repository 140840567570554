import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const API_ENDPOINT_PORT = process.env.REACT_APP_API_ENDPOINT_PORT;
const API_ENDPOINT_PROTOCOL = process.env.REACT_APP_API_ENDPOINT_PROTOCOL;
const API_ENDPOINT_APP_NAME = process.env.REACT_APP_API_ENDPOINT_APP_NAME;

const GetUrl = () => {
    //let protocol = "https";
    let protocol = API_ENDPOINT_PROTOCOL;
    let hostname = window.location.hostname;

    if(  !(typeof(API_ENDPOINT) === 'undefined') && API_ENDPOINT != "")
    {
        hostname = API_ENDPOINT;
    }

    if(  !(typeof(API_ENDPOINT_PORT) === 'undefined') && API_ENDPOINT_PORT != "")
    {
        hostname = hostname +":"+API_ENDPOINT_PORT;
    }

    if(  !(typeof(API_ENDPOINT_APP_NAME) === 'undefined') && API_ENDPOINT_APP_NAME != "")
    {
        hostname = hostname +"/"+API_ENDPOINT_APP_NAME;
    }

    let url = protocol+"://"+hostname;
    return url;
}

export default axios.create({
    baseURL: GetUrl()
});

export const axiosPrivate = axios.create({
    baseURL: GetUrl(), 
    headers: {'Content-Type': 'application/json'}, 
    withCredentials: true
});