import React from 'react';

const ChemicalsProductProcessedComponent = ({ data }) => {
    if (!data) {
      return <div>Nessun dato disponibile</div>;
    }
  
    const {
      anagrafica_prodotto,
      anagrafica_fornitore,
      componenti_chimici,
      pittogrammi_pericolo,
      indicazioni_pericolo,
      consigli_prudenza,
    } = data;
  
    return (
        <div className="container">

        {/* Anagrafica Prodotto */}
        <section>
          <h4>Anagrafica Prodotto</h4>
          <p className="mb-0"><strong>Nome:</strong> {anagrafica_prodotto.nome}</p>
          <p className="mb-0"><strong>Codice:</strong> {anagrafica_prodotto.codice}</p>
          <p className="mb-0"><strong>Descrizione:</strong> {anagrafica_prodotto.descrizione}</p>
          <p className="mb-0"><strong>Tipo:</strong> {anagrafica_prodotto.tipo}</p>
        </section>

        <hr class="hr" />

        {/* Anagrafica Fornitore */}
        <section className="mt-5">
          <h4>Anagrafica Fornitore</h4>
          <p className="mb-0"><strong>Nome:</strong> {anagrafica_fornitore.nome}</p>
          <p className="mb-0"><strong>Indirizzo:</strong> {anagrafica_fornitore.indirizzo}</p>
          <p className="mb-0"><strong>Telefono:</strong> {anagrafica_fornitore.telefono}</p>
          <p className="mb-0"><strong>Email:</strong> {anagrafica_fornitore.email}</p>
        </section>

        <hr class="hr" />

        {/* Componenti Chimici */}
        {/* <section className="mt-5">
          <h4>Componenti Chimici</h4>
          {componenti_chimici.map((componente, index) => (
            <div key={index} className="mb-3">
              <h5>Componente {index + 1}</h5>
              <p><strong>Nome:</strong> {componente.nome}</p>
              <p><strong>CAS:</strong> {componente.CAS || 'N/A'}</p>
              <p><strong>CE:</strong> {componente.CE}</p>
              <p><strong>Classificazione:</strong> {componente.classificazione.categoria}</p>
              <p><strong>Codice:</strong> {componente.classificazione.codice}</p>
              <p><strong>Concentrazione:</strong> {componente.concentrazione}</p>
            </div>
          ))}
        </section> */}

            {/* Componenti Chimici */}
            <section className="mt-5">
                <h4>Componenti Chimici</h4>
                <table className="table table-rounded table-striped border gy-7 gs-7">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>CAS</th>
                            <th>CE</th>
                            <th>Classificazione</th>
                            <th>Concentrazione</th>
                        </tr>
                    </thead>
                    <tbody>
                        {componenti_chimici.map((componente, index) => (
                            <tr key={index}>
                                <td>{componente.nome}</td>
                                <td>{componente.CAS || 'N/A'}</td>
                                <td>{componente.CE}</td>
                                <td>{componente.classificazione.codice}</td>
                                <td>{componente.concentrazione}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>

        <hr class="hr" />

        {/* Pittogrammi di Pericolo */}
        <section className="mb-4">
        <h4>Pittogrammi di Pericolo</h4>
        <div className="d-flex flex-wrap">
            {pittogrammi_pericolo.map((pittogramma, index) => (
            <div key={index} className="text-center mx-3 mb-3">
                <img
                src={`/media/chemicals/safety-pictograms/${pittogramma}.png`}
                alt={pittogramma}
                style={{ width: '80px', height: '80px' }}
                />
                <div>{pittogramma}</div>
            </div>
            ))}
        </div>
        </section>

        <hr class="hr" />

        {/* Indicazioni di Pericolo */}
        <section className="mt-5">
          <h4>Indicazioni di Pericolo</h4>
          <ul className="list-group">
            {indicazioni_pericolo.map((indicazione, index) => (
              <li key={index} className="list-group-item">
                <strong>{indicazione.codice}:</strong> {indicazione.descrizione}
              </li>
            ))}
          </ul>
        </section>

        {/* Consigli di Prudenza */}
        <section className="mt-5">
          <h4>Consigli di Prudenza</h4>
          <ul className="list-group">
            {consigli_prudenza.map((consiglio, index) => (
              <li key={index} className="list-group-item">
                <strong>{consiglio.codice}:</strong> {consiglio.descrizione}
              </li>
            ))}
          </ul>
        </section>
 
        </div>
      );
  };

  export default ChemicalsProductProcessedComponent;


