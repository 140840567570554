import { useNavigate, Link, useLocation, Outlet, useParams } from "react-router-dom";
import { useState, useEffect, useMemo } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { usePageData } from '../../../_metronic/layout/core';
import useAuth from "../../../hooks/useAuth";
import DatePicker, {registerLocale} from 'react-datepicker';
import Select from 'react-select';
import clsx from 'clsx'

import { isCreateMode, isEditMode, isViewMode, validateProperty, validateEntity, checkValidationErrors, GetHandlerPayload, normalizeEntity, downloadPdf } from "./InventoryMovementsLoadHelper";
import { getBatchDetails, updateTotalQuantity, getArticleBySearchCode} from "./InventoryMovementsHelper";

import {ConfirmationModal, ResultModal} from "./Modals";
import {PageErrorMessage, FormInputString} from "./Components";

const InventoryMovementsLoadHandler = () => {    
    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [isError, setIsError] = useState();
    const [isLoading, setIsLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [showIndicatorProgress, setShowIndicatorProgress] = useState('');
    const [modalShow, setModalShow] = useState();
    const [modalMessage, setModalMessage] = useState();
    const [saveInProgress, setSaveInProgress] = useState();
    const [deleteInProgress, setDeleteInProgress] = useState();
    const [saveResult, setSaveResult] = useState();
    const [newEntityViewModel, setNewEntityViewModel] = useState();
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const [deleteConfirmationModalShow, setDeleteConfirmationModalShow] = useState(false);
    const [operationInProgress, setOperationInProgress] = useState("");
    const [isPdfInProgress, setIsPdfInProgress] = useState(false);
    const [quantityTotal, setQuantityTotal] = useState(0);

    const [isGettingDetail, setIsGettingDetail] = useState(false);  
    const [getDetailResult, setGetDetailResult] = useState(0);    

    const [isGettingOrderDetail, setIsGettingOrderDetail] = useState(false);  
    const [getOrderDetailResult, setGetOrderDetailResult] = useState(0);
    
    var forseUpdate = new Date();
    
    let { entityId } = useParams();
    let { handlerMode } = useParams();
    let { inventoryId } = useParams();
    
    let navPrefix = "/inventory";
    let entityName = "Carico";
    let entitiesName = "Movimenti";
    let entityType = "leather";

    const { setPageTitle, pageMachines } = usePageData();
    const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();




    const handleClose = () => 
    {
        if (saveResult == true) 
        {
            if(operationInProgress == "delete")
            {
                navigate(`${navPrefix}/${inventoryId}/movements`);
            }
            else
            {
                navigate(`${navPrefix}/${inventoryId}/movements/view/load/${newEntityViewModel.id}`);
            }
        }

        setModalShow(false);
    }

    const handleConfirmationClose = () => {
        setConfirmationModalShow(false);
    }

    const handleDeleteConfirmationClose = () => {
        setDeleteConfirmationModalShow(false);
    }

    const updateTotal = () => {
        updateTotalQuantity(newEntityViewModel, setQuantityTotal);
    }


    const confirmCreate = async () => 
    {
        setConfirmationModalShow(false);

        //Se c'è un salvataggio in corso non possono essere inoltrare ulteriori richieste
        if (saveInProgress) {
            return;
        }

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if (checkValidationErrors(newEntityViewModel) == true) {
            return;
        }

        setLoadingSave(true);
        setSaveInProgress(true);

        //Impostazione dell'id macchina per cui creare il ciclo di lavoro

        var endpoint = "";

        if(isEditMode(location))
        {
            endpoint = `/inventoryMovement/editEntity`;
        }
        else
        {
            endpoint = `/inventoryMovement/createEntity`;
        }

        let isMounted = true;
        const controller = new AbortController();

        const sendRequest = async () =>
        {
            try 
            {
                var payload = GetHandlerPayload(newEntityViewModel, inventoryId);

                const response = await axiosPrivate.post(endpoint,
                    payload,
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,
                        signal:controller.signal
                    }
                );
    
                if (isMounted) {
                    setNewEntityViewModel({ ...newEntityViewModel, id: response.data });
    
                    setModalMessage("Operazione avvenuta con successo");
                    setLoadingSave(false);
                    setSaveInProgress(false);
                    setSaveResult(true);
                    setModalShow(true);
                }
            }
            catch (err) 
            {
                console.error(err);
                setSaveResult(false);
                setModalMessage(`${err?.response?.data?.message}`);
                setLoadingSave(false);
                setSaveInProgress(false);
                setModalShow(true);
            }
        }

        sendRequest();

        return () => {
            isMounted = false;
            controller.abort();
          }
    }

    const setPropertyValue = (propertyCode, value, event) => {

        if (propertyCode == "date" || propertyCode == "supplierId" || propertyCode == "articleId" || event.target.validity.valid) {
            var wc = { ...newEntityViewModel };

            wc[propertyCode].value = value;
            wc[propertyCode] = validateProperty(wc[propertyCode]);

            if(propertyCode == "batchNumber")
            {
                setGetDetailResult(0);
            }

            //Quando viene scelto l'articolo dall'elenco, vengono popolati anche i relativi campi
            if (propertyCode == "articleId") {

                let articleCode = '';
                let articleName = '';
                let colorName = '';

                if (value != null) {

                    if (!(typeof value.articleCode === 'undefined')) {
                        articleCode = value.articleCode;
                    }

                    if (!(typeof value.articleName === 'undefined')) {
                        articleName = value.articleName;
                    }

                    if (!(typeof value.colorName === 'undefined')) {
                        colorName = value.colorName;
                    }
                }

                // wc['articleCode'].value = articleCode;
                // wc['articleCode'] = validateProperty(wc['articleCode']);

                wc['productionArticleName'] = articleName;
                wc['productionArticleColor'] = colorName;
            }

            setNewEntityViewModel(wc);
        }
    }

    const setLoadingSave = (isLoading) => {
        if (isLoading) {
            setShowIndicatorProgress('on');
        }
        else {
            setShowIndicatorProgress('off');
        }
    }

    const deleteEntity = () => 
    {
        if(deleteInProgress)
        {
            return;
        }

        setDeleteConfirmationModalShow(true);
    }

    const undo = () =>
    {
        navigate(-1);
    }

    const confirmDeleteEntity = async () => {

        setDeleteConfirmationModalShow(false);

        //Se c'è un salvataggio in corso non possono essere inoltraTe ulteriori richieste
        if(deleteInProgress)
        {
            return;
        }

        setLoadingSave(true);
        setDeleteInProgress(true);
        setOperationInProgress("delete");

        let isMounted = true;
        const controller = new AbortController();

        const sendRequest = async () =>
        {
                //Impostazione dell'id macchina per cui creare il ciclo di lavoro
                        
                try 
                {
                    const response = await axiosPrivate.get(`/inventoryMovement/deleteEntity/${entityId}`, 
                        {
                            headers: {'Content-Type':'application/json'},
                            withCredentials: true,
                        }
                    );

                    if (isMounted) 
                    {
                        setModalMessage("Operazione avvenuta con successo");
                        setLoadingSave(false);
                        setDeleteInProgress(false);
                        setSaveResult(true);
                        setModalShow(true);
                    }
                }
                catch(err) 
                {
                    console.error(err);
                    setSaveResult(false);
                    setModalMessage(`${err?.response?.data?.message}`);
                    setLoadingSave(false);
                    setDeleteInProgress(false);
                    setModalShow(true);
                    setOperationInProgress("");
                }    
        }

        sendRequest();

        return () => {
            isMounted = false;
            controller.abort();
          }
    }    

    //Setting Page BreadCrumb
    useEffect(() => {

        setPageTitle(entitiesName);
        
        var breadcrumb = `Nuovo ${entityName}`;
        
        if(isEditMode(location))
        {
            breadcrumb = `Modifica ${entityName} N. ${entityId}`;
        }
        else if(isViewMode(location))
        {
            breadcrumb = `Dettaglio ${entityName} N. ${entityId}`;
        }

        setPageBreadcrumbs([breadcrumb]);
    }, [entityId, handlerMode])

    useEffect(() => {

        setIsLoading(true);

        //Viene impostato a zero questo valore per evitare di mantenere il colore verde o rosso nel relativo elemento della UI
        setGetDetailResult(0);

        let isMounted = true;
        const controller = new AbortController();

        const getEntityModel = async () => {

            try 
            {
                const response = await axiosPrivate.get(`/inventoryMovement/getEntityModel/${entityId}/${entityType}/load`, 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,                    
                    signal: controller.signal
                });

                if (isMounted) {

                    // //Trasformazione delle liste per renderle compatibili con le relative select
                    // response.data.articles = response.data.articles.reduce((res, item) => {
                    //     res.push({ value: item.key, label: item.value});
                    //     return res;
                    // }, []);
            
                    // response.data.suppliers = response.data.suppliers.reduce((res, item) => {
                    //     res.push({ value: item.key, label: item.value});
                    //     return res;
                    // }, []);

                    response.data = normalizeEntity(response.data);

                    //Validazione iniziale
                    validateEntity(response.data);

                    //Aggiornamento quantità pelli calcolata
                    updateTotalQuantity(response.data, setQuantityTotal);

                    setNewEntityViewModel(response.data);

                    setIsLoading(false);
                    setIsError(false);
                }
            }
            catch (err) {
                console.error(err);
                setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
                setIsLoading(false);
                setIsError(true);
            }
        }

        getEntityModel();

        return () => {
            isMounted = false;
            controller.abort();
          }

    }, [entityId, handlerMode])


    const handleSubmit = (e) => {

        e.preventDefault();

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if (checkValidationErrors(newEntityViewModel) == true) {
            return;
        }

        setConfirmationModalShow(true);
    }

    // const onDownloadPdf = () => 
    // {
    //     downloadPdf(entityId, axiosPrivate, isPdfInProgress, setIsPdfInProgress, setModalMessage, setSaveResult, setModalShow);
    // }

    const  onKeyDownBatchNumber = async (event) => 
    {
        if (event.key === 'Enter') 
        {
            //Ho inserito questa istruzione per evitare il submit del form alla pressione del tasto invio
            event.preventDefault();

            var batchNumber = event.target.value;

            //controllo della presenza del previsso LBN- oppure di LBN' (Leather Batch Number)
            //Ho dovuto gestire l'apice perchè alcuni lettori di codici a barre non decodificavano correttamente il trattino
            
            // batchNumber = batchNumber.replace("LBN-", "");
            // batchNumber = batchNumber.replace("LBN'", "");

            let regex = /LBN-?|LBPN-?\d+-?/g;
            batchNumber = batchNumber.replace(regex, '');

            setPropertyValue('batchNumber', batchNumber, event);

            //Lettura dei dati del lotto
            setIsGettingDetail(true);
            var batch = await getBatchDetails(axiosPrivate, batchNumber);

            setIsGettingDetail(false);

            var supplierName = "";
            var inventoryArticleName = "";
            var quantity = "";

            if(batch != null)
            {
                supplierName = batch.supplierName;
                inventoryArticleName = batch.inventoryArticleName;
                quantity = ""+batch.quantity;

                setGetDetailResult(1);
            }
            else
            {
                setGetDetailResult(2);
            }

            var evm = {...newEntityViewModel};
                        
            evm.supplierName = supplierName;
            evm.inventoryArticleName = inventoryArticleName;

            //Per il numero di pelli occorre eseguire anche la validazione
            // evm.quantity.value = quantity;
            // evm.quantity = validateProperty(evm.quantity);

            setNewEntityViewModel(evm);
        }
    }

    const onKeyDownProductionOrderNumber = async (event) => 
        {
            if (event.key === 'Enter') 
            {
                //Ho inserito questa istruzione per evitare il submit del form alla pressione del tasto invio
                event.preventDefault();
    
                var searchCode = event.target.value;
    
                //Lettura dei dati del lotto
                setIsGettingOrderDetail(true);
                var searcheResult = await getArticleBySearchCode(axiosPrivate, searchCode);
    
                setIsGettingOrderDetail(false);
    
                var productionArticleId = "";
                var productionArticleName = "";
                var productionArticleColor = "";
    
                if(searcheResult != null && searcheResult.responseCode == "1")
                {
                    productionArticleId = searcheResult.articleId;
                    productionArticleName = searcheResult.articleName;
                    productionArticleColor = searcheResult.articleColor;
    
                    setGetOrderDetailResult(1);
                }
                else
                {
                    setGetOrderDetailResult(2);
                }
    
                var evm = {...newEntityViewModel};
                            
                //Selezione articolo dal menu a tendina
                if(productionArticleId != "" && productionArticleId != "0")
                {
                    var selectEntry = {};
                    evm.articles.forEach(entry=>{
                
                        if(entry.value == productionArticleId)
                        {
                            selectEntry = entry;
                        }
                    })
                
                    evm.articleId.value = selectEntry;
                }
                else
                {
                    evm.articleId.value = null;
                }

                evm.articleId = validateProperty(evm.articleId);

                evm.productionArticleName = productionArticleName;
                evm.productionArticleColor = productionArticleColor;
    
                setNewEntityViewModel(evm);
            }
        } 

    return (
        <>
            <div id="kt_content_container" className="mx-5">

                {/* begin::Spinner */}
                {isLoading &&
                    <div className="row d-flex justify-content-center m-20">
                        <Spinner animation="border" variant="primary" />
                    </div>
                }
                {/* end::Spinner */}

                {/* begin::Error message */}
                {!isLoading && isError &&
                    <PageErrorMessage message={errorMessage}/>
                }
                {/* end::Error message */}

                {!isLoading && !isError &&

                    <form
                        className="form w-100"
                        noValidate="novalidate"
                        id="kt_sign_in_form"
                        onSubmit={handleSubmit}>

                        <div className='card mb-5 mb-xl-8 mt-5'>

                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>
                                        {isEditMode(location) && <>Modifica {entityName}</>}
                                        {isViewMode(location) && <>Dettaglio {entityName}</>}
                                        {isCreateMode(location) && <>Nuovo {entityName}</>}
                                    </span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>

                                <div className="row">

                                <div className="mb-10 col-6 col-md-4">
                                    <label className="form-label">Data</label>

                                    <div className="d-table w-100">
                                            <div className="table-row">
                                                <div className="d-table-cell w-100">
                                                    <DatePicker
                                                        placeholderText="Scegli Data"
                                                        locale="it"
                                                        className={clsx('form-control form-control-solid', newEntityViewModel?.date.isError ? 'bg-light-danger border-danger' : '')}

                                                        name="date"
                                                        dateFormat="dd/MM/yyyy HH:mm"
                                                        autoComplete="off"
                                                        showTimeSelect
                                                        timeFormat="p"
                                                        timeIntervals={15}

                                                        selected={newEntityViewModel?.date.value}
                                                        onChange={(date) => setPropertyValue('date', date, null)}
                                                        {...(isViewMode(location)) && {disabled: true}}
                                                    />
                                                    
                                                </div>
                                                
                                                <div className="d-table-cell">
                                                    <div className="btn btn-secondary" disabled onClick={(e) => { if(!isViewMode(location)) {setPropertyValue('date', new Date(), null) }}}>
                                                        <i className="fas fa-clock p-0"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                </div>

                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Codice Lotto</label>

                                        <div className="input-group">
                                            <span  className={clsx({'input-group-text' : true, 'bg-success': getDetailResult == 1 , 'bg-danger': getDetailResult == 2  })}>
                                            
                                                {!isGettingDetail && 
                                                    <i className="fas fa-file-alt text-dark" style={{paddingLeft: "1px",paddingRight: "2px"}}></i>
                                                }

                                                {isGettingDetail && 
                                                <div className="spinner-border spinner-border-sm text-warning" >
                                                </div>
                                                }
                                                                                            
                                            </span>
                                            <input type="text"
                                                className={clsx('form-control', newEntityViewModel?.batchNumber.isError ? 'bg-light-danger border-danger' : '')}
                                                placeholder=""
                                                value={newEntityViewModel?.batchNumber.value}
                                                title={newEntityViewModel?.batchNumber.errorMessage}
                                                autoComplete="off"
                                                onChange={(e) => setPropertyValue('batchNumber', e.target.value, e)}
                                                onKeyDown={(e) => onKeyDownBatchNumber(e)}
                                                {...(isViewMode(location)) && {disabled: true}}
                                            />
                                        </div>
                                    </div>

                                    <div className='mb-10 col-12 col-md-4'>
                                        <label className="form-label">Numero Bancale</label>
                                        <input type="text"
                                            className='form-control'
                                            placeholder=""
                                            value={newEntityViewModel?.palletNumber.value}
                                            title={newEntityViewModel?.palletNumber.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('palletNumber', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>

                                    {/* <div className='mb-10 col-12 col-md-6'> 
                                        <label className="form-label">Fornitore Lotto</label>
                                        <input type="text"
                                            className='form-control'
                                            placeholder=""
                                            value={newEntityViewModel?.supplierName}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>

                                    <div className='mb-10 col-12 col-md-6'>
                                        <label className="form-label">Merce Lotto</label>
                                        <input type="text"
                                            className='form-control'
                                            placeholder=""
                                            value={newEntityViewModel?.inventoryArticleName}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div> */}

                                    {/* {inventoryId >= 1 && <>

                                        <div className="mb-10 col-12 col-md-6">
                                        <label className="form-label">Numero Cartellino</label>

                                        <div className="input-group mb-3">
                                            <span className={clsx({ 'input-group-text': true, 'bg-success': getOrderDetailResult == 1, 'bg-danger': getOrderDetailResult == 2 })}>

                                                {!isGettingOrderDetail &&
                                                    <i className="fas fa-file-alt text-dark" style={{ paddingLeft: "1px", paddingRight: "2px" }}></i>
                                                }

                                                {isGettingOrderDetail &&
                                                    <div className="spinner-border spinner-border-sm text-warning" >
                                                    </div>
                                                }

                                            </span>
                                            <input type="text"
                                                className={clsx('form-control', newEntityViewModel?.productionOrderNumber.isError ? 'bg-light-danger border-danger' : '')}
                                                placeholder=""
                                                value={newEntityViewModel?.productionOrderNumber.value}
                                                title={newEntityViewModel?.productionOrderNumber.errorMessage}
                                                autoComplete="off"
                                                onChange={(e) => setPropertyValue('productionOrderNumber', e.target.value, e)}
                                                {...(isViewMode(location)) && { disabled: true }}
                                                // onKeyDown={(e) => onKeyDownProductionOrderNumber(e)} 
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className='mb-10 col-12 col-md-6'>
                                        <label className="form-label">Articolo</label>
                                            <Select
                                                isClearable={true}
                                                options={newEntityViewModel?.articles}
                                                isSearchable={true}
                                                value={newEntityViewModel?.articleId.value }
                                                onChange={(selectedOption) => setPropertyValue('articleId', selectedOption)}
                                                isDisabled={isViewMode(location)}
                                            />  
                                    </div>
                                    
                                    </>} */}
                                </div>
                            </div>
                        </div>

                        <div className='card mb-5 mb-xl-8 mt-5'>

<div className='card-header border-0 pt-5'>
    <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bolder fs-3 mb-1'>Articolo caricato</span>
    </h3>
</div>

<div className='card-body py-3'>
    <div className='row'>

        <div className="mb-10 col-12 col-md-2">
            <label className="form-label">Codice Ricerca</label>

            <div className="input-group mb-3">
                <span className={clsx({ 'input-group-text': true, 'bg-success': getOrderDetailResult == 1, 'bg-danger': getOrderDetailResult == 2 })}>

                    {!isGettingOrderDetail &&
                        <i className="fas fa-file-alt text-dark" style={{ paddingLeft: "1px", paddingRight: "2px" }}></i>
                    }

                    {isGettingOrderDetail &&
                        <div className="spinner-border spinner-border-sm text-warning" >
                        </div>
                    }

                </span>
                <input type="text"
                    className={clsx('form-control', newEntityViewModel?.productionOrderNumber.isError ? 'bg-light-danger border-danger' : '')}
                    placeholder=""
                    value={newEntityViewModel?.productionOrderNumber.value}
                    title={newEntityViewModel?.productionOrderNumber.errorMessage}
                    autoComplete="off"
                    onChange={(e) => setPropertyValue('productionOrderNumber', e.target.value, e)}
                    {...(isViewMode(location)) && { disabled: true }}
                    onKeyDown={(e) => onKeyDownProductionOrderNumber(e)} 
                />
            </div>
        </div>

        <div className='mb-10 col-12 col-md-4'>
            <label className="form-label">Articolo</label>
                <Select
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: newEntityViewModel?.articleId.isError ? 'red' : 'grey',
                            backgroundColor: newEntityViewModel?.articleId.isError ? '#FFF5F8' : 'none'
                        }),
                    }}

                    isClearable={true}
                    options={newEntityViewModel?.articles}
                    isSearchable={true}
                    value={newEntityViewModel?.articleId.value }
                    onChange={(selectedOption) => setPropertyValue('articleId', selectedOption)}
                    isDisabled={isViewMode(location)}
                />  
        </div>

        {/* <div className="mb-10 col-12 col-md-4">
            <label className="form-label">Cliente</label>
            <input type="text"
                className='form-control'
                placeholder=""
                value={newEntityViewModel?.productionArticleCustomer}
                autoComplete="off"
                disabled
            />
        </div> 
        */}

        <div className="mb-10 col-12 col-md-3">
            <label className="form-label">Nome Articolo</label>
            <input type="text"
                className='form-control'
                placeholder=""
                value={newEntityViewModel?.productionArticleName}
                autoComplete="off"
                disabled
            />
        </div>

        <div className="mb-10 col-12 col-md-3">
            <label className="form-label">Colore Articolo</label>
            <input type="text"
                className='form-control'
                placeholder=""
                value={newEntityViewModel?.productionArticleColor}
                autoComplete="off"
                disabled
            />
        </div>


        
                          
    </div>
</div>
</div>


                        <div className='card mb-5 mb-xl-8 mt-5'>
                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Quantità Pelli</span>
                                </h3>
                            </div>
                            <div className='card-body py-3'>
                                <div className="row mb-5">

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Scelta I</label>
                                        <input type="text"
                                            className={clsx('form-control', newEntityViewModel?.quantity1.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            pattern="[0-9]*"
                                            value={newEntityViewModel?.quantity1.value}
                                            title={newEntityViewModel?.quantity1.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => {setPropertyValue('quantity1', e.target.value, e); updateTotal()}}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div> 

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Scelta II</label>
                                        <input type="text"
                                            className={clsx('form-control', newEntityViewModel?.quantity2.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            pattern="[0-9]*"
                                            value={newEntityViewModel?.quantity2.value}
                                            title={newEntityViewModel?.quantity2.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => {setPropertyValue('quantity2', e.target.value, e); updateTotal()}}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>  

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Scelta III</label>
                                        <input type="text"
                                            className={clsx('form-control', newEntityViewModel?.quantity3.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            pattern="[0-9]*"
                                            value={newEntityViewModel?.quantity3.value}
                                            title={newEntityViewModel?.quantity3.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => {setPropertyValue('quantity3', e.target.value, e); updateTotal()}}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Scelta IV</label>
                                        <input type="text"
                                            className={clsx('form-control', newEntityViewModel?.quantity4.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            pattern="[0-9]*"
                                            value={newEntityViewModel?.quantity4.value}
                                            title={newEntityViewModel?.quantity4.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => {setPropertyValue('quantity4', e.target.value, e); updateTotal()}}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>                              

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Altro</label>
                                        <input type="text"
                                            className={clsx('form-control', newEntityViewModel?.quantity.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            pattern="[0-9]*"
                                            value={newEntityViewModel?.quantity.value}
                                            title={newEntityViewModel?.quantity.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => {setPropertyValue('quantity', e.target.value, e); updateTotal()}}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div> 
                                    
                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Scarto</label>
                                        <input type="text"
                                            className={clsx('form-control', newEntityViewModel?.waste.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            pattern="[0-9]*"
                                            value={newEntityViewModel?.waste.value}
                                            title={newEntityViewModel?.waste.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => {setPropertyValue('waste', e.target.value, e); updateTotal()}}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-6">
                                        <label className="form-label">Totale</label>
                                        <input type="text"
                                            className='form-control'
                                            value={quantityTotal}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className='card mb-5 mb-xl-8 mt-5'>
                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Note</span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>
                                <div className='row mb-5'>
                                    <textarea
                                            className={clsx('form-control', newEntityViewModel?.notes?.isError ? 'bg-light-danger border-danger' : '')} 
                                            placeholder="" 
                                            value={newEntityViewModel?.notes?.value} 
                                            title={newEntityViewModel?.notes?.ErrorMessage} 
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue("notes", e.target.value, e)}
                                            rows="5"
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />                                    
                                </div>
                            </div>
                        </div>

                        <div className="text-center">

                            {isViewMode(location) && <>

                                <Link to={`${navPrefix}/${inventoryId}/movements`} className='btn btn-lg btn-primary w-25 m-5'>
                                    Elenco
                                </Link>

                                <Link to={`${navPrefix}/${inventoryId}/movements/edit/load/${entityId}`} className='btn btn-lg btn-primary w-25 m-5'>
                                    Modifica
                                </Link>

                                {/* <div
                                    className='btn btn-lg btn-primary w-25 m-5'
                                    onClick={(e) => onDownloadPdf()}
                                    data-kt-indicator={isPdfInProgress == true ? 'on' : 'off'}>

                                    <span className="svg-icon svg-icon-1">
                                        <i className="fas fa-file-pdf"></i>
                                    </span>

                                    <span className="indicator-label" >Scarica PDF</span>
                                    <span className="indicator-progress" > Scarica PDF
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </div> */}

                                <div className='btn btn-lg btn-danger w-25 m-5' onClick={(e) => deleteEntity()} data-kt-indicator={deleteInProgress ? "on": "off"}>
                                        <span className="indicator-label" >Elimina</span>
                                        <span className="indicator-progress" > Elimina
                                            <span className="spinner-border spinner-border-sm align-middle ms-2">
                                        </span>
                                    </span>
                                </div>  
                            </>
                            }

                            {!isViewMode(location) && <>

                                <a className="btn btn-lg btn-primary w-25 m-5" onClick={(e) => undo()} >
                                    Annulla
                                </a>

                                <button
                                    type="submit"
                                    id="kt_sign_in_submit"
                                    className="btn btn-lg btn-primary w-25 m-5"
                                    data-kt-indicator={showIndicatorProgress}>
                                    <span className="indicator-label">Salva</span>
                                    <span className="indicator-progress" >Caricamento...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </>
                            }
                        </div>

                    </form>
                }
            </div>

            <ConfirmationModal 
                show={deleteConfirmationModalShow} 
                onHide={handleDeleteConfirmationClose} 
                onConfirm={confirmDeleteEntity} />

            <ConfirmationModal 
                show={confirmationModalShow} 
                onHide={handleConfirmationClose} 
                onConfirm={confirmCreate} />

            <ResultModal 
                show={modalShow} 
                onHide={handleClose} 
                result={saveResult}
                message={modalMessage}/> 
        </>

    )
}

export default InventoryMovementsLoadHandler