import { useNavigate, Link, useLocation, Outlet, useParams } from "react-router-dom";
import { useState, useEffect, useMemo } from 'react';
import { KTSVG } from '../../../_metronic/helpers'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { usePageData } from '../../../_metronic/layout/core';
import useAuth from "../../../hooks/useAuth";
import DatePicker, { registerLocale } from 'react-datepicker';
import DataTable from 'react-data-table-component';
import clsx from 'clsx'
import { setTotals, totalColumns, totalsTableStyles } from './PackingListHelper';

const PackingListCreate = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [isError, setIsError] = useState();
    const [isLoading, setIsLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [showIndicatorProgress, setShowIndicatorProgress] = useState('');
    const [modalShow, setModalShow] = useState();
    const [modalMessage, setModalMessage] = useState();
    const [saveInProgress, setSaveInProgress] = useState();
    const [saveResult, setSaveResult] = useState();
    const [newWorkCycleViewModel, setNewWorkCycleViewModel] = useState();
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const [machine, setMachine] = useState();
    const [machineCode, setMachineCode] = useState();
    const [selectedRowId, setSelectedRowId] = useState();
    const [clearSelectedRows, setClearSelectedRows] = useState(false);
    

    const [tableData, setTableData] = useState([]);
    const [rowId, setRowId] = useState(0);

    let { packingListId } = useParams();

    const { setPageTitle, pageMachines } = usePageData();
    const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();

      const columns = [
        {
            name: 'Num. Box',
            selector: row => row.boxNumber,
            width: '100px',
            cell: (row, index, column, id) => {
                return (
                    <input type="text"
                        className={clsx('form-control', row?.boxNumber.isError ? 'bg-light-danger border-danger' : '')}
                        placeholder=""
                        pattern="[0-9]*"
                        value={row?.boxNumber.value}
                        title={row?.boxNumber.errorMessage}
                        autoComplete="off"
                        onChange={(e) => setRowPropertyValue(row, 'boxNumber', e.target.value, e)}
                    />)
            }
        },
        {
            name: 'Num. Ord.',
            selector: row => row.numeroOrdine,
            
            cell: (row, index, column, id) => {
                return (
                    <input type="text"
                        className={clsx('form-control', row?.numeroOrdine.isError ? 'bg-light-danger border-danger' : '')}
                        placeholder=""
                        value={row?.numeroOrdine.value}
                        title={row?.numeroOrdine.errorMessage}
                        autoComplete="off"
                        onChange={(e) => setRowPropertyValue(row, 'numeroOrdine', e.target.value, e)}
                    />)
            }
        },
        {
            name: 'Articolo',
            selector: row => row.descrizioneArticolo,
            cell: (row, index, column, id) => {
                return (
                    <input type="text"
                        className={clsx('form-control', row?.descrizioneArticolo.isError ? 'bg-light-danger border-danger' : '')}
                        placeholder=""
                        value={row?.descrizioneArticolo.value}
                        title={row?.descrizioneArticolo.errorMessage}
                        autoComplete="off"
                        onChange={(e) => setRowPropertyValue(row, 'descrizioneArticolo', e.target.value, e)}
                    />)
            }
        },
        {
            name: 'Lotto',
            selector: row => row.numeroLotto,
            cell: (row, index, column, id) => {
                return (
                    <input type="text"
                        className={clsx('form-control', row?.numeroLotto.isError ? 'bg-light-danger border-danger' : '')}
                        placeholder=""
                        value={row?.numeroLotto.value}
                        title={row?.numeroLotto.errorMessage}
                        autoComplete="off"
                        onChange={(e) => setRowPropertyValue(row, 'numeroLotto', e.target.value, e)}
                    />)
            }
        },
        {
            name: 'Cod. Art.',
            selector: row => row.codiceArticolo,
            cell: (row, index, column, id) => {
                return (
                    <input type="text"
                        className={clsx('form-control', row?.codiceArticolo.isError ? 'bg-light-danger border-danger' : '')}
                        placeholder=""
                        value={row?.codiceArticolo.value}
                        title={row?.codiceArticolo.errorMessage}
                        autoComplete="off"
                        onChange={(e) => setRowPropertyValue(row, 'codiceArticolo', e.target.value, e)}
                    />)
            }
        },
        {
            name: 'Colore',
            selector: row => row.Colore,
            cell: (row, index, column, id) => {
                return (
                    <input type="text"
                        className={clsx('form-control', row?.colore.isError ? 'bg-light-danger border-danger' : '')}
                        placeholder=""
                        value={row?.colore.value}
                        title={row?.colore.errorMessage}
                        autoComplete="off"
                        onChange={(e) => setRowPropertyValue(row, 'colore', e.target.value, e)}
                    />)
            }
        },

        {
            name: 'Scelta',
            width: '140px',
            selector: row => row.scelta,
            cell: (row, index, column, id) => {
                return (
                    <input type="text"
                    className={clsx('form-control text-end', row?.scelta.isError ? 'bg-light-danger border-danger' : '')}
                    placeholder=""
                    value={row?.scelta.value}
                    title={row?.scelta.errorMessage}
                    autoComplete="off"
                    onChange={(e) => setRowPropertyValue(row, 'scelta', e.target.value, e)}
                />)
            }
        },
        {
            name: 'Num. Pacchi',
            selector: row => row.numeroPacchi,
            width: '110px',
            cell: (row, index, column, id) => {
                return (
                    <input type="text"
                    className={clsx('form-control text-end', row?.numeroPacchi.isError ? 'bg-light-danger border-danger' : '')}
                    placeholder=""
                    pattern="[0-9]*"
                    value={row?.numeroPacchi.value}
                    title={row?.numeroPacchi.errorMessage}
                    autoComplete="off"
                    onChange={(e) => setRowPropertyValue(row, 'numeroPacchi', e.target.value, e)}
                />)
            }
        },
        {
            name: 'Superficie',
            selector: row => row.superficie,
            width: '100px',
            cell: (row, index, column, id) => {
                return (
                    <input type="text"
                    className={clsx('form-control text-end', row?.superficie.isError ? 'bg-light-danger border-danger' : '')}
                    placeholder=""
                    pattern="[0-9]*"
                    value={row?.superficie.value}
                    title={row?.superficie.errorMessage}
                    autoComplete="off"
                    onChange={(e) => setRowPropertyValue(row, 'superficie', e.target.value, e)}
                />)
            }
        },
        {
            name: 'Num. Pelli',
            selector: row => row.numeroPelli,
            width: '100px',
            cell: (row, index, column, id) => {
                return (
                    <input type="text"
                    className={clsx('form-control  text-end', row?.numeroPelli.isError ? 'bg-light-danger border-danger' : '')}
                    placeholder=""
                    pattern="[0-9]*"
                    value={row?.numeroPelli.value}
                    title={row?.numeroPelli.errorMessage}
                    autoComplete="off"
                    onChange={(e) => setRowPropertyValue(row, 'numeroPelli', e.target.value, e)}
                />)
            }
        }
    ];


    const isEditMode = () => {

        var output = false;

        if(typeof packingListId  === 'undefined')
        {
            output = false;
        }   
        else
        {
            output = true;
        }

        return output;
    }

    const annullaSalva = () => {
        if (saveInProgress == true) 
        {
            return;
        }
        else 
        {
            //Ritorna alla pagina precedente
            navigate(-1);
        }
    }

    const handleClose = () => {
        if (saveResult == true) {
            navigate(`/packingList/view/${newWorkCycleViewModel.id}`);
        }
        else {
            setModalShow(false);
        }
    }

    const handleConfirmationClose = () => {
        setConfirmationModalShow(false);
    }

    const confirmCreate = async () => {
        setConfirmationModalShow(false);

        //Se c'è un salvataggio in corso non possono essere inoltrare ulteriori richieste
        if (saveInProgress) {
            return;
        }

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if (checkValidationErrors() == true) {
            return;
        }

        setLoadingSave(true);
        setSaveInProgress(true);

        //Impostazione dell'id macchina per cui creare il ciclo di lavoro

        var endpoint = "";

        if(isEditMode())
        {
            endpoint = `/packingList/edit`;
        }
        else
        {
            endpoint = `/packingList/create`;
        }

        try {
            const response = await axiosPrivate.post(endpoint,
                JSON.stringify(newWorkCycleViewModel),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );

            setNewWorkCycleViewModel({ ...newWorkCycleViewModel, id: response.data });

            setModalMessage("Operazione avvenuta con successo");
            setLoadingSave(false);
            setSaveInProgress(false);
            setSaveResult(true);
            setModalShow(true);
        }
        catch (err) {
            console.error(err);
            setSaveResult(false);
            setModalMessage(`${err}<br>${err?.response?.data?.message}`);
            setLoadingSave(false);
            setSaveInProgress(false);
            setModalShow(true);

            //navigate('/login', {state : {from: location }, replace: true});

            // if(err.response?.status === 403) 
            // {
            //     navigate('/login', {state : {from: location }, replace: true});
            // }
        }
    }

    const validateProperty = (property) => {

        //Validazione
        if (property.isMandatory) {
            var propValue = "";

            if (property.dataType == "Date") {
                propValue = property.value;
            }
            else {
                propValue = property.value?.trim();
            }

            if (propValue == "" || propValue == null) {
                property.isError = true;
                property.errorMessage = "Il campo non può essere vuoto";
            }
            else {
                property.isError = false;
                property.errorMessage = "";
            }
        }

        return property;
    }

    //Validazione completa dell'oggetto Lavorazione
    const validateWorkCycle = (newWorkCycle) => {

        newWorkCycle.cliente = validateProperty(newWorkCycle.cliente);
        newWorkCycle.data = validateProperty(newWorkCycle.data);
        newWorkCycle.rifDdt = validateProperty(newWorkCycle.rifDdt);
        newWorkCycle.via = validateProperty(newWorkCycle.via);
        newWorkCycle.cap = validateProperty(newWorkCycle.cap);
        newWorkCycle.citta = validateProperty(newWorkCycle.citta);
        newWorkCycle.totale = validateProperty(newWorkCycle.totale);
        newWorkCycle.destinazione = validateProperty(newWorkCycle.destinazione);
        newWorkCycle.peso = validateProperty(newWorkCycle.peso);
        newWorkCycle.note = validateProperty(newWorkCycle.note);


        // newWorkCycle.propertySections.forEach(function(propertySection) {
        //     propertySection.properties.forEach(function(property) {
        //         property = validateProperty(property);
        //     });
        // });      

        return newWorkCycle;
    }

    const checkValidationErrors = () => {

        var output = false;

        if(newWorkCycleViewModel.cliente.isError
        || newWorkCycleViewModel.data.isError
        || newWorkCycleViewModel.rifDdt.isError
        || newWorkCycleViewModel.via.isError
        || newWorkCycleViewModel.cap.isError
        || newWorkCycleViewModel.citta.isError
        || newWorkCycleViewModel.totale.isError
        || newWorkCycleViewModel.destinazione.isError
        || newWorkCycleViewModel.peso.isError
        || newWorkCycleViewModel.note.isError
        )
        {
            output = true;
        }


        // newWorkCycleViewModel.propertySections.forEach(function (propertySection) {
        //     propertySection.properties.forEach(function (property) {
        //         if (property.isError) {
        //             output = true;
        //         }
        //     });
        // });

        //@TODO
        //output = true;

        return output;
    }

    const setPropertyValue = (propertyCode, value, event) => {

        if (propertyCode == "data" || event.target.validity.valid) {
            var wc = { ...newWorkCycleViewModel };

            wc[propertyCode].value = value;
            wc[propertyCode] = validateProperty(wc[propertyCode]);

            setNewWorkCycleViewModel(wc);
        }
    }

    const setRowPropertyValue = (row, propertyCode, value, event) => {

        if(event.target.validity.valid)
        {
            var wc = { ...newWorkCycleViewModel };

            wc.packingListRows.forEach(function(tableRow) {
    
                if(tableRow.id == row.id)
                {  
                    tableRow[propertyCode].value = value;
                }
            });    
            
            wc = setTotals(wc);
    
            setNewWorkCycleViewModel(wc);
        }
    }    

    const setLoadingSave = (isLoading) => {
        if (isLoading) {
            setShowIndicatorProgress('on');
        }
        else {
            setShowIndicatorProgress('off');
        }
    }

    const addBox = () => {
        var wc = { ...newWorkCycleViewModel };

        let newRow = getNewEmptyRow(wc);

        if(selectedRowId >= 0)
        {
            var index = 0;
            var selectedIndex = 0;

            wc.packingListRows.forEach(function(tableRow) {
        
                if(tableRow.id == selectedRowId)
                {  
                    selectedIndex = index;
                }

                index++;
            });

            wc.packingListRows.splice(selectedIndex+1, 0, newRow);

            clearSelection();
        }
        else
        {
            wc.packingListRows.push(newRow);
        }

        //Ricerca della riga selezionata
        setNewWorkCycleViewModel(wc);
    }

    //Rimuove il box selezionato
    const removeBox = () => {

        console.log("selectedRowId",selectedRowId)

        if(selectedRowId >= 0)
        {
            var wc = { ...newWorkCycleViewModel };

            var index = 0;
            var selectedIndex = 0;

            wc.packingListRows.forEach(function(tableRow) {
        
                if(tableRow.id == selectedRowId)
                {  
                    selectedIndex = index;
                }

                index++;
            });

            wc.packingListRows.splice(selectedIndex, 1);

            clearSelection();
        }        
    }

    const clearSelection = (wc) => {
        setClearSelectedRows(!clearSelectedRows);
        setSelectedRowId(-1);
    }

    const getNewEmptyRow = (wc) => {

        let output = JSON.parse(JSON.stringify(wc.emptyPackingListRowViewModel));
        output.id = rowId;
        setRowId(output.id+1);

        return output;
    }

    const setNextRowId = (wc) => {

        var nextRowId = 0;

        wc.packingListRows.forEach(function(tableRow) {
    
            if(tableRow.id >= nextRowId)
            {  
                nextRowId++;
            }
        });

        setRowId(nextRowId);
    }    

    //Setting Page BreadCrumb
    useEffect(() => {

        setPageTitle("Packing List");
        
        var breadcrumb = "Nuovo Packing List";
        
        if(isEditMode())
        {
            breadcrumb = `Modifica Packing List N. ${packingListId}`;
        }

        setPageBreadcrumbs([breadcrumb]);
    }, [])

    //Lettura lista ricette e validazione iniziale per mostrare i campi obbligatori
    useEffect(() => {

        setIsLoading(true);

        let isMounted = true;
        const controller = new AbortController();

        const getNewWorkCycleModel = async () => {

            try {

                var plId;

                //Se si è in modalità di modifica viene letto l'id del packing list interessato
                if(typeof packingListId  === 'undefined')
                {
                    plId = 0;
                }
                else
                {
                    plId = packingListId;
                }

                var t2s = [];

                if(location.state != null)
                {
                    const { selectedT2s } = location.state
                    t2s = selectedT2s;
                }

                var payLoad = {
                    packingListId:plId,
                    t2s:t2s
                }

                const response = await axiosPrivate.post(`/packingList/getNewPackingListModel`, 
                JSON.stringify(payLoad),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,                    
                    signal: controller.signal
                });

                if (isMounted) {

                    response.data.createdBy = auth.userId;

                    if(response.data.data.value != "")
                    {
                        response.data.data.value = new Date(response.data.data.value);
                    }

                    //Validazione iniziale
                    validateWorkCycle(response.data);

                    setNewWorkCycleViewModel(setTotals(response.data));

                    //Impostazione dell'id per l'eventuale prossima riga 
                    setNextRowId(response.data);

                    setIsLoading(false);
                    setIsError(false);
                }
            }
            catch (err) {
                console.error(err);
                setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
                setIsLoading(false);
                setIsError(true);

                // if(err.response?.status === 403) 
                // {
                //     navigate('/login', {state : {from: location }, replace: true});
                // } 
            }
        }

        getNewWorkCycleModel();

    }, [])

    const onSelectedRowsChange = (state) => {

        var selectedRowId = -1;
    
        state.selectedRows.forEach(function (tableRow) {
            selectedRowId = tableRow.id;
        });

        setSelectedRowId(selectedRowId);
      };  

    const handleSubmit = (e) => {

        e.preventDefault();

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if (checkValidationErrors() == true) {
            return;
        }

        setConfirmationModalShow(true);
    }

    return (
        <>
            <div id="kt_content_container" className="mx-5">

                {/* begin::Spinner */}
                {isLoading &&
                    <div className="row d-flex justify-content-center m-20">
                        <Spinner animation="border" variant="primary" />
                    </div>
                }
                {/* end::Spinner */}

                {/* begin::Error message */}
                {!isLoading && isError &&

                    <div className="row d-flex justify-content-center m-20">

                        <div className="alert alert-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

                            <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                    <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                </svg>
                            </span>

                            <div className="text-center">
                                <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
                            </div>
                        </div>
                    </div>

                }
                {/* end::Error message */}

                {!isLoading && !isError &&

                    <form
                        className="form w-100"
                        noValidate="novalidate"
                        id="kt_sign_in_form"
                        onSubmit={handleSubmit}>

                        {/* Sezione comune a tutte le macchine */}

                        <div className='card mb-5 mb-xl-8 mt-5'>

                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>
                                        
                                        {isEditMode() == false ? 
                                        <>Nuovo Packing List - Intestazione</>
                                        :<>Modifica Packing List - Intestazione</>
                                        }
                                        </span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>
                                <div className='row'>

                                    <div className="mb-10 col-12 col-md-6">
                                        <label className="form-label">Cliente</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.cliente.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.cliente.value}
                                            title={newWorkCycleViewModel?.cliente.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('cliente', e.target.value, e)}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-2">
                                        <label className="form-label">Data</label>

                                        <DatePicker
                                            placeholderText="Scegli Data"
                                            locale="it"
                                            className={clsx('form-control form-control-solid', newWorkCycleViewModel?.data.isError ? 'bg-light-danger border-danger' : '')}
                                            autoComplete="off"
                                            name="data"
                                            dateFormat="dd/MM/yyyy"

                                            selected={newWorkCycleViewModel?.data.value}
                                            onChange={(date) => setPropertyValue('data', date, null)}
                                        />

                                    </div>

                                    <div className="mb-10 col-23 col-md-4">
                                        <label className="form-label">Rif. DDT</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.rifDdt.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.rifDdt.value}
                                            title={newWorkCycleViewModel?.rifDdt.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('rifDdt', e.target.value, e)}
                                        />
                                    </div>

                                    <div className="mb-10 col-23 col-md-6">
                                        <label className="form-label">Via</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.via.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.via.value}
                                            title={newWorkCycleViewModel?.via.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('via', e.target.value, e)}
                                        />
                                    </div>


                                    <div className="mb-10 col-12 col-md-2">
                                        <label className="form-label">CAP</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.cap.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.cap.value}
                                            title={newWorkCycleViewModel?.cap.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('cap', e.target.value, e)}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Città</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.citta.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.citta.value}
                                            title={newWorkCycleViewModel?.citta.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('citta', e.target.value, e)}
                                        />
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className='card mb-5 mb-xl-8 mt-5'>

                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Elenco Box</span>
                                </h3>

                                <div className="card-toolbar">

                                    <a className="btn btn-success  m-1"  onClick={(e) => addBox()}>
                                        <span className="svg-icon svg-icon-1">
                                            <i className="fas fa-solid fa-plus"></i>
                                        </span>Aggiungi Box
                                    </a>

                                    <a className="btn btn-danger m-1"  onClick={(e) => removeBox()}>
                                        <span className="svg-icon svg-icon-1">
                                            <i className="fas fa-solid fa-trash"></i>
                                        </span>Rimuovi Box
                                    </a>                                    
                                </div>
                            </div>

                            <div className='card-body py-3'>

                                <div className="row">
                                    <DataTable
                                        columns={columns}
                                        data={newWorkCycleViewModel?.packingListRows}
                                        highlightOnHover

                                        selectableRows
                                        selectableRowsHighlight
                                        selectableRowsSingle
                                        clearSelectedRows = {clearSelectedRows}
                                        onSelectedRowsChange={onSelectedRowsChange}
                                    />
                                </div>
        
                                {/* <MaterialReactTable
                                    columns={materialTableColumns}
                                    data={tableData}
                                    enableRowSelection //enable some features
                                    enableGlobalFilter={false} //turn off a feature
                                    />                                 */}

                            </div>

                        </div>

                        <div className='card mb-5 mb-xl-8 mt-5'>

                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Totali</span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>

                                <DataTable
                                    columns={totalColumns}
                                    data={newWorkCycleViewModel?.packingListTotalRow}
                                    dense
                                    highlightOnHover
                                />

                            </div>

                        </div>


                        <div className='card mb-5 mb-xl-8'>
                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Altro</span>
                                </h3>
                            </div>
                            <div className='card-body py-3'>

                                <div className='row'>

                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Totale</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.totale.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.totale.value}
                                            title={newWorkCycleViewModel?.totale.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('totale', e.target.value, e)}
                                        />
                                    </div>
                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Destinazione</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.destinazione.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.destinazione.value}
                                            title={newWorkCycleViewModel?.destinazione.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('destinazione', e.target.value, e)}
                                        />
                                    </div>
                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Peso</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.peso.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            pattern="[0-9]*([,][0-9]{0,5})?"
                                            value={newWorkCycleViewModel?.peso.value}
                                            title={newWorkCycleViewModel?.peso.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('peso', e.target.value, e)}
                                        />
                                    </div>
                                    <div className="mb-10 col-12 col-md-12">
                                        <label className="form-label">Note</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.note.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.note.value}
                                            title={newWorkCycleViewModel?.note.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('note', e.target.value, e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="text-center">

                            <a className="btn btn-lg btn-primary w-25 m-5" onClick={(e) => annullaSalva()} >
                                Annulla
                            </a>

                            <button
                                type="submit"
                                id="kt_sign_in_submit"
                                className="btn btn-lg btn-primary w-25 m-5"
                                data-kt-indicator={showIndicatorProgress}>
                                <span className="indicator-label">Salva</span>
                                <span className="indicator-progress" >Caricamento...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                            {/* <p ref={errRef} className={errMsg ? "alert alert-danger d-flex align-items-center" : "offscreen"} aria-live="assertive">{errMsg}</p>                                 */}
                        </div>

                    </form>
                }
            </div>

            <Modal show={modalShow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Esito Salvataggio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {saveResult &&
                            <div className="row d-flex justify-content-center">

                                <div className="d-flex flex-center flex-column py-10 px-10 px-lg-20">

                                    <span className="svg-icon svg-icon-2hx svg-icon-success mb-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                            <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                        </svg>
                                    </span>

                                    <div className="text-center">
                                        {/* <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div> */}
                                        <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: modalMessage }}></div>
                                    </div>
                                </div>

                            </div>
                        }

                        {!saveResult &&
                            <div className="row d-flex justify-content-center">

                                <div className="d-flex flex-center flex-column py-10 px-10 px-lg-20">

                                    <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                            <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                        </svg>
                                    </span>

                                    <div className="text-center">
                                        <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                        <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                        <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: modalMessage }}></div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* START - Confirmation Modal */}
            <Modal show={confirmationModalShow} onHide={handleConfirmationClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Conferma Operazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Sei sicuro di voler continuare?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleConfirmationClose}>Annulla</Button>
                    <Button variant="primary" onClick={confirmCreate}>Conferma</Button>
                </Modal.Footer>
            </Modal>

            {/* END - Confirmation Modal */}
        </>

    )
}

export default PackingListCreate