import { useNavigate, Link } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import {Outlet} from 'react-router-dom'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {PageDataProvider} from '../../_metronic/layout/core'
const LayoutInit = () => {    

    return (<>
        <PageDataProvider>
            <Outlet/>
            <MasterInit />
        </PageDataProvider>
    </>

    )
}

export default LayoutInit