import React, {useRef, useEffect, FC} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {AsideMenuMain} from './AsideMenuMain'
import {DrawerComponent, ScrollComponent, ToggleComponent} from '../../../assets/ts/components'

const AsideMenu: FC = () => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const {pathname} = useLocation()

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0
      }
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <div
      id='#kt_aside_menu'
      data-kt-menu='true'
      className='aside-menu flex-column-fluid'
    >
      {/* begin::Aside Menu */}
      <div 
        className="hover-scroll-overlay-y my-2 my-lg-5 pe-lg-n1" 
        id="kt_aside_menu_wrapper" 
        data-kt-scroll="true" 
        data-kt-scroll-height="auto" 
        data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" 
        data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu" 
        data-kt-scroll-offset="5px">

        {/* begin::Menu */}
        <div className="menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold" 
          id="#kt_aside_menu" 
          data-kt-menu="true">
          <AsideMenuMain />
        </div>
        {/* end::Menu */}

      </div>

      {/* end::Aside Menu */}

    </div>
  )
}

export {AsideMenu}
