/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {AsideMenu} from './AsideMenu'

const AsideDefault: FC = () => {
  const {config, classes} = useLayout()
  const {aside} = config

  return (
    <div
      id='kt_aside'
      className='aside pb-5 pt-5 pt-lg-0'
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'80px', '300px': '100px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >

      {/* begin::Brand */}
      <div className='aside-logo py-8' id='kt_aside_logo'>

        {/* begin::Logo */}
        <Link to='/' className='d-flex align-items-center'>
            <img
              alt='Logo'
              className='h-55px logo'
            //   src={toAbsoluteUrl('/media/logos/logo-demo6.svg')}
              src={toAbsoluteUrl('/media/logos/logo-login.png')}
            />
        </Link>    
        {/* end::Logo */}

      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu/>
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
       <div className='aside-footer flex-column-auto' id='kt_aside_footer'>
        <div className="d-flex justify-content-center">
            <span className="text-white">Versione 1.7.5</span>

          {/* <button type="button" className="btn btm-sm btn-icon btn-active-color-primary">
          </button>           */}
{/*

				  <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
					  <div className="menu-item px-3">
						  <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
					  </div>
					  <div className="separator mb-3 opacity-75"></div>
					  <div className="menu-item px-3">
						  <a className="menu-link px-3">New Ticket</a>
					  </div>
					  <div className="menu-item px-3">
						  <a className="menu-link px-3">New Customer</a>
					  </div>
					  <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
						  <a className="menu-link px-3">
							  <span className="menu-title">New Group</span>
							  <span className="menu-arrow"></span>
						  </a>
						  <div className="menu-sub menu-sub-dropdown w-175px py-4">
							  <div className="menu-item px-3">
								  <a className="menu-link px-3">Admin Group</a>
							  </div>
							  <div className="menu-item px-3">
								  <a className="menu-link px-3">Staff Group</a>
							  </div>
							  <div className="menu-item px-3">
								  <a className="menu-link px-3">Member Group</a>
							  </div>
						  </div>
					  </div>
					  <div className="menu-item px-3">
						  <a className="menu-link px-3">New Contact</a>
					  </div>
					  <div className="separator mt-3 opacity-75"></div>
					  <div className="menu-item px-3">
						  <div className="menu-content px-3 py-3">
							  <a className="btn btn-primary btn-sm px-4" >Generate Reports</a>
						  </div>
					  </div>
				  </div>
*/}
          
        </div>
      </div> 
      {/* end::Footer */}
    </div>
  )
}

export {AsideDefault}
