import {Outlet} from "react-router-dom";
import {useState, useEffect} from "react";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAuth from "../../hooks/useAuth";
import { Spinner } from 'react-bootstrap';

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const {auth, persist} = useAuth();

    useEffect(()=> {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch(err)
            {
                console.error(err);
            }
            finally{
                isMounted && setIsLoading(false);
            }
        }

        //Refactored
        //!auth?.accessToken ? verifyRefreshToken(): setIsLoading(false);

        if(!auth?.accessToken)
        {
            verifyRefreshToken();
        }
        else
        {
            setIsLoading(false);
        }

        return () => isMounted = false;
    }, [])

    useEffect(() => {
        //console.log(`isLoading: ${isLoading}`)
        //console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)

    },[isLoading])
    
    return (
        <>
            {!persist 
                ? <Outlet/>
                : isLoading
                    ? <>
                    <div className="d-flex flex-row-fluid flex-center">
                        <div className="row">
                            <div className="col-12 text-center">
                                <Spinner animation="border" variant="primary" />
                            </div>
                            <div className="col-12 text-center">
                                <br></br>
                            <span className="text-dark">Caricamento in corso</span>

                            </div>
                            
                        </div>
                    </div>
                </>
                    : <Outlet/>
            }
        </>
    )
}

export default PersistLogin