import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { KTSVG } from '../../../_metronic/helpers'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { usePageData } from '../../../_metronic/layout/core';
import { Spinner, Modal, Button} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DatePicker, {registerLocale} from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it"; // the locale you want
import FileSaver from "file-saver";
import useCache from '../../../hooks/useCache';
import clsx from 'clsx'
import {ProductionOrderCard, ProductionOrderMobilePagination} from "./ProdOrderComponents";
import BarcodeScannerComponent from "react-qr-barcode-scanner-17";

registerLocale("it", it); // register it with the name you want

const ProdOrderList = () => {
  const [workCycles, setWorkCycles] = useState();
  //const [modalShow, setModalShow] = useState();  
  //const [modalMessage, setModalMessage] = useState();  

  const [isError, setIsError] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  let { unitId } = useParams();

  const { setPageTitle, pageMachines } = usePageData();
  const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();
  const [unit, setUnit] = useState();
  const [selectedMachineId, setSelectedMachineId] = useState(0);

  const [modalFilterShow, setModalFilterShow] = useState(false);  
  const [modalNewJobShow, setModalNewJobShow] = useState(false);  
  
  const [tempFilter, setTempFilter]= useState();
  const [showExportProgress, setShowExportProgress] = useState('off');

  const [isScanning, setIsScanning] = useState(false);

  //---START Gestione Filtri---//
  const { filters } = useCache();
  const [filter, setFilter] = useState({});
  let emptyFilter = {
    id:'',
    dataCreazioneInizio:'',
    dataCreazioneFine:'',
    dataInizio:'',
    dataFine:'',
    stato:'',
    numeroOrdine:'',
    cliente:'',
    codiceArticolo:'',
    coloreArticolo:'',
    numeroLotto:'',
    tipoOrdine:'',
    idMacchina:'0',
    sortField:'DataOrdine',
    sortDirection:'desc'
  };   
 //---END Gestione Filtri---//

  //Pagination Handling
  const [perPage, setPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  //Sorting handling
  const [defaultSortAsc, setDefaultSortAsc] = useState(false);
  const [defaultSortFieldId, setDefaultSortFieldId] = useState(2);  

  const paginationComponentOptions = {
    rowsPerPageText: 'Elementi per pagina',
    rangeSeparatorText: 'di',
    selectAllRowsItem: false
  };

  const columns = [
    // {
    //   name: 'Id',
    //   selector: row => row.id,
    //   width: '100px',
    //   sortable: true,
    //   sortField:'Id',
    //   sortFieldId:1,
    //   cell: (row, index, column, id) => {
    //     return getProdViewLink(row, row?.id);
    //   }
    // },
    {
      name: 'Numero',
      maxWidth: '150px',
      selector: row => row.orderNumber,
      sortFieldId:1,
      sortable: false,
      cell: (row, index, column, id) => {
        return getProdViewLink(row, row?.orderNumber);        
      }
    },
    {
      name: 'Data Creazione',
      maxWidth: '150px',
      selector: row => row.dataOrdineStr,
      sortable: true,
      sortField:'DataOrdine',  
      sortFieldId:2,
      cell: (row, index, column, id) => {
        return getProdViewLink(row, row?.dataOrdineStr);        
      }
    },
    {
      name: 'Cliente',
      selector: row => row.customer,
      sortable: true,
      sortField:'Customer',
      sortFieldId:3,
      sortable: false,
      cell: (row, index, column, id) => {
        return getProdViewLink(row, row?.customer);        
      }
    },  
    {
      name: 'Articolo',
      selector: row => row.article,
      sortable: false,
      sortField:'Article',
      sortFieldId:4,
      cell: (row, index, column, id) => {
        return getProdViewLink(row, row?.article);        
      }
    }, 
    {
      name: 'Colore',
      selector: row => row.color,
      sortable: false,
      sortField:'Color',
      sortFieldId:5,
      cell: (row, index, column, id) => {
        return getProdViewLink(row, row?.color);        
      }
    },    
    {
      name: 'Num. Pelli',
      maxWidth: '150px',
      selector: row => row.leathersNumber,
      sortFieldId:6,
      sortable:false,
      cell: (row, index, column, id) => {
        return getProdViewLink(row, row?.leathersNumber);        
      }
    },
    {
      name: 'Ultima Lavorazione',
      maxWidth: '200px',
      selector: row => row.lastJob?.machineName,
      sortFieldId:7,
      sortable:false,
      cell: (row, index, column, id) => {
        return getProdViewLink(row, row.lastJob?.machineName);        
      }
    },    
    {
      name: 'Consegna',
      maxWidth: '150px',
      selector: row => row.dataConsegnaStr,
      sortable: true,
      sortField:'DataConsegna',  
      sortFieldId:8,
      cell: (row, index, column, id) => {
        return getProdViewLink(row, row?.dataConsegnaStr);        
      }
    },
    {
      name: 'Stato',
      selector: row => row.status,
      maxWidth: '180px',
      sortable: false,
      sortField:'status',  
      sortFieldId:9,
      cell: (row, index, column, id) => {
        //return getProdViewLink(row, row?.startDateStr ? row?.startDateStr : '-');   
        var bgClass = '';
        var statusName = '';

        if (row.status == 0) // ND
        {
          bgClass = 'bg-secondary  text-dark';
          statusName = 'Non Definito';
        }
        else if (row.status == 1) // TODO
        {
          bgClass = 'bg-primary';
          statusName = 'Da Fare';
        }
        else if (row.status == 2) // INPROGRESS
        {
          bgClass = 'bg-warning text-dark';
          statusName = 'In Produzione';
        }
        else if (row.status == 3) // DONE
        {
          bgClass = 'bg-success';
          statusName = 'Completato';
        }

        return (<span className={clsx(`badge ${bgClass}`)}>{statusName}</span>);
      }
    },
    {
      name: 'Azioni',
      maxWidth: '100px',
      right: true,
      ignoreRowClick: true,
      cell: (row, index, column, id) => {
        return (
          <div className='row'>

            <Link to={`/productionOrders/view/${row?.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <i className="fas fa-eye"></i>
            </Link>

            <Link to={`/productionOrders/edit/${row?.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <i className="fas fa-pencil-alt"></i>
            </Link>
          </div>)
      }
    }
  ];

//Setting Page BreadCrumb
useEffect(()=>{ 

  setPageTitle('Cartellini');
  setPageBreadcrumbs(['']);

},[])

  useEffect(() => {

    //Piuttosto che prendere di default al caricamento della pagina il filtro vuoto, 
    //Bisognerebbe controllare se esiste già un filtro. In caso positivo prendere quello.
    //Altrimenti, impostare il filtro vuoto in cache per la pagina corrente e usarlo.
    //Poi mantenerlo aggiornato se l'utente applica filtri
    //const [filter, setFilter] = useState(emptyFilter);
  
    let initFilter = {};
  
    //Se il filtro non esiste in cache, allora viene impostato quello vuoto da utilizzare e salvato in cache per la pagina corrente
    //Altrimenti viene preso il filtro in cache
    if((typeof filters[location.pathname] === 'undefined'))
    {
      initFilter = emptyFilter;
    }
    else
    {
      initFilter = filters[location.pathname];
    }
  
    setTableFiltering(initFilter);

    getWorkCycleList(1, perPage, initFilter);

  }, [])

  const getProdViewLink = (row, text) => {
    let rowId = row?.id;
    let machineId = row?.machineId;
    let machineCode = row?.machineCode;

    return <Link to={`/productionOrders/view/${rowId}`} className='text-dark text-hover-primary fs-6'>{text}</Link>;    
  }

  //Dato che il filtro in cache è stato introdotto successivamente, viene fatta una doppia operazione
  //Sia sul filtro in cache che in quello locale. Quindi è stato raggruppato nello stesso medoto
  const setTableFiltering = (inputFilter) =>
  {
    filters[location.pathname] = inputFilter;
    setFilter(inputFilter);
  }

  const handleSort = async (column, sortDirection) => {

    if(sortDirection == "asc" || sortDirection == "")
    {
      setDefaultSortAsc(true);
    }
    else
    {
      setDefaultSortAsc(false);
    }

    setDefaultSortFieldId(column.sortFieldId);

    let sortedFilter = {...filter, sortField:column.sortField, sortDirection, sortDirection};
    //setFilter(sortedFilter);
    setTableFiltering(sortedFilter);

    getWorkCycleList(1, perPage, sortedFilter);
  };


  const handleNewJobClose = () => {
    setSelectedMachineId(0);
   setModalNewJobShow(false);
  }


  const showFilterModal = () => {

    //Salvataggio in memoria del filtro attuale. 
    //Questo viene fatto perchè se non viene applicato o resettato, il filtro viene reimpostato
    //A quello al momento dell'apertura della finestra

    setTempFilter(filter);

    setModalFilterShow(true);
   }  

   const showNewJobModal = () => {

    setModalNewJobShow(true); 
   }  
   

   const handleFilterClose = () => {
    
    //Se la finestra viene chiusa senza ne applicare ne resettare il filtro, allora i filtri impostati dal momento dell'apertura 
    //della finestra del filtro vengono persi e reimpostati quelli precedentemente applicati
    setFilter(tempFilter);
 
    setModalFilterShow(false);
  }   

   const resetFilter = () => {

    setTableFiltering(emptyFilter);

    getWorkCycleList(1, perPage, emptyFilter);

    setModalFilterShow(false);
   }       

   const applyFilter = () => {

    setTableFiltering(filter);

    getWorkCycleList(1, perPage, filter);
    setModalFilterShow(false);
  }  

  const goToNextPage = () => {

    var nextPage = currentPage + 1;
    getWorkCycleList(nextPage, perPage, filter);

    setCurrentPage(nextPage);
  }  

  const goToPrevPage = () => {

    var prevPage = currentPage - 1;
    getWorkCycleList(prevPage, perPage, filter);

    setCurrentPage(prevPage);
  }    

   const setFilterProperty = (attribute, value, event) => {

    if (attribute == "id") 
      {
        if(event.target.validity.valid)
        {
          setFilter({...filter, id:value});
        }
      }
      else
      {
        var tmpFilter = { ...filter };
        tmpFilter[attribute] = value;

        setFilter(tmpFilter);
      }
   }

   const getWorkCycleListRequest = (page, pageSize, pageFilter)=> {

    let workCycleListRequest =
    {
      unitId: unitId,
      idMacchina: pageFilter.idMacchina,
      page: page,
      pageSize: pageSize,
      filterId: pageFilter.id,
      filterOrderNumber: pageFilter.numeroOrdine,
      filterArticle: pageFilter.codiceArticolo,
      filterColor: pageFilter.coloreArticolo,
      filterCustomer: pageFilter.cliente,
      filterBatch: pageFilter.numeroLotto,
      filterOrderType: pageFilter.tipoOrdine,
      filterCreationStartDate: pageFilter.dataCreazioneInizio,
      filterCreationEndDate: pageFilter.dataCreazioneFine,
      filterStartDate: pageFilter.dataInizio,
      filterEndDate: pageFilter.dataFine,
      filterStatus: pageFilter.stato,
      sortField: pageFilter.sortField,
      sortDirection: pageFilter.sortDirection
    }

    return workCycleListRequest;
   }

  const getWorkCycleList = (page, pageSize, pageFilter) => {

    //console.log(pageFilter);

    setIsLoading(true);

    let isMounted = true;
    const controller = new AbortController();

    let workCycleListRequest = getWorkCycleListRequest(page, pageSize, pageFilter);

    const fetchWorkCycleList = async () => {

      try 
      {
        const response = await axiosPrivate.post(`/production/getProductionOrders`,  
        JSON.stringify(workCycleListRequest), 
        {
            headers: {'Content-Type':'application/json'},
            withCredentials: true,
            signal:controller.signal
        });

        //console.log(response.data);
        if (isMounted) {
          setWorkCycles(response.data.data);
          setTotalRows(response.data.total);
          setIsLoading(false);
          setIsError(false);
        }

      }
      catch (err) {
        console.error(err);
        setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
        setIsLoading(false);
        setIsError(true);

        // if(err.response?.status === 403) 
        // {
        //     navigate('/login', {state : {from: location }, replace: true});
        // }
      }
    }

    fetchWorkCycleList();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }

  const goToNewJobPage = () => 
  {
    if(selectedMachineId > 0)
    {
      navigate(`/jobs/${selectedMachineId}/createJob`);
    }
}

const downloadReport = () => {

  if(showExportProgress == 'on')
  {
    return;
  }

  setShowExportProgress('on');

  let isMounted = true;
  const controller = new AbortController();

  let workCycleListRequest = getWorkCycleListRequest(0, perPage, filter);

  const fetchReport = () => {

     axiosPrivate.post(`/production/export`,  
      JSON.stringify(workCycleListRequest), 
      {
          headers: {'Content-Type':'application/json'},
          withCredentials: true,
          signal:controller.signal
      })
      .then(res => {
        // let extension = 'zip';
        // let tempFileName = `${this.state['selectedCommunity']}`
        // let fileName = `${tempFileName}.${extension}`;
        let fileName = `Export.csv`;

        const blob = new Blob([res.data], {
            type: 'application/octet-stream'
        })

        FileSaver.saveAs(blob, fileName);
        setShowExportProgress('off');
      })
      .catch(err => {
          console.log(err.message);
          setShowExportProgress('off');
          alert(err?.response?.data?.message);
      });
  }

  fetchReport();

  return () => {
    isMounted = false;
    controller.abort();
  }

 }


  const handlePageChange = page => {
		getWorkCycleList(page, perPage, filter);
    setCurrentPage(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {

    getWorkCycleList(page, newPerPage, filter);
    setPerPage(newPerPage);
	};  

  const onCodeScanned = (code) =>
    {
        var tmpFilter = {...filter, numeroOrdine:code}
        setFilter(tmpFilter);
  
        setTableFiltering(tmpFilter);
  
        getWorkCycleList(1, perPage, tmpFilter);
  
        setIsScanning(false);
    }
  
    const isFilterActive = () => {
  
      var output = false;
      
      if((typeof filters[location.pathname] === 'undefined'))
      {    
        output = false;
      }
      else 
      {
        var currentFilter = filters[location.pathname];
  
        if(currentFilter.id == "" 
        && currentFilter.stato == "" 
        && currentFilter.numeroOrdine == "" 
        && currentFilter.cliente == "" 
        && currentFilter.codiceArticolo == "" 
        && currentFilter.coloreArticolo == "" 
        && currentFilter.dataInizio == "" 
        && currentFilter.dataFine == "" 
        && currentFilter.dataCreazioneInizio == "" 
        && currentFilter.dataCreazioneFine == "" 
        && currentFilter.numeroLotto == "" 
        && currentFilter.tipoOrdine == "" 
        )
        {
          output = false;
        }
        else
        {
          output = true;
        }
      }
  
      return output;
    }
    

  return (
    <div id="kt_content_container" className="mx-5">

      {/* begin::Spinner */}
      {isLoading &&
        <div className="row d-flex justify-content-center m-20">
          <Spinner animation="border" variant="primary" />
        </div>
      }
      {/* end::Spinner */}

      {/* begin::Error message */}
      {!isLoading && isError &&

        <div className="row d-flex justify-content-center m-20">

          <div className="alert alert-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

            <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
              </svg>
            </span>

            <div className="text-center">
              <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
              <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
              <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
            </div>
          </div>

        </div>

      }
      {/* end::Error message */}

      {/* begin::Table */}
      {!isLoading && !isError &&
        <>
        <div className="row d-none d-md-block">
          <div className='card mb-5 mt-5 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Elenco Cartellini</span>
              </h3>
              <div className="card-toolbar">

                <div className='btn btn-primary me-2 mt-1' onClick={(e) => showFilterModal()}>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-search"></i>
                  </span>
                  Filtro
                </div>

                <div className='btn btn-secondary me-2 mt-1' onClick={(e) => getWorkCycleList(1, perPage, filter)}>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-sync"></i>
                  </span>
                  Aggiorna
                </div>

                <div
                  className='btn btn-secondary me-2 mt-1'
                  onClick={(e) => downloadReport()}
                  data-kt-indicator={showExportProgress}
                >

                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-download"></i>
                  </span>

                  <span className="indicator-label" >Esporta</span>
                  <span className="indicator-progress" > Esporta
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </div>

                <Link to={`/productionOrders/create/0`} className='btn btn-success mt-1'>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-solid fa-plus"></i>
                  </span>
                  Nuovo Cartellino
                </Link>

              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>

              <div className='row mb-2'>
                <DataTable
                  columns={columns}
                  data={workCycles}
                  pagination
                  paginationServer

                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationDefaultPage={currentPage}
                  paginationPerPage={perPage}
                  paginationComponentOptions={paginationComponentOptions}
                  paginationRowsPerPageOptions={[10, 25, 50, 100]}

                  onSort={handleSort}
                  sortServer
                  defaultSortFieldId={defaultSortFieldId}
                  defaultSortAsc={defaultSortAsc}

                  highlightOnHover
                  striped

                />
              </div>
            </div>
          </div>
        </div>

        <div className="row d-block d-md-none" >

          <div className="col-12">
            <div className='card mb-5 mt-5 mb-xl-8'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Elenco Cartellini</span>
                </h3>
                <div className="card-toolbar">

                  {/* <div className='btn btn-primary me-2 mt-1' onClick={(e) => showFilterModal()}>
                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-search"></i>
                    </span>
                    Filtro
                  </div> */}

                  {isScanning == false && <>
                    <div className='btn btn-primary me-2 mt-1' onClick={(e) => showFilterModal()}>
                      <span className="svg-icon svg-icon-1">
                        <i className={clsx('fas fa-search', isFilterActive() ? 'text-warning' : '')}></i>
                      </span>
                      <span className={clsx(isFilterActive() ? 'text-warning' : '')}>Filtro</span>
                    </div>
                  </>}

                  <div className="btn btn-secondary mt-1" onClick={(e) => setIsScanning(!isScanning)}>
                    <i className="fas fa-camera p-0"></i>
                  </div>

                </div>
              </div>

            </div>

            {isScanning &&
              <div className="col-12">
                {/* <MobileView> */}
                <div className="mb-10 col-12 col-md-2">
                  <BarcodeScannerComponent
                    width="100%"
                    onUpdate={(err, result) => {
                      if (result) {
                        onCodeScanned(result.text);
                      }
                      else {
                        //setScannedCode("Not Found");
                      }
                    }}
                  />
                </div>
                {/* </MobileView> */}
              </div>
            }

          </div>

          {workCycles?.map((wc, i) =>
            <>
              <div className="col-12">
                <ProductionOrderCard productionOrder={wc} />
              </div>
            </>)
          }

          <ProductionOrderMobilePagination
            currentPage={currentPage}
            perPage={perPage}
            totalRows={totalRows}
            onGoToPrevPage={goToPrevPage}
            onGoToNextPage={goToNextPage} />

        </div>        
        </>
      }

      <Modal show={modalFilterShow} onHide={handleFilterClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filtri di Ricerca</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">

            <div className="row">
              <div className="mb-10 col-6">
                <label className="form-label">ID</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="ID"
                  pattern="[0-9]*"
                  value={filter?.id} 
                  onChange={(e) => setFilterProperty('id', e.target.value, e)}
                  />
              </div>

              <div className="mb-10 col-6">
                <label className="form-label">Stato</label>
                  <select
                    className='form-select' 
                    value={filter?.stato}
                    onChange={(e) => setFilterProperty('stato', e.target.value, e)}>
                        <option value='0'>Tutti</option>
                        <option value='1'>Da Fare</option>
                        <option value='2'>In Produzione</option>
                        <option value='3'>Completato</option>
                    </select>
              </div>

            </div>
                 
            {/* <div className="row">
              <div className="mb-10 col-6">
                <label className="form-label">Da</label>
                <DatePicker
                  placeholderText="Scegli Data"
                  locale="it"
                  className='form-control form-control-solid'
                  name="startDate"
                  dateFormat="dd/MM/yyyy"
                  selected={filter?.dataInizio} 
                  onChange={(date) => setFilterProperty('dataInizio', date, null)}                  
                />
              </div>

              <div className="mb-10 col-6">
                <label className="form-label">A</label>
                <DatePicker
                  placeholderText="Scegli Data"
                  locale="it"
                  className='form-control form-control-solid'
                  name="endDate"
                  dateFormat="dd/MM/yyyy"
                  selected={filter?.dataFine} 
                  onChange={(date) => setFilterProperty('dataFine', date, null)}                  
                />
              </div>               
            </div> */}

            <div className="row">
              <div className="mb-10 col-6">

                <label className="form-label">Numero</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="Cartellino"
                  value={filter?.numeroOrdine}
                  onChange={(e) => setFilterProperty('numeroOrdine', e.target.value, e)}
                />
              </div>

              <div className="mb-10 col-6">

                <label className="form-label">Cliente</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="Cliente"
                  value={filter?.cliente}
                  onChange={(e) => setFilterProperty('cliente', e.target.value, e)}
                />
              </div>

            </div>

            <div className="row">
              <div className="mb-10 col-6">

                <label className="form-label">Articolo</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="Articolo"
                  value={filter?.codiceArticolo}
                  onChange={(e) => setFilterProperty('codiceArticolo', e.target.value, e)}
                />
              </div>

              <div className="mb-10 col-6">

                <label className="form-label">Colore</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="Colore"
                  value={filter?.coloreArticolo}
                  onChange={(e) => setFilterProperty('coloreArticolo', e.target.value, e)}
                />
              </div>

              <div className="mb-10 col-6">
                <label className="form-label">Data Creazione - Da</label>
                <DatePicker
                  placeholderText="Scegli Data"
                  locale="it"
                  className='form-control form-control-solid'
                  name="dataCreazioneInizio"
                  dateFormat="dd/MM/yyyy"
                  autoComplete="off"
                  selected={filter?.dataCreazioneInizio} 
                  onChange={(date) => setFilterProperty('dataCreazioneInizio', date, null)}                  
                />
              </div>

              <div className="mb-10 col-6">
                <label className="form-label">Data Creazione - A</label>
                <DatePicker
                  placeholderText="Scegli Data"
                  locale="it"
                  className='form-control form-control-solid'
                  name="dataCreazioneFine"
                  dateFormat="dd/MM/yyyy"
                  autoComplete="off"
                  selected={filter?.dataCreazioneFine} 
                  onChange={(date) => setFilterProperty('dataCreazioneFine', date, null)}                  
                />
              </div>

              <div className="mb-10 col-6">
                <label className="form-label">Consegna - Da</label>
                <DatePicker
                  placeholderText="Scegli Data"
                  locale="it"
                  className='form-control form-control-solid'
                  name="startDate"
                  dateFormat="dd/MM/yyyy"
                  autoComplete="off"
                  selected={filter?.dataInizio} 
                  onChange={(date) => setFilterProperty('dataInizio', date, null)}                  
                />
              </div>

              <div className="mb-10 col-6">
                <label className="form-label">Consegna - A</label>
                <DatePicker
                  placeholderText="Scegli Data"
                  locale="it"
                  className='form-control form-control-solid'
                  name="endDate"
                  dateFormat="dd/MM/yyyy"
                  autoComplete="off"
                  selected={filter?.dataFine} 
                  onChange={(date) => setFilterProperty('dataFine', date, null)}                  
                />
              </div>      

              <div className="mb-10 col-12 col-md-6">

                <label className="form-label">Numero Lotto Fornitore</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="Numero Lotto Fornitore"
                  value={filter?.numeroLotto}
                  onChange={(e) => setFilterProperty('numeroLotto', e.target.value, e)}
                />
              </div>

              <div className="mb-10 col-12 col-md-6">

                <label className="form-label">Tipo Ordine</label>
                <select
                    className='form-select' 
                    value={filter?.tipoOrdine}
                    onChange={(e) => setFilterProperty('tipoOrdine', e.target.value, e)}>
                        <option value=''>Tutti</option>
                        <option value='Campionario'>Campionario</option>
                        <option value='Produzione'>Produzione</option>
                        <option value='Altro'>Altro</option>
                    </select>
              </div>              

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetFilter}>
            Reset
          </Button> 
          <Button variant="primary" onClick={applyFilter}>
            Applica
          </Button>         
        </Modal.Footer>
      </Modal>
     
      <Modal show={modalNewJobShow} onHide={handleNewJobClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nuova lavorazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">

            <div className="row">

              <div className="mb-10 col-12">
                <label className="form-label">Macchina</label>
                  <select
                    className='form-select' 
                    value={selectedMachineId}
                    onChange={(e) => setSelectedMachineId(e.target.value)}>
                        <option value='0'>Scegli</option>
                        <option value='15'>Lavatrice Unimatik</option>
                    </select>
              </div>

            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={goToNewJobPage}>
            Continua
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default ProdOrderList