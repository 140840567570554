import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {AuthProvider} from './context/AuthProvider';
import {CacheProvider} from './context/CacheProvider';
import ScrollToTop from './components/utility/ScrollToTop';


//import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/css/style.bundle-demo6.css';


import LayoutInit from './components/layout/LayoutInit';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <CacheProvider>
          <ScrollToTop>
            <Routes>
              <Route element={<LayoutInit />}>
                <Route path="/*" element={<App />}>
                </Route>
              </Route>
            </Routes>
          </ScrollToTop>
        </CacheProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();