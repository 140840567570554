import { useNavigate, Link, useLocation, Outlet, useParams } from "react-router-dom";
import { useState, useEffect, useMemo } from 'react';
import { KTSVG } from '../../../_metronic/helpers'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { usePageData } from '../../../_metronic/layout/core';
import useAuth from "../../../hooks/useAuth";
import DatePicker, { registerLocale } from 'react-datepicker';
import DataTable from 'react-data-table-component';
import clsx from 'clsx'

const CustomerCreate = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [isError, setIsError] = useState();
    const [isLoading, setIsLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [showIndicatorProgress, setShowIndicatorProgress] = useState('');
    const [modalShow, setModalShow] = useState();
    const [modalMessage, setModalMessage] = useState();
    const [saveInProgress, setSaveInProgress] = useState();
    const [saveResult, setSaveResult] = useState();
    const [newWorkCycleViewModel, setNewWorkCycleViewModel] = useState();
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);

    let { customerId } = useParams();
    let userType = "CUSTOMER";

    const { setPageTitle, pageMachines } = usePageData();
    const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();

    const isEditMode = () => {

        var output = false;

        if(typeof customerId  === 'undefined')
        {
            output = false;
        }   
        else
        {
            output = true;
        }

        return output;
    }

    const annullaSalva = () => {
        if (saveInProgress == true) 
        {
            return;
        }
        else 
        {
            //Ritorna alla pagina precedente
            navigate(-1);
        }
    }

    const handleClose = () => {
        if (saveResult == true) {
            navigate(`/profiles/customer/view/${newWorkCycleViewModel.id}`);
        }
        else {
            setModalShow(false);
        }
    }

    const handleConfirmationClose = () => {
        setConfirmationModalShow(false);
    }

    const confirmCreate = async () => {
        setConfirmationModalShow(false);

        //Se c'è un salvataggio in corso non possono essere inoltrare ulteriori richieste
        if (saveInProgress) {
            return;
        }

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if (checkValidationErrors() == true) {
            return;
        }

        setLoadingSave(true);
        setSaveInProgress(true);

        //Impostazione dell'id macchina per cui creare il ciclo di lavoro

        var endpoint = "";

        if(isEditMode())
        {
            endpoint = `/customers/edit`;
        }
        else
        {
            endpoint = `/customers/create`;
        }

        try {
            const response = await axiosPrivate.post(endpoint,
                JSON.stringify(newWorkCycleViewModel),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );

            setNewWorkCycleViewModel({ ...newWorkCycleViewModel, id: response.data });

            setModalMessage("Operazione avvenuta con successo");
            setLoadingSave(false);
            setSaveInProgress(false);
            setSaveResult(true);
            setModalShow(true);
        }
        catch (err) {
            console.error(err);
            setSaveResult(false);
            setModalMessage(`${err?.response?.data?.message}`);
            setLoadingSave(false);
            setSaveInProgress(false);
            setModalShow(true);

            //navigate('/login', {state : {from: location }, replace: true});

            // if(err.response?.status === 403) 
            // {
            //     navigate('/login', {state : {from: location }, replace: true});
            // }
        }
    }

    const validateProperty = (property) => {

        //Validazione
        if (property.isMandatory) {
            var propValue = "";

            if (property.dataType == "Date") {
                propValue = property.value;
            }
            else {
                propValue = property.value?.trim();
            }

            if (propValue == "" || propValue == null) {
                property.isError = true;
                property.errorMessage = "Il campo non può essere vuoto";
            }
            else {
                property.isError = false;
                property.errorMessage = "";
            }
        }

        return property;
    }

    //Validazione completa dell'oggetto Lavorazione
    const validateWorkCycle = (newWorkCycle) => {

        newWorkCycle.codice = validateProperty(newWorkCycle.codice);
        newWorkCycle.ragioneSociale = validateProperty(newWorkCycle.ragioneSociale);
        newWorkCycle.indirizzo = validateProperty(newWorkCycle.indirizzo);
        newWorkCycle.cap = validateProperty(newWorkCycle.cap);
        newWorkCycle.citta = validateProperty(newWorkCycle.citta);

        return newWorkCycle;
    }

    const checkValidationErrors = () => {

        var output = false;

        if(
            newWorkCycleViewModel.codice.isError
        || newWorkCycleViewModel.ragioneSociale.isError
        || newWorkCycleViewModel.indirizzo.isError
        || newWorkCycleViewModel.cap.isError
        || newWorkCycleViewModel.citta.isError
        )
        {
            output = true;
        }

        return output;
    }

    const setPropertyValue = (propertyCode, value, event) => {

        if (propertyCode == "data" || event.target.validity.valid) {
            var wc = { ...newWorkCycleViewModel };

            wc[propertyCode].value = value;
            wc[propertyCode] = validateProperty(wc[propertyCode]);

            setNewWorkCycleViewModel(wc);
        }
    }

    const setLoadingSave = (isLoading) => {
        if (isLoading) {
            setShowIndicatorProgress('on');
        }
        else {
            setShowIndicatorProgress('off');
        }
    }


    //Setting Page BreadCrumb
    useEffect(() => {

        setPageTitle("Anagrafiche");
        
        var breadcrumb = "Nuovo Cliente";
        
        if(isEditMode())
        {
            breadcrumb = `Modifica Cliente N. ${customerId}`;
        }

        setPageBreadcrumbs(["Clienti",breadcrumb]);
    }, [])

    //Lettura lista ricette e validazione iniziale per mostrare i campi obbligatori
    useEffect(() => {

        setIsLoading(true);

        let isMounted = true;
        const controller = new AbortController();

        const getNewWorkCycleModel = async () => {

            try {

                var plId;

                //Se si è in modalità di modifica viene letto l'id del packing list interessato
                if(typeof customerId  === 'undefined')
                {
                    plId = 0;
                }
                else
                {
                    plId = customerId;
                }

                var t2s = [];

                if(location.state != null)
                {
                    const { selectedT2s } = location.state
                    t2s = selectedT2s;
                }

                var payLoad = {
                    packingListId:plId,
                    t2s:t2s
                }

                const response = await axiosPrivate.get(`/customers/getNewCustomerModel/${plId}`, 
                {
                    signal: controller.signal
                });

                if (isMounted) {

                    //response.data.createdBy = auth.userId;

                    //Validazione iniziale
                    response.data.userType = userType;
                    validateWorkCycle(response.data);

                    setNewWorkCycleViewModel(response.data);

                    setIsLoading(false);
                    setIsError(false);
                }
            }
            catch (err) {
                console.error(err);
                setErrorMessage(`${err?.response?.data?.message}`);
                setIsLoading(false);
                setIsError(true);

                // if(err.response?.status === 403) 
                // {
                //     navigate('/login', {state : {from: location }, replace: true});
                // } 
            }
        }

        getNewWorkCycleModel();

    }, [])

   

    const handleSubmit = (e) => {

        e.preventDefault();

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if (checkValidationErrors() == true) {
            return;
        }

        setConfirmationModalShow(true);
    }

    return (
        <>
            <div id="kt_content_container" className="mx-5">

                {/* begin::Spinner */}
                {isLoading &&
                    <div className="row d-flex justify-content-center m-20">
                        <Spinner animation="border" variant="primary" />
                    </div>
                }
                {/* end::Spinner */}

                {/* begin::Error message */}
                {!isLoading && isError &&

                    <div className="row d-flex justify-content-center m-20">

                        <div className="alert alert-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

                            <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                    <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                </svg>
                            </span>

                            <div className="text-center">
                                <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
                            </div>
                        </div>
                    </div>

                }
                {/* end::Error message */}

                {!isLoading && !isError &&

                    <form
                        className="form w-100"
                        noValidate="novalidate"
                        id="kt_sign_in_form"
                        onSubmit={handleSubmit}>

                        {/* Sezione comune a tutte le macchine */}

                        <div className='card mb-5 mb-xl-8 mt-5'>

                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>
                                        
                                        {isEditMode() == false ? 
                                        <>Nuovo Cliente</>
                                        :<>Modifica Cliente</>
                                        }
                                        </span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>
                                <div className='row'>

                                    <div className="mb-10 col-12 col-md-6">
                                        <label className="form-label">Codice</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.codice.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.codice.value}
                                            title={newWorkCycleViewModel?.codice.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('codice', e.target.value, e)}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-6">
                                        <label className="form-label">Ragione sociale</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.ragioneSociale.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.ragioneSociale.value}
                                            title={newWorkCycleViewModel?.ragioneSociale.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('ragioneSociale', e.target.value, e)}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-6">
                                        <label className="form-label">Indirizzo</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.indirizzo.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.indirizzo.value}
                                            title={newWorkCycleViewModel?.indirizzo.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('indirizzo', e.target.value, e)}
                                        />
                                    </div>


                                    <div className="mb-10 col-12 col-md-2">
                                        <label className="form-label">CAP</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.cap.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.cap.value}
                                            title={newWorkCycleViewModel?.cap.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('cap', e.target.value, e)}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Città</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.citta.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.citta.value}
                                            title={newWorkCycleViewModel?.citta.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('citta', e.target.value, e)}
                                        />
                                    </div>

                                </div>

                            </div>

                        </div>


                        <div className="text-center">

                            <a className="btn btn-lg btn-primary w-25 m-5" onClick={(e) => annullaSalva()} >
                                Annulla
                            </a>

                            <button
                                type="submit"
                                id="kt_sign_in_submit"
                                className="btn btn-lg btn-primary w-25 m-5"
                                data-kt-indicator={showIndicatorProgress}>
                                <span className="indicator-label">Salva</span>
                                <span className="indicator-progress" >Caricamento...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                            {/* <p ref={errRef} className={errMsg ? "alert alert-danger d-flex align-items-center" : "offscreen"} aria-live="assertive">{errMsg}</p>                                 */}
                        </div>

                    </form>
                }
            </div>

            <Modal show={modalShow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Esito Salvataggio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {saveResult &&
                            <div className="row d-flex justify-content-center">

                                <div className="d-flex flex-center flex-column py-10 px-10 px-lg-20">

                                    <span className="svg-icon svg-icon-2hx svg-icon-success mb-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                            <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                        </svg>
                                    </span>

                                    <div className="text-center">
                                        {/* <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div> */}
                                        <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: modalMessage }}></div>
                                    </div>
                                </div>

                            </div>
                        }

                        {!saveResult &&
                            <div className="row d-flex justify-content-center">

                                <div className="d-flex flex-center flex-column py-10 px-10 px-lg-20">

                                    <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                            <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                        </svg>
                                    </span>

                                    <div className="text-center">
                                        <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                        <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                        <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: modalMessage }}></div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* START - Confirmation Modal */}
            <Modal show={confirmationModalShow} onHide={handleConfirmationClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Conferma Operazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Sei sicuro di voler continuare?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleConfirmationClose}>Annulla</Button>
                    <Button variant="primary" onClick={confirmCreate}>Conferma</Button>
                </Modal.Footer>
            </Modal>
            {/* END - Confirmation Modal */}
        </>

    )
}

export default CustomerCreate