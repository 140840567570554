export function isUnitModule(location) 
{
    var output = false;

    var pathName = location.pathname;

    if(pathName.indexOf("units/") > -1)
    {
        output = true;
    }
   
    return output;
}