import { useNavigate, Link, useLocation, Outlet, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { KTSVG } from '../../_metronic/helpers'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { usePageData } from '../../_metronic/layout/core';
import clsx from 'clsx';

const UserCreate = () => {

    const [user, setUser] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();

    let { idUser } = useParams();

    const [isError, setIsError] = useState();
    const [isLoading, setIsLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [showIndicatorProgress, setShowIndicatorProgress] = useState('');
    const [modalShow, setModalShow] = useState();
    const [modalMessage, setModalMessage] = useState();
    const [saveInProgress, setSaveInProgress] = useState();
    const [saveResult, setSaveResult] = useState();

    //Per evitare l'autocomplete del browser viene impostata la tipologia dell'input su text e poi cambiata a password
    const [inputPasswordType, setInputPasswordType] = useState("text");

    const { setPageTitle, pageMachines } = usePageData();
    const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();

    const annullaSalva = () => {
        if (saveInProgress == true) {
            return;
        }
        else {
            navigate(`/settings/users`);
        }
    }

    const handleClose = () => {
        if (saveResult == true) {
            navigate(`/settings/user/${user.id}`);
        }
        else {
            setModalShow(false);
        }
    }

    const setUserProperty = (attribute, value, event) => {
       
        var validation = validateField(attribute, value);

        if (attribute == "firstName") 
        {
            setUser({...user, firstName:value, firstNameIsError:validation.isError, firstNameErrorMessage:validation.errorMessage});
        }
        else if (attribute == "lastName") 
        {
            setUser({...user, lastName:value, lastNameIsError:validation.isError, lastNameErrorMessage:validation.errorMessage});
        }
        else if (attribute == "username") 
        {
            setUser({...user, username:value, usernameIsError:validation.isError, usernameErrorMessage:validation.errorMessage});
        }
        else if (attribute == "roleId") 
        {
            setUser({...user, roleId:value});
        } 
        else if (attribute == "newPassword") 
        {
            //Per evitare l'autocomplete del browser viene cambiata la tipologia di input a runtime
            setInputPasswordType("password");
            var passwordValidation = validatePassword(value, user.newPasswordRepeat);

            setUser({...user, 
                newPassword:value,
                newPasswordIsError:passwordValidation.newPasswordIsError, 
                newPasswordErrorMessage:passwordValidation.newPasswordErrorMessage,
                newPasswordRepeatIsError:passwordValidation.newPasswordRepeatIsError, 
                newPasswordRepeatErrorMessage:passwordValidation.newPasswordRepeatErrorMessage,        
            });
            
        }     
        else if (attribute == "newPasswordRepeat") 
        {
            var validation = validatePassword(user.newPassword, value);

            setUser({...user, 
                newPasswordRepeat:value,
                newPasswordRepeatIsError:validation.newPasswordRepeatIsError, 
                newPasswordRepeatErrorMessage:validation.newPasswordRepeatErrorMessage,        
            });
        }     
        else if (attribute == "isActive") 
        {
            setUser({...user, isActive:value});
        }                                       
    }

    const validateField = (attribute, value) => {

        var output = {isError:false, errorMessage:""};

        if(attribute != "newPassword" || attribute != "newPasswordRepeat")
        {    
            if (typeof(value) == "undefined" || (typeof(value) == "string" && value?.trim() == "")) {
                output.isError = true;
                output.errorMessage = "Il campo non può essere vuoto";
            }
        }

        return output;
    }    

    const validatePassword  = (newPassword, newPasswordRepeat) => {
        
        var validation = {
            newPasswordIsError:false,
            newPasswordErrorMessage:"",
            newPasswordRepeatIsError:false,
            newPasswordRepeatErrorMessage:""
        }

        validation.newPasswordIsError = false;
        validation.newPasswordErrorMessage = "";
        validation. newPasswordRepeatIsError = false;
        validation.newPasswordRepeatErrorMessage = "";

        if(newPassword.length > 0) 
        {
            if(newPassword.length < 6) //Lunghezza minimia di sei caratteri
            {
                validation.newPasswordIsError = true;
                validation.newPasswordErrorMessage = "Lunghezza minima di 6 caratteri";
            }
        }

        if(newPasswordRepeat != newPassword) //Controllo campi uguali
        {
            validation.newPasswordRepeatIsError = true;
            validation.newPasswordRepeatErrorMessage = "Il campo deve coincidere con la password";
        }

        return validation;
    }

    //Validazione completa dell'oggetto Lavorazione
    const validateUser = (userInput) => {

        var validation;

        //Nome
        validation = validateField("firstName", userInput.firstName);
        userInput.firstNameIsError = validation.isError;
        userInput.firstNameErrorMessage = validation.errorMessage;

        //Cognome
        validation = validateField("lastName", userInput.lastName);
        userInput.lastNameIsError = validation.isError;
        userInput.lastNameErrorMessage = validation.errorMessage;

        //Username
        validation = validateField("username", userInput.username);
        userInput.usernameIsError = validation.isError;
        userInput.usernameErrorMessage = validation.errorMessage;

        //Password
        validation = validateField("newPassword", userInput.newPassword);
        userInput.newPasswordIsError = validation.isError;
        userInput.newPasswordErrorMessage = validation.errorMessage;

        //Password Repeat
        validation = validateField("newPasswordRepeat", userInput.newPasswordRepeat);
        userInput.newPasswordRepeatIsError = validation.isError;
        userInput.newPasswordRepeatErrorMessage = validation.errorMessage;

        return userInput;
    }

    const setLoadingSave = (isLoading) => {
        if (isLoading) {
            setShowIndicatorProgress('on');
        }
        else {
            setShowIndicatorProgress('off');
        }
    }

    //Setting Page BreadCrumb
    useEffect(() => {

        setPageTitle("Utenti");
        setPageBreadcrumbs([`Nuovo Utente`]);

    }, [])

    //Lettura modello lato server
    useEffect(() => {

        setIsLoading(true);

        let isMounted = true;
        const controller = new AbortController();

         const getNewUserModel = async () => {
            try {
                const response = await axiosPrivate.get(`/users/getNewUserModel`, {
                    signal: controller.signal
                });

                if (isMounted) {
                   
                    //Validazione iniziale
                    var validatedUser = validateUser(response?.data);

                    setUser(validatedUser);

                    setIsLoading(false);
                    setIsError(false);
                } 
                
            }
            catch(err) 
            {
                console.error(err);
                setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
                setIsLoading(false);
                setIsError(true);

                // if(err.response?.status === 403) 
                // {
                //     navigate('/login', {state : {from: location }, replace: true});
                // } 
            }
        }

        getNewUserModel();

    }, [])   

    const checkValidationErrors = () => {

        var output = false;

        if(
            user.firstNameIsError
            || user.lastNameIsError
            || user.usernameIsError
            || user.newPasswordIsError
            || user.newPasswordRepeatIsError
            )
        {
            output = true;
        }

        return output;
    }    

    const handleSubmit = async (e) => {
        e.preventDefault();

        //Se c'è un salvataggio in corso non possono essere inoltrare ulteriori richieste
        if (saveInProgress) {
            return;
        }

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if(checkValidationErrors() == true)
        {
            return;
        }        

        setLoadingSave(true);
        setSaveInProgress(true);

        try {
            const response = await axiosPrivate.post(`/users/create`,
                JSON.stringify(user),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );

            setUser({...user, id:response.data});

            setModalMessage("Operazione avvenuta con successo");
            setLoadingSave(false);
            setSaveInProgress(false);
            setSaveResult(true);
            setModalShow(true);
        }
        catch (err) {
            console.error(err);
            setSaveResult(false);
            setModalMessage(`${err}<br>${err?.response?.data?.message}`);
            setLoadingSave(false);
            setSaveInProgress(false);
            setModalShow(true);

            //navigate('/login', {state : {from: location }, replace: true});

            // if(err.response?.status === 403) 
            // {
            //     navigate('/login', {state : {from: location }, replace: true});
            // } 
        }
    }

    return (
        <>
            <div id="kt_content_container" className="container-xxl py-2">

                {/* begin::Spinner */}
                {isLoading &&
                    <div className="row d-flex justify-content-center m-20">
                        <Spinner animation="border" variant="primary" />
                    </div>
                }
                {/* end::Spinner */}

                {/* begin::Error message */}
                {!isLoading && isError &&

                    <div className="row d-flex justify-content-center m-20">

                        <div className="alert alert-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

                            <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                    <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                </svg>
                            </span>

                            <div className="text-center">
                                <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
                            </div>
                        </div>
                    </div>

                }
                {/* end::Error message */}

                {!isLoading && !isError &&

                    <form
                        autoComplete="off"
                        className="form w-100 mt-5"
                        noValidate="novalidate"
                        id="kt_sign_in_form"
                        onSubmit={handleSubmit}>

                        <div className='card mb-5 mb-xl-8'>
                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Nuovo Utente</span>
                                </h3>
                            </div>
                            <div className='card-body py-3'>
                                <div className='row'>
                                    <div className="mb-10 mb-10 col-sm-12 col-md-6">
                                        <label className="form-label">Nome</label>
                                        <input type="text" 
                                        className={clsx('form-control', user?.firstNameIsError ? 'bg-light-danger border-danger' : '')} 
                                        title={user?.firstNameErrorMessage} 
                                        placeholder="" 
                                        value={user?.firstName} 
                                        onChange={(e) => setUserProperty('firstName', e.target.value, e)} />
                                    </div>

                                    <div className="mb-10 mb-10 col-sm-12 col-md-6">
                                        <label className="form-label">Cognome</label>
                                        <input 
                                            type="text" 
                                            className={clsx('form-control', user?.lastNameIsError ? 'bg-light-danger border-danger' : '')} 
                                            title={user?.lastNameErrorMessage} 
                                            placeholder="" 
                                            value={user?.lastName} 
                                            onChange={(e) => setUserProperty('lastName', e.target.value, e)}/>
                                    </div>
                                    <div className="mb-10 mb-10 col-sm-12 col-md-6">
                                        <label className="form-label">Username</label>
                                        <input 
                                            type="text" 
                                            className={clsx('form-control', user?.usernameIsError ? 'bg-light-danger border-danger' : '')} 
                                            title={user?.usernameErrorMessage} 
                                            placeholder="" 
                                            value={user?.username} 
                                            onChange={(e) => setUserProperty('username', e.target.value, e)}/>
                                    </div>
                                    <div className="mb-10 mb-10 col-sm-12 col-md-6">
                                        <label className="form-label">Ruolo</label>
                                        <select 
                                        className='form-select' 
                                        value={user?.roleId}
                                        onChange={(e) => setUserProperty('roleId', e.target.value, e)}>
                                            <option value='100'>Operatore</option>
                                            <option value='200'>Supervisore</option>
                                            <option value='300'>Amministratore</option>
                                        </select>

                                    </div>

                                    <div className="mb-10 mb-10 col-sm-12 col-md-6">
                                        <label className="form-label">Login Attivo</label>
                                        <div className="form-check form-check-custom form-check-solid">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={(e) => setUserProperty('isActive', e.target.checked, e)}
                                                checked={user?.isActive}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='card mb-5 mb-xl-8'>
                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Imposta Password</span>
                                </h3>
                            </div>
                            <div className='card-body py-3'>

                                <div className='row'>
                                    <div className="col-sm-12 col-md-6  mb-10">
                                        <label className="form-label">Password</label>
                                      
                                        <input 
                                            type={inputPasswordType} 
                                            className={clsx('form-control', user?.newPasswordIsError ? 'bg-light-danger border-danger' : '')} 
                                            title={user?.newPasswordErrorMessage} 
                                            placeholder="" 
                                            value={user?.newPassword} 
                                            onChange={(e) => setUserProperty('newPassword', e.target.value, e)}/>
                                    </div>

                                    <div className="col-sm-12 col-md-6  mb-10">
                                        <label className="form-label">Ripeti Password</label>
                                      
                                        <input 
                                            type={inputPasswordType} 
                                            className={clsx('form-control', user?.newPasswordRepeatIsError ? 'bg-light-danger border-danger' : '')} 
                                            title={user?.newPasswordRepeatErrorMessage} 
                                            placeholder="" 
                                            value={user?.newPasswordRepeat} 
                                            onChange={(e) => setUserProperty('newPasswordRepeat', e.target.value, e)}/>
                                    </div>                                             
                                </div>
                            </div>
                        </div>

                        <div className="text-center">

                            <a className="btn btn-lg btn-primary w-25 m-5" onClick={(e) => annullaSalva()} >
                                Annulla
                            </a>

                            <button
                                type="submit"
                                id="kt_sign_in_submit"
                                className="btn btn-lg btn-primary w-25 m-5"
                                data-kt-indicator={showIndicatorProgress}>
                                    <span className="indicator-label">Salva</span>
                                    <span className="indicator-progress" >Caricamento...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                }
            </div>

            <Modal show={modalShow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Esito Salvataggio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {saveResult &&
                            <div className="row d-flex justify-content-center">

                                <div className="d-flex flex-center flex-column py-10 px-10 px-lg-20">

                                    <span className="svg-icon svg-icon-2hx svg-icon-success mb-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                            <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                        </svg>
                                    </span>

                                    <div className="text-center">
                                        {/* <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div> */}
                                        <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: modalMessage }}></div>
                                    </div>
                                </div>

                            </div>
                        }

                        {!saveResult &&
                            <div className="row d-flex justify-content-center">

                                <div className="d-flex flex-center flex-column py-10 px-10 px-lg-20">

                                    <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                            <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                        </svg>
                                    </span>

                                    <div className="text-center">
                                        <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                        <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                        <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: modalMessage }}></div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default UserCreate