import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { usePageData } from '../../_metronic/layout/core';
import MachineDashboard from './MachineDashboard';
import MachineProdListV2 from '../produzione/generic/MachineProdListV2';
import { Spinner } from 'react-bootstrap';

const MachineDashboardHandler = () => {

    const [machineStatus, setMachineStatus] = useState();
    const [isError, setIsError] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isPageLoading, setIsPageLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [machineNameTitle, setMachineNameTitle] = useState();
    const [machine, setMachine] = useState();

    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    let { idMacchina } = useParams();

    const { setPageTitle, pageMachines } = usePageData();
    const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();

//Setting Page BreadCrumb
useEffect(()=>{ 

    let isMounted = true;
    const controller = new AbortController();
    
    //Se non è stata trovata nessuna macchine viene impostato un nome di defualt 'No Name'
    let machineName = "No Name";

    const getMachine = async () => {

        setIsLoading(true);

        try {
          const response = await axiosPrivate.get(`/machines/getMachineById/${idMacchina}`, {
            signal: controller.signal
          });
  
          if (isMounted) {
            machineName = response.data.name;
            setMachine(response.data);
          }
        }
        catch (err) {
          console.error(err);
        }
  
        setIsLoading(false);

        setPageTitle(machineName);
        setPageBreadcrumbs(['Dashboard']);
        }
  
      getMachine();


    return () => {
        isMounted = false;
        controller.abort();
    }

  },[idMacchina])   
  

    return (<>

        {/* begin::Spinner */}
        {isLoading &&
            <div className="row d-flex justify-content-center m-20">
                <Spinner animation="border" variant="primary" />
            </div>
        }
        {/* end::Spinner */}

        {!isLoading && <>

            {machine?.hasDashboard ?
                <MachineDashboard />
                : <MachineProdListV2 />
            }

        </>}
    </>
        
    )
}

export default MachineDashboardHandler