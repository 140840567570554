/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout, usePageData} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout();
  const {header, aside} = config;
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData();

  return (
    <div id='kt_header' className='header align-items-stretch'>
      {/* begin::Container */}
      <div className='container-fluid d-flex align-items-stretch justify-content-between'>
        {/* begin::Aside mobile toggle */}
        <div className="d-flex align-items-center d-lg-none ms-n1 me-2" title="Show aside menu">
            <div className="btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px" id="kt_aside_mobile_toggle">
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon svg-icon-1' />
            </div>
          </div>
        {/* end::Aside mobile toggle */}
        {/* begin::Mobile logo */}
        {/* <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
          <Link to='/' className='d-lg-none'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-demo6.svg')} className='h-30px' />
          </Link>
        </div> */}
        {/* end::Mobile logo */}

        {/* begin::Wrapper */}
        {/* <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1"> */}
        <div className="d-flex align-items-stretch justify-content-between flex-grow-1 flex-lg-grow-1">
          {/* begin::Navbar */}
          <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <div id="kt_page_title"  className="page-title d-flex align-items-center flex-wrap me-3 mb-lg-0">
                <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">{pageTitle}</h1>

              {pageBreadcrumbs 
              && pageBreadcrumbs.length > 0 
              &&
                <>
                  <span className="h-20px border-gray-200 border-start mx-4"></span>
                  <ul className="breadcrumb breadcrumb-line text-muted fs-6 fw-bold">
                    {Array.from(pageBreadcrumbs).map((item, index) => (
                          <li className="breadcrumb-item pe-3" key={index}>
                          {' '+item}
                          </li>
                    ))}

                  </ul>
                </>
              }



              </div>              
            
            {/* <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
            <small className="text-muted fs-7 fw-bold my-1 ms-1">#XRS-45670</small> */}
            {/* <Header /> */}
          </div>
          {/* end::Navbar */}

          {/* {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )} */}

            <Topbar />
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}