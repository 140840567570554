import clsx from 'clsx'
import { useNavigate, Link} from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';

export function UnitJobStatus(props) 
{
        var bgClass = '';
        var statusName = '';


        if (props.row.startDate != null && props.row.endDate == null) {
          bgClass = 'bg-warning';
          statusName = 'In Corso';
        }
        else if (props.row.endDate != null) {
          bgClass = 'bg-success';
          statusName = 'Completo';
        }

        return (<span className={clsx(`badge ${bgClass}`)}>{statusName}</span>);        
}

export function UnitJobsMobilePagination(props) 
{
    var currentPage = props.currentPage;
    var perPage = props.perPage;
    var totalRows = props.totalRows;

    return (
        <>
        {totalRows > 0 &&
        <div className="col-12 mt-10">
            <ul className="pagination pagination-lg">
              <li className={clsx('page-item previous', {'disabled' : currentPage == 1})}>
                <div className="page-link" onClick={(e) => props.onGoToPrevPage()}>
                  <i className="previous"></i>
                </div>
              </li>
              
              <li className="page-item">
                <a className="page-link">
                {1 + ((currentPage -1) * perPage)} - {((((currentPage -1) * perPage)) + perPage ) > totalRows ? totalRows : ((((currentPage -1) * perPage)) + perPage ) } di {totalRows}
                </a>
              </li>

              <li className={clsx('page-item next', { 'disabled': currentPage == Math.ceil(totalRows / perPage) })}>
                <div className="page-link" onClick={(e) => props.onGoToNextPage()}>
                  <i className="next"></i>
                </div>
              </li>
            </ul>
          </div>
        }

        {totalRows ==0 && 
        <>
          <div className="col-12 mt-10">
            <div className="alert alert-primary fs-3 text-center py-10">
              Nessuna lavorazione presente
            </div>
          </div>
        </>
        }


          </>
    )
}

export function UnitJobCard(props) 
{
    var po = props.productionOrder;
    return (
        <>
            <div className='card mb-5 mt-5'>
                <div className='card-header border-0 pt-5' onClick={(e) => props.onGoToDetailPage(po)}>

                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-3'>{po?.orderNumber} ({po?.id})</span>
                    </h3>
                    <div className="card-toolbar">
                        <UnitJobStatus row={po} />
                    </div>
                </div>
                <div className='card-body py-3' onClick={(e) => props.onGoToDetailPage(po)}>
                    <div className="row">

                        <div className="col-6 mb-3">
                            <div className="fs-9 fw-bold">Macchina</div>
                            <div className="fs-2"> {po?.machineName}</div>
                        </div>

                        <div className="col-6 mb-3">
                            <div className="fs-9 fw-bold">Num. Pelli</div>
                            <div className="fs-2"> {po?.leathersNumber}</div>
                        </div>

                        <div className="col-6 mb-3">
                            <div className="fs-9 fw-bold">Articolo</div>
                            <div className="fs-2"> {po?.article}</div>
                        </div>

                        <div className="col-6 mb-3">
                            <div className="fs-9 fw-bold">Colore</div>
                            <div className="fs-2"> {po?.color}</div>
                        </div>

                        <div className="col-6 mb-3">
                            <div className="fs-9 fw-bold">Data Inizio</div>
                            <div className="fs-3"> {po?.startDateStr ? po?.startDateStr : '-'}</div>
                        </div>

                        <div className="col-6 mb-3">
                            <div className="fs-9 fw-bold">Data Fine</div>
                            <div className="fs-3"> {po?.endDateStr ? po?.endDateStr : '-'}</div>
                        </div>
                    </div>

                </div>

                {!po?.endDate &&
                  <div className="btn btn-danger mx-5 mb-5"
                    onClick={(e) => props.onEndJob(po)} >

                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-solid fa-stop"></i>
                    </span>
                    Termina
                  </div>
              }

            </div>
        </>
    )
}

export function EndSingleJobConfirmationModal(props) {

  return <>

      {/* START - Delete Confirmation Modal */}
      <Modal show={props.show} onHide={props.onHide}>

          <Modal.Header closeButton>
              <Modal.Title>Conferma Operazione</Modal.Title>
          </Modal.Header>

          <Modal.Body>
              Sei sicuro di voler continuare?
          </Modal.Body>

          <Modal.Footer>
              <Button variant="secondary" onClick={props.onHide}>Annulla</Button>
              <Button variant="primary" onClick={props.onConfirm}>Conferma</Button>
          </Modal.Footer>
      </Modal>
      {/* END - Confirmation Modal */} 

  </>;
}