
export function isEditMode(location) 
{
    var output = false;

    if(location.pathname.indexOf("/edit") > -1)
    {
        output = true;
    }
   
    return output;
}


export function isViewMode (location)
{
    var output = false;

    if(location.pathname.indexOf("/view") > -1)
    {
        output = true;
    }
   
    return output;
}    

export function isCreateMode(location)
{
    var output = false;

    if(location.pathname.indexOf("/create") > -1)
    {
        output = true;
    }
   
    return output;
}     


export function validateProperty (property)
{
    //Validazione
    if (property.isMandatory) {
        var propValue = "";

        if (property.dataType == "Date") {
            propValue = property.value;
        }
        else {
            propValue = property.value?.trim();
        }

        if (propValue == "" || propValue == null) {
            property.isError = true;
            property.errorMessage = "Il campo non può essere vuoto";
        }
        else {
            property.isError = false;
            property.errorMessage = "";
        }
    }

    return property;
}

//Validazione completa dell'oggetto Lavorazione
export function validateWorkCycle(entity) 
{
    entity.codice = validateProperty(entity.codice);
    entity.ragioneSociale = validateProperty(entity.ragioneSociale);
    entity.indirizzo = validateProperty(entity.indirizzo);
    entity.cap = validateProperty(entity.cap);
    entity.citta = validateProperty(entity.citta);

    return entity;
}

export function checkValidationErrors (entity)
{
    var output = false;

    if(
        entity.codice.isError
    || entity.ragioneSociale.isError
    || entity.indirizzo.isError
    || entity.cap.isError
    || entity.citta.isError
    )
    {
        output = true;
    }

    return output;
}
