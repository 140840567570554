import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { usePageData } from '../../_metronic/layout/core';
import { Spinner } from 'react-bootstrap';
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers';
import clsx from 'clsx';

const MachineDashboard = () => {

    const [machineStatus, setMachineStatus] = useState();
    const [isError, setIsError] = useState();
    const [isLoading, setIsLoading] = useState();
    const [isPageLoading, setIsPageLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [machineNameTitle, setMachineNameTitle] = useState();
    const [machine, setMachine] = useState({});

    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    let { idMacchina } = useParams();
    let { codiceMacchina } = useParams();

    const { setPageTitle, pageMachines } = usePageData();
    const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();

    //Setting Page BreadCrumb
    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        //Se non è stata trovata nessuna macchine viene impostato un nome di defualt 'No Name'
        let machineName = "No Name";

        const getMachineName = async () => {
            try {
                const response = await axiosPrivate.get(`/machines/getMachineById/${idMacchina}`, {
                    signal: controller.signal
                });

                if (isMounted) {
                    machineName = response.data;

                    setPageTitle(response.data.name);
                    setMachine(response.data);
                }
            }
            catch (err) {
                console.error(err);
            }

            
            setPageBreadcrumbs(['Dashboard']);
        }

        getMachineName();

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, [idMacchina])

    const UPDATE_INTERVAL_MS = 5000;

    useEffect(() => {

        updateMachineStatus(true);

        const interval = setInterval(() => {
            updateMachineStatus(false);
        }, UPDATE_INTERVAL_MS);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.        
    }, [idMacchina])

    const updateMachineStatus = (showPageLoading) => {

        //Se già c'è una chiamata in corso, l'aggiornamento non viene eseguito
        if (isLoading) {
            return;
        }

        setIsLoading(true);

        if(showPageLoading)
        {
            setIsPageLoading(true);
        }

        let isMounted = true;
        const controller = new AbortController();

        const getMachineStatus = async () => {
            try {
                const response = await axiosPrivate.get(`/machines/getStatus/${idMacchina}`, {
                    signal: controller.signal
                });

                if (isMounted) {
                    setMachineStatus(response.data);
                    setIsLoading(false);
                    setIsPageLoading(false);
                    setIsError(false);
                }
            }
            catch (err) {
                console.error(err);
                setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
                setIsLoading(false);
                setIsPageLoading(false);
                setIsError(true);

                // if(err.response?.status === 401) 
                // {
                //     navigate('/login', {state : {from: location }, replace: true});
                // } 
            }
        }

        getMachineStatus();

        return () => {
            isMounted = false;
            controller.abort();
        }
    }


    return (
        <div id="kt_content_container" className="container-xxl py-2">
            {/* begin::Spinner */}
            {isPageLoading &&
                <div className="row d-flex justify-content-center m-20">
                    <Spinner animation="border" variant="primary" />
                </div>
            }
            {/* end::Spinner */}

            {!isPageLoading && <>

                <div className="row">

                    <div className="col-sm-12 col-md-12">
                        <div className='card mt-5 mb-5 mb-xl-8'>
                            {/* begin::Header */}
                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>{machine?.name}</span>
                                </h3>

                                <div className='card-toolbar'>
                                    <div
                                        type="button"
                                        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                        onClick={(e) => updateMachineStatus()}>
                                        <i className="fas fa-sync"></i>
                                    </div>
                                </div>

                            </div>
                            {/* end::Header */}

                            {/* begin::Body */}
                            <div className='card-body py-3' >
                                <div className="row" style={{ minHeight: '260px' }} >

                                    <div className="col-12 text-center">
                                        <img
                                            alt=''
                                            className='img-fluid'
                                            src={machine?.imagePath}
                                        />
                                    </div>

                                    <div className="col-12 p-0">
                                        <div className="text-end p-0">
                                            Ultimo Aggiornamento:&nbsp;
                                            {!isError && machineStatus?.lastUpdateStr}
                                            {isError && 'ND'}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* end::Body */}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className='card mt-5 mb-5 mb-xl-8'>
                            {/* begin::Header */}
                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Parametri di funzionamento</span>
                                </h3>
                                <div className='card-toolbar'>
                                    {/* begin::Spinner */}
                                    {isLoading &&
                                        <div className="row d-flex justify-content-center m-2">
                                            <Spinner animation="border" variant="primary" />
                                        </div>
                                    }
                                    {/* end::Spinner */}
                                </div>
                            </div>
                            {/* end::Header */}

                            {/* begin::Body */}
                            <div className='card-body py-3' >

                                {/* begin::Error message */}
                                {isError &&

                                    <div className="row d-flex justify-content-center">

                                        <div className="alert alert-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

                                            <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                                    <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                                </svg>
                                            </span>

                                            <div className="text-center">
                                                <h1 className="fw-bolder mb-5 text-danger" >Errore</h1>
                                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                                <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
                                            </div>
                                        </div>

                                    </div>
                                }
                                {/* end::Error message */}

                                {!isError && !machineStatus?.isLate && machineStatus?.status == 0 && <>

                                    <div className="row">

                                    {machineStatus?.parameters.map((parameter, j) =>

                                        <div className=" col-sm-6 col-md-3" key={j}>
                                             <div 
                                                className={clsx({
                                                    'border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3': true,
                                                    'bg-danger': parameter?.isAlarm && parameter.isAlarmOn,
                                                    'bg-secondary': parameter?.isAlarm && !parameter.isAlarmOn,
                                                })}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <div className="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="4500" data-kt-countup-prefix="$">
                                                        {parameter?.value} {parameter?.value != "" ? parameter?.um : ""}
                                                    </div>
                                                </div>
                                                <div className="fw-bold fs-6 text-gray-400">{parameter?.name}</div>
                                            </div> 
                                        </div>
                                    )}

                                    </div>

                                </>}

                                {!isError && !machineStatus?.isLate && machineStatus?.status > 0 && <>

                                    <div className="row d-flex justify-content-center">

                                        <div className="alert alert-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

                                            <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                                    <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                                </svg>
                                            </span>

                                            <div className="text-center">
                                                <h1 className="fw-bolder mb-5 text-danger" >Errore Lettura Dati</h1>
                                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                                <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: machineStatus?.statusMessage }}></div>
                                            </div>
                                        </div>

                                    </div>

                                </>}

                                {!isError && machineStatus?.isLate && <>

                                    <div className="row d-flex justify-content-center">

                                        <div className="alert alert-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

                                            <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                                    <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                                </svg>
                                            </span>

                                            <div className="text-center">
                                                <h1 className="fw-bolder mb-5 text-danger" >Dati obsoleti</h1>
                                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                                <div className="mb-9 text-dark">
                                                    <span>Data Ultimo Aggiornamento: {machineStatus?.lastUpdateStr}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </>}

                            </div>
                            {/* end::Body */}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="text-center">
                        {/* <Link to={`/macchina/${idMacchina}/ricette`} className='btn btn-lg btn-primary w-25 m-5'>
                            <span className="indicator-label">Ricette</span>
                        </Link> */}

                        <Link to={`/produzione/${idMacchina}/${codiceMacchina}/lavorazioni`} className='btn btn-lg btn-primary m-5'>
                            <span className="indicator-label">Lavorazioni</span>
                        </Link>
                    </div>
                </div>



            </>}


        </div>
    )
}

export default MachineDashboard