import { useNavigate, Link } from "react-router-dom";
import {Outlet, useParams} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../../_metronic/helpers'
import clsx from 'clsx'

const InventoryMovementsNavigation = () => 
{
    const {pathname} = useLocation();
    let { inventoryId } = useParams(); 
    
    let movementsUrl = `/inventory/${inventoryId}/movements/`;
    let stockUrl = `/inventory/${inventoryId}/stock/`;
    const isMovementsActive = checkIsTabMovementsActive(pathname);
    const isStockActive = checkIsTabStockActive(pathname);

    return (
        <>
            {/* <!--begin::Container--> */}
                <div 
                className="container-fluid d-flex align-items-center bg-white mytoolbar">
                
                    {/* <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"> */}
                                
                        <div className="d-flex overflow-auto h-55px">
                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                                <li className="nav-item"><Link to={movementsUrl} className={clsx('nav-link text-active-primary me-6', { active: isMovementsActive})}>Movimenti</Link></li>
                                <li className="nav-item"><Link to={stockUrl} className={clsx('nav-link text-active-primary me-6', { active: isStockActive})}>Giacenza</Link></li>
                            </ul>
                        </div>                                    

                    {/* </div> */}
                </div>
            {/* <!--end::Container--> */}
        <Outlet/>
        </>

    )
}

  function checkIsTabMovementsActive(pathname) {
    if (!pathname) {
      return false;
    }

    if(pathname.indexOf("movements") > -1)
    {
        return true;
    }
  
    return false;
  }

  function checkIsTabStockActive(pathname) {
    if (!pathname) {
      return false;
    }

    if(pathname.indexOf("stock") > -1 )
    {
        return true;
    }
  
    return false;
  }

export default InventoryMovementsNavigation