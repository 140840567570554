import { Link } from "react-router-dom"

const Missing = () => {
    return (
        <div id="kt_content_container" className="container-xxl py-2">


        <div className="container">
            <div className="row ">
                <div className="col-md-12 m-20 text-center">
                    <div className="error-template">
                        <h1>Pagina non trovata</h1>
                        {/* <h2>
                            404 Not Found</h2> */}
                        <div className="error-details">
                            La pagina che stai tentando di visitare non è stata trovata
                        </div>
                        <div className="error-actions">

                        <Link to={`/home`} className='btn btn-lg btn-primary w-25 m-5'>
                            <span className="indicator-label">Vai alla Home</span>
                        </Link> 

                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
    )
}

export default Missing