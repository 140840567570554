import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

  export default function InfoTooltip({ text }) {
      return(
        <>
            <OverlayTrigger
                placement='top'
                overlay={<Tooltip>{text}</Tooltip>}>
                <i className="fas fa-info-circle text-primary me-2"></i>
            </OverlayTrigger>
            </>
      )
  };