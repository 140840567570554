import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { KTSVG } from '../../../_metronic/helpers'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { usePageData } from '../../../_metronic/layout/core';
import { Spinner, Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DatePicker, {registerLocale} from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it"; // the locale you want
import {PageErrorMessage, GetGridColumns, GetInventoryElements, GetLeathersCount} from "./Components";
import {LeathersCounterModal} from "./Modals";
import useCache from '../../../hooks/useCache';

import Select from 'react-select';

registerLocale("it", it); // register it with the name you want

const InventoryMovements = () => {
  const [entities, setEntities] = useState();

  let { inventoryId } = useParams();
  let navPrefix = "inventory";
  let entityType = "leather";

  //const [modalShow, setModalShow] = useState();  
  //const [modalMessage, setModalMessage] = useState();  

  const [isError, setIsError] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [isCounterModalVisible, setIsCounterModalVisible] = useState(false);
  const [movementsCounter, setMovementsCounter] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  let { idMacchina } = useParams();

  const { setPageTitle, pageMachines } = usePageData();
  const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();
  const [machineCode, setMachineCode] = useState();

  const [modalFilterShow, setModalFilterShow] = useState(false);  

  const [tempFilter, setTempFilter]= useState();
  const [showExportProgress, setShowExportProgress] = useState('off');

  //---START Gestione Filtri---//
  const { filters } = useCache();
  const [filter, setFilter] = useState({});
  let emptyFilter = {
    batchNumber:'',
    productionOrderNumber:'',
    articleId:null,
    supplierId:null,
    startDate:'',
    endDate:'',
    sortField:'Date',
    sortDirection:'desc'
  };
  //---END Gestione Filtri---//

  //Pagination Handling
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  //Sorting handling
  const [defaultSortAsc, setDefaultSortAsc] = useState(false);
  const [defaultSortFieldId, setDefaultSortFieldId] = useState(1);  

  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);

  const [modalShow, setModalShow] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [saveInProgress, setSaveInProgress] = useState();
  const [showIndicatorProgress, setShowIndicatorProgress] = useState('');
  const [saveResult, setSaveResult] = useState();

  const [articleOptions, setArticleOptions] = useState([]);
  const [supplierOptions, setSupplierptions] = useState([]);

  const paginationComponentOptions = {
    rowsPerPageText: 'Elementi per pagina',
    rangeSeparatorText: 'di',
    selectAllRowsItem: false
  };

  const columns = GetGridColumns(navPrefix, inventoryId)

  //Setting Page BreadCrumb and getting inventory informations (eg. articles for filtering)
  useEffect(async () => {

    setPageTitle("Magazzino Pelli");

    if (inventoryId == "0") {
      setPageBreadcrumbs(["Piclato"]);
    }
    else if (inventoryId == "1") {
      setPageBreadcrumbs(["Crust"]);
    }
    else if (inventoryId == "2") {
      setPageBreadcrumbs(["WB"]);
    }
    else if (inventoryId == "3") {
      setPageBreadcrumbs(["Finito"]);
    }
    

    let elements = await GetInventoryElements(axiosPrivate, inventoryId, entityType);

    setArticleOptions(elements.articles);
    setSupplierptions(elements.suppliers);

  }, [inventoryId])

  useEffect(() => {

    let initFilter = {};
  
    //Se il filtro non esiste in cache, allora viene impostato quello vuoto da utilizzare e salvato in cache per la pagina corrente
    //Altrimenti viene preso il filtro in cache
    if((typeof filters[location.pathname] === 'undefined'))
    {
      initFilter = emptyFilter;
    }
    else
    {
      initFilter = filters[location.pathname];
    }

    setTableFiltering(initFilter);

    getEntityList(1, perPage, initFilter);

  }, [inventoryId])

  //Dato che il filtro in cache è stato introdotto successivamente, viene fatta una doppia operazione
  //Sia sul filtro in cache che in quello locale. Quindi è stato raggruppato nello stesso medoto
  const setTableFiltering = (inputFilter) =>
  {
    filters[location.pathname] = inputFilter;
    setFilter(inputFilter);
  }    


  const handleSort = async (column, sortDirection) => {

    if(sortDirection == "asc" || sortDirection == "")
    {
      setDefaultSortAsc(true);
    }
    else
    {
      setDefaultSortAsc(false);
    }

    setDefaultSortFieldId(column.sortFieldId);

    let sortedFilter = {...filter, sortField:column.sortField, sortDirection, sortDirection};
    setTableFiltering(sortedFilter);
    getEntityList(1, perPage, sortedFilter);
  };

  const handleFilterClose = () => {
    
    //Se la finestra viene chiusa senza ne applicare ne resettare il filtro, allora i filtri impostati dal momento dell'apertura 
    //della finestra del filtro vengono persi e reimpostati quelli precedentemente applicati
    setFilter(tempFilter);
 
   setModalFilterShow(false);
  }    

  const showFilterModal = () => {

    //Salvataggio in memoria del filtro attuale. 
    //Questo viene fatto perchè se non viene applicato o resettato, il filtro viene reimpostato
    //A quello al momento dell'apertura della finestra

    setTempFilter(filter);

    setModalFilterShow(true);
   }  

   const resetFilter = () => {

    setTableFiltering(emptyFilter);
    getEntityList(1, perPage, emptyFilter);

    setModalFilterShow(false);
   }       

   const applyFilter = () => {
    setTableFiltering(filter);
    
    getEntityList(1, perPage, filter);
    setModalFilterShow(false);
  }  

   const setFilterProperty = (attribute, value, event) => 
   {
      var tempFilter = {...filter};

      tempFilter[attribute] = value;

      setFilter(tempFilter);
   }

   const getEntityListRequest = (page, pageSize, pageFilter)=> {

    var articleId = '';
    if(pageFilter.articleId != null)
    {
      articleId = pageFilter.articleId.value;
    }

    var supplierId = '';
    if(pageFilter.supplierId != null)
    {
      supplierId = pageFilter.supplierId.value;
    }    

    let entityListRequest =
    {
      filterInventoryId: inventoryId,

      filterBatchNumber: pageFilter.batchNumber,
      filterProductionOrderNumber: pageFilter.productionOrderNumber,

      filterArticleId: articleId,
      filterSupplierId: supplierId,
      filterStartDate: pageFilter.startDate,
      filterEndDate: pageFilter.endDate,

      page: page,
      pageSize: pageSize,
      sortField: pageFilter.sortField,
      sortDirection: pageFilter.sortDirection
    }

    return entityListRequest;
   }

   const countLeathers = async (pageFilter) => 
   {
      var entityListRequest = getEntityListRequest(0, 0, pageFilter);

      var counter = {};

      try 
      {
        counter = await GetLeathersCount(axiosPrivate, entityListRequest);
        counter.isError = false;
      }
      catch(err)
      {
          counter.isError = true;
          counter.errorMessage = err.message;
      }

      console.log(counter)
      setMovementsCounter(counter);
      setIsCounterModalVisible(true);
   }

  const getEntityList = (page, pageSize, pageFilter) => {

    setIsLoading(true);

    let isMounted = true;
    const controller = new AbortController();

    let entityListRequest = getEntityListRequest(page, pageSize, pageFilter);

    const fetchWorkCycleList = async () => {

      try 
      {
        const response = await axiosPrivate.post(`/inventoryMovement/getEntities`,  
        JSON.stringify(entityListRequest),
        {
            headers: {'Content-Type':'application/json'},
            withCredentials: true,
            signal:controller.signal
        });

        if (isMounted) 
        {
          setEntities(response.data.data);
          setTotalRows(response.data.total);

          //L'elenco delle righe selezionate viene svuotato ogni volta che c'è un aggiornamento delle righe mostrate
          setSelectedRows([]); 

          setIsLoading(false);
          setIsError(false);
        }
      }
      catch (err) 
      {
        console.error(err);
        setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
        setIsLoading(false);
        setIsError(true);
      }
    }

    fetchWorkCycleList();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }

  const onFilterArticleChange = (inputValue, { action, prevInputValue }
  ) => {

    console.log("onFilterArticleChange", action, inputValue);

    // if (action === 'input-change')
    // {
    //   setFilterProperty('articleId', inputValue)
    // }
    
  };

  const handleChange = (selectedOption) => {

    var value = "";
    setFilterProperty('articleId', selectedOption)

  };

  const handlePageChange = page => {
		getEntityList(page, perPage, filter);
    setCurrentPage(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {

    getEntityList(page, newPerPage, filter);
    setPerPage(newPerPage);
	};  

  return (
    <div id="kt_content_container" className="mx-5">

      {/* begin::Spinner */}
      {isLoading &&
        <div className="row d-flex justify-content-center m-20">
          <Spinner animation="border" variant="primary" />
        </div>
      }
      {/* end::Spinner */}

      {/* begin::Error message */}
      {!isLoading && isError &&
        <PageErrorMessage message={errorMessage}/>
      }
      {/* end::Error message */}

      {/* begin::Table */}
      {!isLoading && !isError &&
        <>

          <div className='card mb-5 mt-5 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Elenco Movimenti</span>
              </h3>
              <div className="card-toolbar">

                <div className='btn btn-primary me-2 mt-1' onClick={(e) => showFilterModal()}>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-search"></i>
                  </span>
                  Filtro
                </div>

                <div className='btn btn-secondary me-2 mt-1' onClick={(e) => countLeathers(filter)}>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-calculator"></i>
                  </span>
                  Calcola
                </div>
                                
                <Link to={`/${navPrefix}/${inventoryId}/movements/create/load/0`} className='btn btn-success me-2 mt-1'>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-solid fa-plus"></i>
                  </span>
                  Carico
                </Link>

                <Link to={`/${navPrefix}/${inventoryId}/movements/create/unload/0`} className='btn btn-warning mt-1'>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-solid fa-plus"></i>
                  </span>
                  Scarico
                </Link>

              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>

              <div className='row mb-2'>

                <DataTable
                  columns={columns}
                  data={entities}
                  pagination
                  paginationServer

                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationDefaultPage={currentPage}
                  paginationPerPage={perPage}
                  paginationComponentOptions={paginationComponentOptions}
                  paginationRowsPerPageOptions={[10, 25, 50, 100]}

                  onSort={handleSort}
                  sortServer
                  defaultSortFieldId={defaultSortFieldId}
                  defaultSortAsc={defaultSortAsc}

                  subHeader={true}

                  highlightOnHover
                  striped
                />
              </div>
            </div>
          </div>
        </>
      }

      <Modal show={modalFilterShow} onHide={handleFilterClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filtri di Ricerca</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">

            <div className="row">

              <div className="mb-10 col-6">
                <label className="form-label">Lotto</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="Lotto"
                  autoComplete="off"
                  value={filter?.batchNumber}
                  onChange={(e) => setFilterProperty('batchNumber', e.target.value, e)}
                />
              </div>

              <div className="mb-10 col-6">
                <label className="form-label">Num. Ordine</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="Lotto"
                  autoComplete="off"
                  value={filter?.productionOrderNumber}
                  onChange={(e) => setFilterProperty('productionOrderNumber', e.target.value, e)}
                />
              </div>              

              <div className="mb-10 col-12">
                <label className="form-label">Articolo</label>
                <Select 
                  isClearable={true}
                  options={articleOptions} 
                  isSearchable={true}
                  value={filter?.articleId}
                  onChange={(selectedOption)=> setFilterProperty('articleId', selectedOption)}
                />                
              </div>

              {/* <div className="mb-10 col-12">
                <label className="form-label">Fornitore</label>
                <Select 
                  isClearable={true}
                  options={supplierOptions} 
                  isSearchable={true}
                  value={filter?.supplierId}
                  onChange={(selectedOption)=> setFilterProperty('supplierId', selectedOption)}
                />                
              </div>               */}

            </div>
            <div className="row">
              <div className="mb-10 col-6">
                <label className="form-label">Da</label>
                <DatePicker
                  placeholderText="Scegli Data"
                  locale="it"
                  className='form-control form-control-solid'
                  name="startDate"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                  selected={filter?.startDate}
                  onChange={(date) => setFilterProperty('startDate', date, null)}
                />
              </div>

              <div className="mb-10 col-6">
                <label className="form-label">A</label>
                <DatePicker
                  placeholderText="Scegli Data"
                  locale="it"
                  className='form-control form-control-solid'
                  name="endDate"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                  selected={filter?.endDate}
                  onChange={(date) => setFilterProperty('endDate', date, null)}
                />
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetFilter}>
            Reset
          </Button> 
          <Button variant="primary" onClick={applyFilter}>
            Applica
          </Button>         
        </Modal.Footer>
      </Modal> 

      <LeathersCounterModal 
      show={isCounterModalVisible} 
      onHide={()=> {setIsCounterModalVisible(false)}} 
      counter={movementsCounter}/>      

    </div>
  )
}

export default InventoryMovements 