import { useNavigate, Link, useLocation, Outlet, useParams } from "react-router-dom";
import { useState, useEffect, useMemo } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { usePageData } from '../../../_metronic/layout/core';
import useAuth from "../../../hooks/useAuth";
import clsx from 'clsx'

import { isCreateMode, isEditMode, isViewMode, validateProperty, validateWorkCycle, checkValidationErrors } from "./AccountHelper";

import {ConfirmationModal, ResultModal} from "./ConfirmationModal";
import {PageErrorMessage, FormInputString} from "./AccountComponents";

const AccountHandler = () => {    
    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [isError, setIsError] = useState();
    const [isLoading, setIsLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [showIndicatorProgress, setShowIndicatorProgress] = useState('');
    const [modalShow, setModalShow] = useState();
    const [modalMessage, setModalMessage] = useState();
    const [saveInProgress, setSaveInProgress] = useState();
    const [deleteInProgress, setDeleteInProgress] = useState();
    const [saveResult, setSaveResult] = useState();
    const [newWorkCycleViewModel, setNewWorkCycleViewModel] = useState();
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const [deleteConfirmationModalShow, setDeleteConfirmationModalShow] = useState(false);
    const [operationInProgress, setOperationInProgress] = useState("");
    
    var forseUpdate = new Date();
    
    let { accountId } = useParams();
    let { accountType } = useParams();
    let { handlerMode } = useParams();

    let navPrefix = "/profiles";
    let entityName = "Fornitore";
    let entitiesName = "Fornitori";
    let userType = "SUPPLIER";

    const { setPageTitle, pageMachines } = usePageData();
    const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();


    const handleClose = () => 
    {
        if (saveResult == true) 
        {
            if(operationInProgress == "delete")
            {
                navigate(`${navPrefix}/account/${accountType}/list`);
            }
            else
            {
                navigate(`${navPrefix}/account/${accountType}/view/${newWorkCycleViewModel.id}`);
            }
        }

        setModalShow(false);
    }

    const handleConfirmationClose = () => {
        setConfirmationModalShow(false);
    }

    const handleDeleteConfirmationClose = () => {
        setDeleteConfirmationModalShow(false);
    }

    const confirmCreate = async () => {
        setConfirmationModalShow(false);

        //Se c'è un salvataggio in corso non possono essere inoltrare ulteriori richieste
        if (saveInProgress) {
            return;
        }

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if (checkValidationErrors(newWorkCycleViewModel) == true) {
            return;
        }

        setLoadingSave(true);
        setSaveInProgress(true);

        //Impostazione dell'id macchina per cui creare il ciclo di lavoro

        var endpoint = "";

        if(isEditMode(location))
        {
            endpoint = `/customers/edit`;
        }
        else
        {
            endpoint = `/customers/create`;
        }

        try {
            const response = await axiosPrivate.post(endpoint,
                JSON.stringify(newWorkCycleViewModel),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );

            setNewWorkCycleViewModel({ ...newWorkCycleViewModel, id: response.data });

            setModalMessage("Operazione avvenuta con successo");
            setLoadingSave(false);
            setSaveInProgress(false);
            setSaveResult(true);
            setModalShow(true);
        }
        catch (err) {
            console.error(err);
            setSaveResult(false);
            setModalMessage(`${err?.response?.data?.message}`);
            setLoadingSave(false);
            setSaveInProgress(false);
            setModalShow(true);
        }
    }

    const setPropertyValue = (propertyCode, value, event) => {

        if (propertyCode == "dataOrdine" || propertyCode == "dataConsegna" || event.target.validity.valid) {
            var wc = { ...newWorkCycleViewModel };

            wc[propertyCode].value = value;
            wc[propertyCode] = validateProperty(wc[propertyCode]);

            setNewWorkCycleViewModel(wc);
        }
    }

    const setLoadingSave = (isLoading) => {
        if (isLoading) {
            setShowIndicatorProgress('on');
        }
        else {
            setShowIndicatorProgress('off');
        }
    }

    const deleteEntity = () => 
    {
        if(deleteInProgress)
        {
            return;
        }

        setDeleteConfirmationModalShow(true);
    }

    const undo = () =>
    {
        navigate(-1);
    }

    const confirmDeleteEntity = async () => {

        setDeleteConfirmationModalShow(false);

        //Se c'è un salvataggio in corso non possono essere inoltraTe ulteriori richieste
        if(deleteInProgress)
        {
            return;
        }

        setLoadingSave(true);
        setDeleteInProgress(true);
        setOperationInProgress("delete");

        //Impostazione dell'id macchina per cui creare il ciclo di lavoro
        
        try 
        {
            const response = await axiosPrivate.get(`/customers/deleteEntity/${accountId}`, 
                {
                    headers: {'Content-Type':'application/json'},
                    withCredentials: true,
                }
            );

            setModalMessage("Operazione avvenuta con successo");
            setLoadingSave(false);
            setDeleteInProgress(false);
            setSaveResult(true);
            setModalShow(true);
        }
        catch(err) 
        {
            console.error(err);
            setSaveResult(false);
            setModalMessage(`${err?.response?.data?.message}`);
            setLoadingSave(false);
            setDeleteInProgress(false);
            setModalShow(true);
            setOperationInProgress("");
        }           
    }    

    //Setting Page BreadCrumb
    useEffect(() => {

        setPageTitle("Anagrafiche");
        
        var breadcrumb = `Nuovo ${entityName}`;
        
        if(isEditMode(location))
        {
            breadcrumb = `Modifica ${entityName} N. ${accountId}`;
        }
        else if(isViewMode(location))
        {
            breadcrumb = `Dettaglio ${entityName} N. ${accountId}`;
        }

        setPageBreadcrumbs([entitiesName, breadcrumb]);
    }, [accountId, handlerMode])

    useEffect(() => {

        setIsLoading(true);

        let isMounted = true;
        const controller = new AbortController();

        const getNewWorkCycleModel = async () => {

            try {

                const response = await axiosPrivate.get(`/customers/getNewCustomerModel/${accountId}`, 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,                    
                    signal: controller.signal
                });

                if (isMounted) {

                    response.data.createdBy = auth.userId;
                    response.data.userType = userType;

                    // if(response.data.dataOrdine.value != "")
                    // {
                    //     response.data.dataOrdine.value = new Date(response.data.dataOrdine.value);
                    // }

                    //Validazione iniziale
                    validateWorkCycle(response.data);

                    setNewWorkCycleViewModel(response.data);

                    setIsLoading(false);
                    setIsError(false);
                }
            }
            catch (err) {
                console.error(err);
                setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
                setIsLoading(false);
                setIsError(true);
            }
        }

        getNewWorkCycleModel();

    }, [accountId, handlerMode])


    const handleSubmit = (e) => {

        e.preventDefault();

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if (checkValidationErrors(newWorkCycleViewModel) == true) {
            return;
        }

        setConfirmationModalShow(true);
    }

    return (
        <>
            <div id="kt_content_container" className="mx-5">

                {/* begin::Spinner */}
                {isLoading &&
                    <div className="row d-flex justify-content-center m-20">
                        <Spinner animation="border" variant="primary" />
                    </div>
                }
                {/* end::Spinner */}

                {/* begin::Error message */}
                {!isLoading && isError &&
                    <PageErrorMessage message={errorMessage}/>
                }
                {/* end::Error message */}

                {!isLoading && !isError &&

                    <form
                        className="form w-100"
                        noValidate="novalidate"
                        id="kt_sign_in_form"
                        onSubmit={handleSubmit}>

                        <div className='card mb-5 mb-xl-8 mt-5'>

                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>
                                        {isEditMode(location) && <>Modifica {entityName} - Intestazione</>}
                                        {isViewMode(location) && <>Dettaglio {entityName} - Intestazione</>}
                                        {isCreateMode(location) && <>Nuovo {entityName} - Intestazione</>}
                                    </span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>

                                <div className="row">

                                    {/* <div className="mb-10 col-12 col-md-6">
                                        <FormInputString
                                            label="Codice2"
                                            entityPropertyModel={newWorkCycleViewModel?.codice}
                                            propertyName="codice"
                                            isDisabled={isViewMode(location)}
                                            setPropertyValue={setPropertyValue} />
                                    </div> */}

                                    <div className="mb-10 col-12 col-md-2">
                                        <label className="form-label">Codice</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.codice.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.codice.value}
                                            title={newWorkCycleViewModel?.codice.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('codice', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-5">
                                        <label className="form-label">Ragione sociale</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.ragioneSociale.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.ragioneSociale.value}
                                            title={newWorkCycleViewModel?.ragioneSociale.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('ragioneSociale', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>
                                    
                                    <div className="mb-10 col-12 col-md-5">
                                        <label className="form-label">Partita Iva</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.partitaIva.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.partitaIva.value}
                                            title={newWorkCycleViewModel?.partitaIva.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('partitaIva', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>                                    
                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Indirizzo</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.indirizzo.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.indirizzo.value}
                                            title={newWorkCycleViewModel?.indirizzo.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('indirizzo', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div> 

                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">CAP</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.cap.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.cap.value}
                                            title={newWorkCycleViewModel?.cap.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('cap', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Città</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.citta.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.citta.value}
                                            title={newWorkCycleViewModel?.citta.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('citta', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>       

                                    <div className="mb-10 col-12 col-md-6">
                                        <label className="form-label">Email</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.email.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.email.value}
                                            title={newWorkCycleViewModel?.email.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('email', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>    

                                    <div className="mb-10 col-12 col-md-6">
                                        <label className="form-label">Contatto telefonico</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.telephoneNumber.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.telephoneNumber.value}
                                            title={newWorkCycleViewModel?.telephoneNumber.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('telephoneNumber', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>                                                                                                        
                                </div>
                            </div>

                        </div>


                        <div className="text-center">

                            {isViewMode(location) && <>

                                <a className="btn btn-lg btn-primary w-25 m-5" onClick={(e) => navigate(`${navPrefix}/account/${accountType}/list`)} >
                                    Elenco
                                </a>

                                <Link to={`${navPrefix}/account/${accountType}/edit/${accountId}`} className='btn btn-lg btn-primary w-25 m-5'>
                                    Modifica
                                </Link>

                                <div className='btn btn-lg btn-danger w-25 m-5' onClick={(e) => deleteEntity()} data-kt-indicator={deleteInProgress ? "on": "off"}>
                                        <span className="indicator-label" >Elimina</span>
                                        <span className="indicator-progress" > Elimina
                                            <span className="spinner-border spinner-border-sm align-middle ms-2">
                                        </span>
                                    </span>
                                </div>  
                            </>
                            }

                            {!isViewMode(location) && <>

                                <a className="btn btn-lg btn-primary w-25 m-5" onClick={(e) => undo()} >
                                    Annulla
                                </a>

                                <button
                                    type="submit"
                                    id="kt_sign_in_submit"
                                    className="btn btn-lg btn-primary w-25 m-5"
                                    data-kt-indicator={showIndicatorProgress}>
                                    <span className="indicator-label">Salva</span>
                                    <span className="indicator-progress" >Caricamento...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </>
                            }
                        </div>

                    </form>
                }
            </div>

            <ConfirmationModal 
                show={deleteConfirmationModalShow} 
                onHide={handleDeleteConfirmationClose} 
                onConfirm={confirmDeleteEntity} />

            <ConfirmationModal 
                show={confirmationModalShow} 
                onHide={handleConfirmationClose} 
                onConfirm={confirmCreate} />

            <ResultModal 
                show={modalShow} 
                onHide={handleClose} 
                result={saveResult}
                message={modalMessage}/> 
        </>

    )
}

export default AccountHandler