import { useNavigate, Link } from "react-router-dom";
import {Outlet, useParams} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../../_metronic/helpers'
import clsx from 'clsx'

const ProdOrderNavigation = () => 
{
    const {pathname} = useLocation()
    
    const isArchiveActive = checkIsTabArchiveActive(pathname);
    const isArticlesActive = checkIsTabArticlesActive(pathname);
    const isColorsActive = checkIsTabColorsActive(pathname);
    
    return (
        <>
            {/* <!--begin::Container--> */}
                <div 
                className="container-fluid d-flex align-items-center bg-white mytoolbar">
                
                    {/* <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"> */}
                                
                        <div className="d-flex overflow-auto h-55px">
                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                                <li className="nav-item"><Link to={`/productionOrders`} className={clsx('nav-link text-active-primary me-6', { active: isArchiveActive})}>Archivio</Link></li>
                                {/* <li className="nav-item"><Link to={`/productionArticles`} className={clsx('nav-link text-active-primary me-6', { active: isArticlesActive})}>Articoli</Link></li>
                                <li className="nav-item"><Link to={`/productionColors`} className={clsx('nav-link text-active-primary me-6', { active: isColorsActive})}>Colori</Link></li> */}
                            </ul>
                        </div>                                    

                    {/* </div> */}
                </div>
            {/* <!--end::Container--> */}
        <Outlet/>
        </>
    )
}


  function checkIsTabT2Active(pathname) 
  {
    if (!pathname) {
      return false;
    }

    if(pathname.indexOf("t2s") > -1)
    {
        return true;
    }
  
    return false;
  }

  function checkIsTabArticlesActive(pathname) {
    if (!pathname) {
      return false;
    }

    if(pathname.indexOf("productionArticles") > -1)
    {
        return true;
    }
  
    return false;
  }

  function checkIsTabColorsActive(pathname) {
    if (!pathname) {
      return false;
    }

    if(pathname.indexOf("productionColors") > -1)
    {
        return true;
    }
  
    return false;
  }


  function checkIsTabArchiveActive(pathname) {
    if (!pathname) {
      return false;
    }

    if(pathname.indexOf("productionOrders") > -1)
    {
        return true;
    }
  
    return false;
  }


export default ProdOrderNavigation