import { useNavigate, Link, useLocation, Outlet, useParams } from "react-router-dom";
import { useState, useEffect, useMemo } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { usePageData } from '../../../_metronic/layout/core';
import useAuth from "../../../hooks/useAuth";
import DatePicker, {registerLocale} from 'react-datepicker';
import Select from 'react-select';
import clsx from 'clsx'
import DataTable from 'react-data-table-component';

import { isCreateMode, isEditMode, isViewMode, validateProperty, validateEntity, checkValidationErrors, GetHandlerPayload, normalizeEntity, downloadPdf } from "./InventoryBatchesHelper";

import {ConfirmationModal, ResultModal} from "./Modals";
import {PageErrorMessage, FormInputString} from "./Components";

const InventoryBatchesHandler = () => {    
    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [isError, setIsError] = useState();
    const [isLoading, setIsLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [showIndicatorProgress, setShowIndicatorProgress] = useState('');
    const [modalShow, setModalShow] = useState();
    const [modalMessage, setModalMessage] = useState();
    const [saveInProgress, setSaveInProgress] = useState();
    const [deleteInProgress, setDeleteInProgress] = useState();
    const [saveResult, setSaveResult] = useState();
    const [newEntityViewModel, setNewEntityViewModel] = useState();
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const [deleteConfirmationModalShow, setDeleteConfirmationModalShow] = useState(false);
    const [operationInProgress, setOperationInProgress] = useState("");
    const [isPdfInProgress, setIsPdfInProgress] = useState(false);

    const [clearSelectedRows, setClearSelectedRows] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState();
    const [rowId, setRowId] = useState(0);

    var forseUpdate = new Date();
    
    let { entityId } = useParams();
    let { handlerMode } = useParams();
    let { inventoryId } = useParams();
    
    let navPrefix = "/inventory";
    let entityName = "Lotto";
    let entitiesName = "Lotti";
    let entityType = "leather";

    const { setPageTitle, pageMachines } = usePageData();
    const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();


    const handleClose = () => 
    {
        if (saveResult == true) 
        {
            if(operationInProgress == "delete")
            {
                navigate(`${navPrefix}/leather/movements`);
            }
            else
            {
                navigate(`${navPrefix}/leather/batches/view/${newEntityViewModel.id}`);
            }
        }

        setModalShow(false);
    }

    const setRowPropertyValue = (row, propertyCode, value, event) => {

        var wc = { ...newEntityViewModel };

        wc.batchPallets.forEach(function(tableRow) {

            if(tableRow.id == row.id)
            {  
                tableRow[propertyCode].value = value;
            }
        }); 
        
        setNewEntityViewModel(wc);

    } 

    const columns = [
        {
          name: 'Numero',
          selector: row => row.number,
          sortable: false,    
          sortFieldId:1,
          sortField:'Name',
          cell: (row, index, column, id) => {
            return (
                <input type="text"
                    className={clsx('form-control', row?.number.isError ? 'bg-light-danger border-danger' : '')}
                    placeholder=""
                    value={row?.number.value}
                    autoComplete="off"
                    onChange={(e) => setRowPropertyValue(row, 'number', e.target.value, e)}
                    {...(isViewMode(location)) && {disabled: true}}
                />)       
          }
        }, 
        {
          name: 'Scelta',
          selector: row => row.selection,
          sortable: false,
          cell: (row, index, column, id) => {
            return (
                <input type="text"
                    className={clsx('form-control', row?.selection.isError ? 'bg-light-danger border-danger' : '')}
                    placeholder=""
                    value={row?.selection.value}
                    autoComplete="off"
                    onChange={(e) => setRowPropertyValue(row, 'selection', e.target.value, e)}
                    {...(isViewMode(location)) && {disabled: true}}
                />)          
          }
        }, 
        {
            name: 'Taglia',
            selector: row => row.size,
            sortable: false,
            cell: (row, index, column, id) => {
              return (
                  <input type="text"
                      className={clsx('form-control', row?.size.isError ? 'bg-light-danger border-danger' : '')}
                      placeholder=""
                      value={row?.size.value}
                      autoComplete="off"
                      onChange={(e) => setRowPropertyValue(row, 'size', e.target.value, e)}
                      {...(isViewMode(location)) && {disabled: true}}
                  />)          
            }
          }, 
        {
          name: 'Quantità',
          selector: row => row.quantity,
          sortable: false,
          cell: (row, index, column, id) => {
            return (
                <input type="text"
                    className={clsx('form-control', row?.quantity.isError ? 'bg-light-danger border-danger' : '')}
                    placeholder=""
                    value={row?.quantity.value}
                    autoComplete="off"
                    onChange={(e) => setRowPropertyValue(row, 'quantity', e.target.value, e)}
                    {...(isViewMode(location)) && {disabled: true}}
                />)          
          }
        },
        {
            name: '',
            width: '50px',
            cell: (row, index, column, id) => {
              return (
                <>
                {isViewMode(location) && 
                    <div className='row'>

                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                       onClick={(e) => onDownloadRowPdf(row.id)}
                        >
                        <i className="far fa-file-alt"></i>
                    </div>

              </div>
                }
               </>)
            }
          }
      ];

    const handleConfirmationClose = () => {
        setConfirmationModalShow(false);
    }

    const handleDeleteConfirmationClose = () => {
        setDeleteConfirmationModalShow(false);
    }

    const confirmCreate = async () => 
    {
        setConfirmationModalShow(false);

        //Se c'è un salvataggio in corso non possono essere inoltrare ulteriori richieste
        if (saveInProgress) {
            return;
        }

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if (checkValidationErrors(newEntityViewModel) == true) {
            return;
        }

        setLoadingSave(true);
        setSaveInProgress(true);

        //Impostazione dell'id macchina per cui creare il ciclo di lavoro

        var endpoint = "";

        if(isEditMode(location))
        {
            endpoint = `/inventoryBatch/editEntity`;
        }
        else
        {
            endpoint = `/inventoryBatch/createEntity`;
        }

        let isMounted = true;
        const controller = new AbortController();

        const sendRequest = async () =>
        {
            try 
            {
                var payload = GetHandlerPayload(newEntityViewModel);

                const response = await axiosPrivate.post(endpoint,
                    payload,
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,
                        signal:controller.signal
                    }
                );
    
                if (isMounted) {
                    setNewEntityViewModel({ ...newEntityViewModel, id: response.data });
    
                    setModalMessage("Operazione avvenuta con successo");
                    setLoadingSave(false);
                    setSaveInProgress(false);
                    setSaveResult(true);
                    setModalShow(true);
                }
            }
            catch (err) 
            {
                console.error(err);
                setSaveResult(false);
                setModalMessage(`${err?.response?.data?.message}`);
                setLoadingSave(false);
                setSaveInProgress(false);
                setModalShow(true);
            }
        }

        sendRequest();

        return () => {
            isMounted = false;
            controller.abort();
          }
    }

    const setPropertyValue = (propertyCode, value, event) => {

        if (propertyCode == "date" || propertyCode == "supplierId" || propertyCode == "articleId" || event.target.validity.valid) {
            var wc = { ...newEntityViewModel };

            wc[propertyCode].value = value;
            wc[propertyCode] = validateProperty(wc[propertyCode]);

            setNewEntityViewModel(wc);
        }
    }

    const setLoadingSave = (isLoading) => {
        if (isLoading) {
            setShowIndicatorProgress('on');
        }
        else {
            setShowIndicatorProgress('off');
        }
    }

    const deleteEntity = () => 
    {
        if(deleteInProgress)
        {
            return;
        }

        setDeleteConfirmationModalShow(true);
    }

    const undo = () =>
    {
        navigate(-1);
    }

    const confirmDeleteEntity = async () => {

        setDeleteConfirmationModalShow(false);

        //Se c'è un salvataggio in corso non possono essere inoltraTe ulteriori richieste
        if(deleteInProgress)
        {
            return;
        }

        setLoadingSave(true);
        setDeleteInProgress(true);
        setOperationInProgress("delete");

        let isMounted = true;
        const controller = new AbortController();

        const sendRequest = async () =>
        {
                //Impostazione dell'id macchina per cui creare il ciclo di lavoro
                        
                try 
                {
                    const response = await axiosPrivate.get(`/inventoryBatch/deleteEntity/${entityId}`, 
                        {
                            headers: {'Content-Type':'application/json'},
                            withCredentials: true,
                        }
                    );

                    if (isMounted) 
                    {
                        setModalMessage("Operazione avvenuta con successo");
                        setLoadingSave(false);
                        setDeleteInProgress(false);
                        setSaveResult(true);
                        setModalShow(true);
                    }
                }
                catch(err) 
                {
                    console.error(err);
                    setSaveResult(false);
                    setModalMessage(`${err?.response?.data?.message}`);
                    setLoadingSave(false);
                    setDeleteInProgress(false);
                    setModalShow(true);
                    setOperationInProgress("");
                }    
        }

        sendRequest();

        return () => {
            isMounted = false;
            controller.abort();
          }
    }    

    //Setting Page BreadCrumb
    useEffect(() => {

        setPageTitle(entitiesName);
        
        var breadcrumb = `Nuovo ${entityName}`;
        
        if(isEditMode(location))
        {
            breadcrumb = `Modifica ${entityName} N. ${entityId}`;
        }
        else if(isViewMode(location))
        {
            breadcrumb = `Dettaglio ${entityName} N. ${entityId}`;
        }

        setPageBreadcrumbs([breadcrumb]);
    }, [entityId, handlerMode])

    useEffect(() => {

        setIsLoading(true);

        let isMounted = true;
        const controller = new AbortController();

        const getEntityModel = async () => {

            try 
            {
                const response = await axiosPrivate.get(`/inventoryBatch/getEntityModel/${entityId}/${entityType}/load`, 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,                    
                    signal: controller.signal
                });

                if (isMounted) {

                    // //Trasformazione delle liste per renderle compatibili con le relative select
                    // response.data.articles = response.data.articles.reduce((res, item) => {
                    //     res.push({ value: item.key, label: item.value});
                    //     return res;
                    // }, []);
            
                    // response.data.suppliers = response.data.suppliers.reduce((res, item) => {
                    //     res.push({ value: item.key, label: item.value});
                    //     return res;
                    // }, []);


                    response.data = normalizeEntity(response.data);

                    //Validazione iniziale
                    validateEntity(response.data);

                    setNewEntityViewModel(response.data);

                    setIsLoading(false);
                    setIsError(false);
                }
            }
            catch (err) {
                console.error(err);
                setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
                setIsLoading(false);
                setIsError(true);
            }
        }

        getEntityModel();

        return () => {
            isMounted = false;
            controller.abort();
          }

    }, [entityId, handlerMode])

    const onSelectedRowsChange = (state) => {

        var selectedRowId = -1;
    
        state.selectedRows.forEach(function (tableRow) {
            selectedRowId = tableRow.id;
        });

        setSelectedRowId(selectedRowId);
      }; 

      const addElement = () => {
        var wc = { ...newEntityViewModel };

        let newRow = getNewEmptyRow(wc);

        if(selectedRowId >= 0)
        {
            var index = 0;
            var selectedIndex = 0;

            wc.batchPallets.forEach(function(tableRow) {
        
                if(tableRow.id == selectedRowId)
                {  
                    selectedIndex = index;
                }

                index++;
            });

            wc.batchPallets.splice(selectedIndex+1, 0, newRow);

            clearSelection();
        }
        else
        {
            wc.batchPallets.push(newRow);
        }

        //Ricerca della riga selezionata
        setNewEntityViewModel(wc);
    }

    //Rimuove il box selezionato
    const removeElement = () => {

        if(selectedRowId >= 0)
        {
            var wc = { ...newEntityViewModel };

            var index = 0;
            var selectedIndex = 0;

            wc.batchPallets.forEach(function(tableRow) {
        
                if(tableRow.id == selectedRowId)
                {  
                    selectedIndex = index;
                }

                index++;
            });

            wc.batchPallets.splice(selectedIndex, 1);

            clearSelection();
        }        
    }

    const clearSelection = (wc) => {
        setClearSelectedRows(!clearSelectedRows);
        setSelectedRowId(-1);
    }

    const getNewEmptyRow = (wc) => {

        let output = JSON.parse(JSON.stringify(wc.emptyBatchPalletViewModel));
        output.id = rowId;
        setRowId(output.id+1);

        return output;
    }


    const handleSubmit = (e) => {

        e.preventDefault();

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if (checkValidationErrors(newEntityViewModel) == true) {
            return;
        }

        setConfirmationModalShow(true);
    }

    const onDownloadPdf = () => 
    {
        downloadPdf(entityId, 0, axiosPrivate, isPdfInProgress, setIsPdfInProgress, setModalMessage, setSaveResult, setModalShow);
    }

    const onDownloadRowPdf = (rowId) => 
        {
            downloadPdf(entityId, rowId, axiosPrivate, isPdfInProgress, setIsPdfInProgress, setModalMessage, setSaveResult, setModalShow);
        }
    

    return (
        <>
            <div id="kt_content_container" className="mx-5">

                {/* begin::Spinner */}
                {isLoading &&
                    <div className="row d-flex justify-content-center m-20">
                        <Spinner animation="border" variant="primary" />
                    </div>
                }
                {/* end::Spinner */}

                {/* begin::Error message */}
                {!isLoading && isError &&
                    <PageErrorMessage message={errorMessage}/>
                }
                {/* end::Error message */}

                {!isLoading && !isError &&

                    <form
                        className="form w-100"
                        noValidate="novalidate"
                        id="kt_sign_in_form"
                        onSubmit={handleSubmit}>

                        <div className='card mb-5 mb-xl-8 mt-5'>

                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>
                                        {isEditMode(location) && <>Modifica {entityName}</>}
                                        {isViewMode(location) && <>Dettaglio {entityName}</>}
                                        {isCreateMode(location) && <>Nuovo {entityName}</>}
                                    </span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>

                                <div className="row">

                                <div className="mb-10 col-6 col-md-6">
                                    <label className="form-label">Consegna</label>

                                    <div className="d-table w-100">
                                            <div className="table-row">
                                                <div className="d-table-cell w-100">
                                                    <DatePicker
                                                        placeholderText="Scegli Data"
                                                        locale="it"
                                                        className={clsx('form-control form-control-solid', newEntityViewModel?.date.isError ? 'bg-light-danger border-danger' : '')}

                                                        name="date"
                                                        dateFormat="dd/MM/yyyy HH:mm"
                                                        autoComplete="off"
                                                        showTimeSelect
                                                        timeFormat="p"
                                                        timeIntervals={15}

                                                        selected={newEntityViewModel?.date.value}
                                                        onChange={(date) => setPropertyValue('date', date, null)}
                                                        {...(isViewMode(location)) && {disabled: true}}
                                                    />
                                                    
                                                </div>
                                                
                                                <div className="d-table-cell">
                                                    <div className="btn btn-secondary" disabled onClick={(e) => { if(!isViewMode(location)) {setPropertyValue('date', new Date(), null) }}}>
                                                        <i className="fas fa-clock p-0"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>

                                    <div className="mb-10 col-12 col-md-6">
                                        <label className="form-label">Codice Lotto</label>
                                        <input type="text"
                                            className={clsx('form-control', newEntityViewModel?.batchNumber.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newEntityViewModel?.batchNumber.value}
                                            title={newEntityViewModel?.batchNumber.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('batchNumber', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Fornitore</label>
                                        <Select
                                            isDisabled={isViewMode(location)}
                                            isClearable={true}
                                            classNames={{
                                                control: (state) =>
                                                newEntityViewModel?.supplierId.isError  ? 'bg-light-danger border-danger' : '',
                                              }}
                                            options={newEntityViewModel?.suppliers}
                                            isSearchable={true}
                                            value={newEntityViewModel?.supplierId.value}
                                            onChange={(selectedOption) => setPropertyValue('supplierId', selectedOption)}
                                        /> 
                                    </div>

                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Merce</label>
                                        <Select
                                            isDisabled={isViewMode(location)}
                                            isClearable={true}
                                            classNames={{
                                                control: (state) =>
                                                newEntityViewModel?.articleId.isError  ? 'bg-light-danger border-danger' : '',
                                              }}                                            
                                            options={newEntityViewModel?.articles}
                                            isSearchable={true}
                                            value={newEntityViewModel?.articleId.value}
                                            onChange={(selectedOption) => setPropertyValue('articleId', selectedOption)}
                                        /> 
                                    </div>

                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Quantità</label>

                                        <div className="input-group mb-3">
                                            <select 
                                                className={clsx('form-select', newEntityViewModel?.quantityUM.isError ? 'bg-light-danger border-danger' : '')}
                                                value={newEntityViewModel?.quantityUM.value}
                                                onChange={(e) => setPropertyValue('quantityUM', e.target.value, e)}
                                                {...(isViewMode(location)) && {disabled: true}}>
                                                <option value="">Scegli</option>
                                                <option value="NR">NR</option>
                                                <option value="MQ">MQ</option>
                                            </select>

                                            <input type="text"
                                            className={clsx('form-control', newEntityViewModel?.quantity.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            pattern="[0-9]*"
                                            value={newEntityViewModel?.quantity.value}
                                            title={newEntityViewModel?.quantity.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('quantity', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />

                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>

                        <div className='card mb-5 mb-xl-8 mt-5'>

<div className='card-header border-0 pt-5'>
    <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bolder fs-3 mb-1'>Elenco Pallet</span>
    </h3>

    <div className="card-toolbar">

        {!isViewMode(location) && <>
            <a className="btn btn-success  m-1" onClick={(e) => addElement()}>
                <span className="svg-icon svg-icon-1">
                    <i className="fas fa-solid fa-plus"></i>
                </span>Aggiungi
            </a>

            <a className="btn btn-danger m-1" onClick={(e) => removeElement()}>
                <span className="svg-icon svg-icon-1">
                    <i className="fas fa-solid fa-trash"></i>
                </span>Rimuovi
            </a>  
        </>}
    </div>
</div>

<div className='card-body py-3'>

    <div className="row">
        <DataTable
            columns={columns}
            data={newEntityViewModel?.batchPallets}
            highlightOnHover
            
            selectableRows
            selectableRowDisabled={(row) => {return isViewMode(location);}}
            selectableRowsHighlight
            selectableRowsSingle
            clearSelectedRows = {clearSelectedRows}
            onSelectedRowsChange={onSelectedRowsChange}
            />
    </div>
</div>

</div>

                        <div className='card mb-5 mb-xl-8 mt-5'>
                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Note</span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>
                                <div className='row mb-5'>
                                {/* <label className="form-label">Note</label> */}
                                        <textarea
                                            className={clsx('form-control', newEntityViewModel?.notes?.isError ? 'bg-light-danger border-danger' : '')} 
                                            placeholder="" 
                                            value={newEntityViewModel?.notes?.value} 
                                            title={newEntityViewModel?.notes?.ErrorMessage} 
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue("notes", e.target.value, e)}
                                            rows="5"
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />                                  
                                </div>
                            </div>
                        </div>

                        <div className="text-center">

                            {isViewMode(location) && <>

                                <Link to={`${navPrefix}/leather/batches`} className='btn btn-lg btn-primary w-25 m-5'>
                                    Elenco
                                </Link>

                                <Link to={`${navPrefix}/leather/batches/edit/${entityId}`} className='btn btn-lg btn-primary w-25 m-5'>
                                    Modifica
                                </Link>

                                <div
                                    className='btn btn-lg btn-primary w-25 m-5'
                                    onClick={(e) => onDownloadPdf()}
                                    data-kt-indicator={isPdfInProgress == true ? 'on' : 'off'}>

                                    <span className="svg-icon svg-icon-1">
                                        <i className="fas fa-file-pdf"></i>
                                    </span>

                                    <span className="indicator-label" >Scarica PDF</span>
                                    <span className="indicator-progress" > Scarica PDF
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </div>

                                <div className='btn btn-lg btn-danger w-25 m-5' onClick={(e) => deleteEntity()} data-kt-indicator={deleteInProgress ? "on": "off"}>
                                        <span className="indicator-label" >Elimina</span>
                                        <span className="indicator-progress" > Elimina
                                            <span className="spinner-border spinner-border-sm align-middle ms-2">
                                        </span>
                                    </span>
                                </div>  
                            </>
                            }

                            {!isViewMode(location) && <>

                                <a className="btn btn-lg btn-primary w-25 m-5" onClick={(e) => undo()} >
                                    Annulla
                                </a>

                                <button
                                    type="submit"
                                    id="kt_sign_in_submit"
                                    className="btn btn-lg btn-primary w-25 m-5"
                                    data-kt-indicator={showIndicatorProgress}>
                                    <span className="indicator-label">Salva</span>
                                    <span className="indicator-progress" >Caricamento...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </>
                            }
                        </div>

                    </form>
                }
            </div>

            <ConfirmationModal 
                show={deleteConfirmationModalShow} 
                onHide={handleDeleteConfirmationClose} 
                onConfirm={confirmDeleteEntity} />

            <ConfirmationModal 
                show={confirmationModalShow} 
                onHide={handleConfirmationClose} 
                onConfirm={confirmCreate} />

            <ResultModal 
                show={modalShow} 
                onHide={handleClose} 
                result={saveResult}
                message={modalMessage}/> 
        </>

    )
}

export default InventoryBatchesHandler