import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { KTSVG } from '../../../_metronic/helpers'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { usePageData } from '../../../_metronic/layout/core';
import { Spinner, Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DatePicker, {registerLocale} from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it"; // the locale you want
import {PageErrorMessage, GetGridColumns, GetInventoryElements} from "./Components";
import Select from 'react-select';

registerLocale("it", it); // register it with the name you want

const InventoryBatches = () => {
  const [entities, setEntities] = useState();

  let navPrefix = "inventory";
  let entityType = "leather";

  //const [modalShow, setModalShow] = useState();  
  //const [modalMessage, setModalMessage] = useState();  

  const [isError, setIsError] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  let { idMacchina } = useParams();

  const { setPageTitle, pageMachines } = usePageData();
  const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();
  const [machineCode, setMachineCode] = useState();

  const [modalFilterShow, setModalFilterShow] = useState(false);  

  const [tempFilter, setTempFilter]= useState();
  const [showExportProgress, setShowExportProgress] = useState('off');

  let emptyFilter = {
    batchNumber:'',
    articleId:null,
    supplierId:null,
    startDate:'',
    endDate:'',
    sortField:'Date',
    sortDirection:'desc'
  };

  const [filter, setFilter] = useState(emptyFilter);    

  //Pagination Handling
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  //Sorting handling
  const [defaultSortAsc, setDefaultSortAsc] = useState(false);
  const [defaultSortFieldId, setDefaultSortFieldId] = useState(1);  

  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);

  const [modalShow, setModalShow] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [saveInProgress, setSaveInProgress] = useState();
  const [showIndicatorProgress, setShowIndicatorProgress] = useState('');
  const [saveResult, setSaveResult] = useState();

  const [articleOptions, setArticleOptions] = useState([]);
  const [supplierOptions, setSupplierptions] = useState([]);

  const paginationComponentOptions = {
    rowsPerPageText: 'Elementi per pagina',
    rangeSeparatorText: 'di',
    selectAllRowsItem: false
  };

  const columns = GetGridColumns(navPrefix)

  //Setting Page BreadCrumb and getting inventory informations (eg. articles for filtering)
  useEffect(async () => {

    setPageTitle("Magazzino Pelli");
    setPageBreadcrumbs(["Movimenti"]);

    let elements = await GetInventoryElements(axiosPrivate, entityType);

    setArticleOptions(elements.articles);
    setSupplierptions(elements.suppliers);

  }, [])

  useEffect(() => {

    getEntityList(1, perPage, filter);

  }, [])


  const handleSort = async (column, sortDirection) => {

    if(sortDirection == "asc" || sortDirection == "")
    {
      setDefaultSortAsc(true);
    }
    else
    {
      setDefaultSortAsc(false);
    }

    setDefaultSortFieldId(column.sortFieldId);

    let sortedFilter = {...filter, sortField:column.sortField, sortDirection, sortDirection};
    setFilter(sortedFilter);

    getEntityList(1, perPage, sortedFilter);
  };

  const handleFilterClose = () => {
    
    //Se la finestra viene chiusa senza ne applicare ne resettare il filtro, allora i filtri impostati dal momento dell'apertura 
    //della finestra del filtro vengono persi e reimpostati quelli precedentemente applicati
    setFilter(tempFilter);
 
   setModalFilterShow(false);
  }    

  const showFilterModal = () => {

    //Salvataggio in memoria del filtro attuale. 
    //Questo viene fatto perchè se non viene applicato o resettato, il filtro viene reimpostato
    //A quello al momento dell'apertura della finestra

    setTempFilter(filter);

    setModalFilterShow(true);
   }  

   const resetFilter = () => {

    setFilter(emptyFilter);
    getEntityList(1, perPage, emptyFilter);

    setModalFilterShow(false);
   }       

   const applyFilter = () => {
    getEntityList(1, perPage, filter);
    setModalFilterShow(false);
  }  

   const setFilterProperty = (attribute, value, event) => 
   {
    console.log("setFilterProperty",attribute, value)

      var tempFilter = {...filter};

      tempFilter[attribute] = value;

      setFilter(tempFilter);
   }

   const getEntityListRequest = (page, pageSize, pageFilter)=> {

    var articleId = '';
    if(pageFilter.articleId != null)
    {
      articleId = pageFilter.articleId.value;
    }

    var supplierId = '';
    if(pageFilter.supplierId != null)
    {
      supplierId = pageFilter.supplierId.value;
    }    

    let entityListRequest =
    {
      filterBatchNumber: pageFilter.batchNumber,
      filterArticleId: articleId,
      filterSupplierId: supplierId,
      filterStartDate: pageFilter.startDate,
      filterEndDate: pageFilter.endDate,

      page: page,
      pageSize: pageSize,
      sortField: pageFilter.sortField,
      sortDirection: pageFilter.sortDirection
    }

    return entityListRequest;
   }

  const getEntityList = (page, pageSize, pageFilter) => {

    setIsLoading(true);

    let isMounted = true;
    const controller = new AbortController();

    let entityListRequest = getEntityListRequest(page, pageSize, pageFilter);

    const fetchWorkCycleList = async () => {

      try 
      {
        const response = await axiosPrivate.post(`/inventoryBatch/getEntities`,  
        JSON.stringify(entityListRequest),
        {
            headers: {'Content-Type':'application/json'},
            withCredentials: true,
            signal:controller.signal
        });

        if (isMounted) 
        {
          setEntities(response.data.data);
          setTotalRows(response.data.total);

          //L'elenco delle righe selezionate viene svuotato ogni volta che c'è un aggiornamento delle righe mostrate
          setSelectedRows([]); 

          setIsLoading(false);
          setIsError(false);
        }
      }
      catch (err) 
      {
        console.error(err);
        setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
        setIsLoading(false);
        setIsError(true);
      }
    }

    fetchWorkCycleList();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }

  const onFilterArticleChange = (inputValue, { action, prevInputValue }
  ) => {

    console.log("onFilterArticleChange", action, inputValue);

    // if (action === 'input-change')
    // {
    //   setFilterProperty('articleId', inputValue)
    // }
    
  };

  const handleChange = (selectedOption) => {

    var value = "";
    setFilterProperty('articleId', selectedOption)

  };

  const handlePageChange = page => {
		getEntityList(page, perPage, filter);
    setCurrentPage(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {

    getEntityList(page, newPerPage, filter);
    setPerPage(newPerPage);
	};  

  return (
    <div id="kt_content_container" className="mx-5">

      {/* begin::Spinner */}
      {isLoading &&
        <div className="row d-flex justify-content-center m-20">
          <Spinner animation="border" variant="primary" />
        </div>
      }
      {/* end::Spinner */}

      {/* begin::Error message */}
      {!isLoading && isError &&
        <PageErrorMessage message={errorMessage}/>
      }
      {/* end::Error message */}

      {/* begin::Table */}
      {!isLoading && !isError &&
        <>

          <div className='card mb-5 mt-5 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Elenco Lotti</span>
              </h3>
              <div className="card-toolbar">

                <div className='btn btn-primary me-2 mt-1' onClick={(e) => showFilterModal()}>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-search"></i>
                  </span>
                  Filtro
                </div>

                <div className='btn btn-secondary me-2 mt-1' onClick={(e) => getEntityList(1, perPage, filter)}>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-sync"></i>
                  </span>
                  Aggiorna
                </div>
                                
                <Link to={`/${navPrefix}/leather/batches/create/0`} className='btn btn-success me-2 mt-1'>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-solid fa-plus"></i>
                  </span>
                  Nuovo
                </Link>

              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>

              <div className='row mb-2'>

                <DataTable
                  columns={columns}
                  data={entities}
                  pagination
                  paginationServer

                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationDefaultPage={currentPage}
                  paginationPerPage={perPage}
                  paginationComponentOptions={paginationComponentOptions}
                  paginationRowsPerPageOptions={[10, 25, 50, 100]}

                  onSort={handleSort}
                  sortServer
                  defaultSortFieldId={defaultSortFieldId}
                  defaultSortAsc={defaultSortAsc}

                  highlightOnHover
                  striped
                />
              </div>
            </div>
          </div>
        </>
      }

      <Modal show={modalFilterShow} onHide={handleFilterClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filtri di Ricerca</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">

            <div className="row">

              <div className="mb-10 col-12">
                <label className="form-label">Numero Lotto</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="Numero Lotto"
                  autoComplete="off"
                  value={filter?.batchNumber}
                  onChange={(e) => setFilterProperty('batchNumber', e.target.value, e)}
                />
              </div>

              <div className="mb-10 col-12">
                <label className="form-label">Articolo</label>
                <Select 
                  isClearable={true}
                  options={articleOptions} 
                  isSearchable={true}
                  value={filter?.articleId}
                  onChange={(selectedOption)=> setFilterProperty('articleId', selectedOption)}
                />                
              </div>

              <div className="mb-10 col-12">
                <label className="form-label">Fornitore</label>
                <Select 
                  isClearable={true}
                  options={supplierOptions} 
                  isSearchable={true}
                  value={filter?.supplierId}
                  onChange={(selectedOption)=> setFilterProperty('supplierId', selectedOption)}
                />                
              </div>              

            </div>
            <div className="row">
              <div className="mb-10 col-6">
                <label className="form-label">Da</label>
                <DatePicker
                  placeholderText="Scegli Data"
                  locale="it"
                  className='form-control form-control-solid'
                  name="startDate"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                  selected={filter?.startDate}
                  onChange={(date) => setFilterProperty('startDate', date, null)}
                />
              </div>

              <div className="mb-10 col-6">
                <label className="form-label">A</label>
                <DatePicker
                  placeholderText="Scegli Data"
                  locale="it"
                  className='form-control form-control-solid'
                  name="endDate"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                  selected={filter?.endDate}
                  onChange={(date) => setFilterProperty('endDate', date, null)}
                />
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetFilter}>
            Reset
          </Button> 
          <Button variant="primary" onClick={applyFilter}>
            Applica
          </Button>         
        </Modal.Footer>
      </Modal>   

    </div>
  )
}

export default InventoryBatches