import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/users/refresh-token', {
            withCredentials: true
        });

        //console.log(response.data)

        setAuth(prev => {
            //(JSON.stringify(prev));
            //console.log("Refresh Token Result");
            //console.log(response.data);
            
            return { 
                ...prev, 
                roles: response.data.roles,
                accessToken: response.data.accessToken,
                username: response.data.username,
                userId: response.data.id,
                frontEndConfig: response.data.frontEndConfig
            }
        });

        return response.data.accessToken;
    }

    return refresh;
}

export default useRefreshToken;