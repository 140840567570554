/* eslint-disable react/jsx-no-target-blank */
import React, {FC} from 'react';
import { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';

//import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {usePageData} from '../../../layout/core';
import useAuth from '../../../../hooks/useAuth';
import ROLES from '../../../../components/system/Roles';

import { useModuleActive } from '../../../../hooks/useModuleActive';
import { MODULE_IDS } from '../../../../models/systems/ModuleIds';

export function AsideMenuMain() {
  //const intl = useIntl()
  const axiosPrivate = useAxiosPrivate();
  const [machines, setMachines] = useState<any[]>([]);
  const {setPageMachines} = usePageData();
  const isModuleActive = useModuleActive();

  //const [isAdmin, setIsAdmin] = useState<any[]>([]);

  const authObj:any = useAuth();

  useEffect(() => {

    let isMounted = true;
    const controller = new AbortController();

    const getMachinesList = async () => {
      try {
        const response = await axiosPrivate.get('/machines/getMenuMachines', {
          signal: controller.signal,
        });

        if (isMounted) {
          setMachines(response.data);
          setPageMachines(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    };

    getMachinesList();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate, setPageMachines]);

// ✅ Named function returning array of strings
function isAdmin(): boolean {

  let output = false;


  authObj?.auth?.roles?.forEach(function (role:any) {
    
    if(role == ROLES.Amministratore)
    {
      output = true;
    }

  });

  return output;

}


function isSupervisor(): boolean {

  let output = false;


  authObj?.auth?.roles?.forEach(function (role:any) {
    
    if(role == ROLES.Supervisore)
    {
      output = true;
    }

  });

  return output;

}


  return (
    <>   

    <AsideMenuItem to='/home' title='Home' fontIcon='bi-house' isFirstLevel={true}/> 

      {/* <AsideMenuItemWithSub
        to='/'
        title='Dashboard'
        fontIcon='bi-house'
        isFirstLevel={true}
      >
        <AsideMenuItem to='/multipurpose' title='MultiPurpose' hasBullet={true} />   
        <AsideMenuItem to='/ecommerce' title='Ecommerce' hasBullet={true} />   
        <AsideMenuItem to='/projects' title='Projects' hasBullet={true} />   
      </AsideMenuItemWithSub> */}      

  {isModuleActive(MODULE_IDS.PRODUCTION) && <>
  <AsideMenuItem to='/produzione' title='Produzione' fontIcon='bi bi-list-check' isFirstLevel={true}>
  </AsideMenuItem>  
  </>}

      {isModuleActive(MODULE_IDS.PRODUCTION_ORDER) && <>

      {(isAdmin() || isSupervisor()) && <>
        <AsideMenuItem to='/productionOrders' title='Cartellini' fontIcon='bi bi-list-check' isFirstLevel={true}>
        </AsideMenuItem>  
      </>}  

      <AsideMenuItem to='/units' title='Reparti' fontIcon='bi bi-list-check' isFirstLevel={true}>
      </AsideMenuItem>      
      
      </>
      }

      {isModuleActive(MODULE_IDS.INVENTORY) && <>

        {(isAdmin() || isSupervisor()) && <>

          <AsideMenuItemWithSub to='/inventory' title='Magazzino Pelli' fontIcon='fa fa-solid fa-warehouse' isFirstLevel={true}>
            <AsideMenuItem to='/inventory/0/movements ' title='Magazzino Piclato' hasBullet={true} />
            <AsideMenuItem to='/inventory/2/movements ' title='Magazzino WB' hasBullet={true} />
            <AsideMenuItem to='/inventory/1/movements ' title='Magazzino Crust' hasBullet={true} />
            <AsideMenuItem to='/inventory/3/movements ' title='Magazzino Finito' hasBullet={true} />
            {isAdmin() && <>
              <AsideMenuItem to='/inventory/leather/batches ' title='Lotti' hasBullet={true} />
            </>}
          </AsideMenuItemWithSub>
        </>}
      </>
      }

      {isModuleActive(MODULE_IDS.CHEMICALS) && <>

      {(isAdmin() || isSupervisor()) && <>
        <AsideMenuItem to='/chemicals/product/list' title='Chimico (Preview)' fontIcon='fas fa-flask' isFirstLevel={true}>
        </AsideMenuItem>
      </>}
      
      </>
      }

      {isModuleActive(MODULE_IDS.PACKING_LIST) && <>

        {isAdmin() && <>

          <AsideMenuItem to='/packingList/list' title='Packing List' fontIcon='bi bi-list-check' isFirstLevel={true}>
          </AsideMenuItem>
        </>}
      </>
      }

      {isModuleActive(MODULE_IDS.PRODUCTION_ORDER) && <>

      {isAdmin() && <>
          <AsideMenuItemWithSub to='/profiles' title='Anagrafiche' fontIcon='fa fa-solid fa-user' isFirstLevel={true}>
            <AsideMenuItem to='/profiles/article/leather/list' title='Articoli' hasBullet={true} />
            <AsideMenuItem to='/profiles/customer/list' title='Clienti' hasBullet={true} />

            {isModuleActive(MODULE_IDS.INVENTORY) && <>
              <AsideMenuItem to='/profiles/account/supplier/list' title='Fornitori' hasBullet={true} />
            </>}
            
          </AsideMenuItemWithSub>
        </>}
      </>
      }

      {isAdmin() && <>

      <AsideMenuItemWithSub
        to='/settings'
        title='Impostazioni'
        fontIcon='bi-gear'
        isFirstLevel={true}
      >
        <AsideMenuItem to='/settings/users' title='Utenti' hasBullet={true} />
        <AsideMenuItem to='/settings/machines' title='Macchine' hasBullet={true} />
        <AsideMenuItem to='/settings/systemLogs' title='Log di sistema' hasBullet={true} />
        {/* <AsideMenuItem to='/settings/machines' title='Macchine' hasBullet={true} />    */}

      </AsideMenuItemWithSub>
      </>}
    </>
  )
}
