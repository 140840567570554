import { useNavigate, Link } from "react-router-dom";
import {Outlet, useParams} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../../_metronic/helpers'
import clsx from 'clsx'

const PackingListNavigation = () => 
{
    const {pathname} = useLocation()
    let { idMacchina } = useParams();
    //let { codiceMacchina } = useParams();
    let codiceMacchina = "ger1";
    
    let packingListUrl = `/packingList/list`;
    let customerListUrl = `/packingList/customer/list`;
    //let comandiUrl = `/macchina/${idMacchina}/th004/comandi`;
    let t2Url = `/packingList/t2s`;
    const isPackingListActive = checkIsTabPackingListActive(pathname, packingListUrl);
    //const isComandiActive = checkIsTabComandiActive(pathname);
    const isT2Active = checkIsTabT2Active(pathname);
    const isCustomersActive = checkIsTabCustomersActive(pathname);
    
    return (
        <>
            {/* <!--begin::Container--> */}
                <div 
                className="container-fluid d-flex align-items-center bg-white mytoolbar">
                
                    {/* <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"> */}
                                
                        <div className="d-flex overflow-auto h-55px">
                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                                <li className="nav-item"><Link to={packingListUrl} className={clsx('nav-link text-active-primary me-6', { active: isPackingListActive})}>Archivio</Link></li>
                                <li className="nav-item"><Link to={t2Url} className={clsx('nav-link text-active-primary me-6', { active: isT2Active})}>Elenco T2</Link></li>
                                {/* <li className="nav-item"><Link to={customerListUrl} className={clsx('nav-link text-active-primary me-6', { active: isCustomersActive})}>Clienti</Link></li> */}
                                {/* <li className="nav-item"><Link to={ordiniUrl} className={clsx('nav-link text-active-primary me-6', { active: isOrdiniActive})}>Lavorazioni</Link></li> */}
                            </ul>
                        </div>                                    

                    {/* </div> */}
                </div>
            {/* <!--end::Container--> */}
        <Outlet/>
        </>

    )
}

// function checkIsTabComandiActive(pathname) 
// {
//     if (!pathname) 
//     {
//       return false;
//     }

//     if(pathname.indexOf("comandi") > -1 || pathname.indexOf("creaComando") > -1)
//     {
//         return true;
//     }
  
//     return false;
// }

  function checkIsTabT2Active(pathname) 
  {
    if (!pathname) {
      return false;
    }

    if(pathname.indexOf("t2s") > -1)
    {
        return true;
    }
  
    return false;
  }

  function checkIsTabCustomersActive(pathname) 
  {
    if (!pathname) {
      return false;
    }

    if(pathname.indexOf("customer") > -1)
    {
        return true;
    }
  
    return false;
  }

  function checkIsTabPackingListActive(pathname) {
    if (!pathname) {
      return false;
    }

    if(pathname.indexOf("packingList/list") > -1 || pathname.indexOf("packingList/create") > -1 || pathname.indexOf("packingList/edit") > -1 || pathname.indexOf("packingList/view") > -1 )
    {
        return true;
    }
  
    return false;
  }


export default PackingListNavigation