import {createContext, useState} from "react";

const CacheContext = createContext({});

export const CacheProvider = ({children}) => {
    const [filters, setFilters] = useState([]);
    
    return (
        <CacheContext.Provider value={{filters, setFilters}}>
            {children}
        </CacheContext.Provider>
    )
}

export default CacheContext;