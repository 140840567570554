import { useNavigate, Link , useLocation, Outlet, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import {KTSVG} from '../../../_metronic/helpers'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import {Spinner, Modal, Button} from 'react-bootstrap';
import {usePageData} from '../../../_metronic/layout/core';
import useAuth from "../../../hooks/useAuth";
import DatePicker, {registerLocale} from 'react-datepicker';
import clsx from 'clsx'
import InfoTooltip from '../../system/generic/InfoTooltip';
import { isUnitModule } from "../unit/UnitHelper";
import { setMisuratriceFields } from "./MachineProdHelper";
import BarcodeScannerComponent from "react-qr-barcode-scanner-17";
import {BrowserView, MobileView, isMobile} from 'react-device-detect';

const MachineProdCreateV3 = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const {auth} = useAuth();

    let { idMacchina } = useParams();
    let { unitId } = useParams();

    const [isError, setIsError] = useState();  
    const [isLoading, setIsLoading] = useState();  
    const [errorMessage, setErrorMessage] = useState();
    const [showIndicatorProgress, setShowIndicatorProgress] = useState('');
    const [modalShow, setModalShow] = useState();  
    const [modalMessage, setModalMessage] = useState();
    const [saveInProgress, setSaveInProgress] = useState();
    const [saveResult, setSaveResult] = useState();
    const [newWorkCycleViewModel, setNewWorkCycleViewModel] = useState();
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);  
    const [machine, setMachine] = useState();
    const [machineCode, setMachineCode] = useState();

    const [isGettingOrderDetail, setIsGettingOrderDetail] = useState(false);  
    const [getOrderDetailResult, setGetOrderDetailResult] = useState(0);

    const [isScanning, setIsScanning] = useState(false);

    const [data, setData] = useState("Not Found");

    const {setPageTitle, pageMachines} = usePageData();
    const {setPageBreadcrumbs, pageBreadcrumbs} = usePageData();

    const annullaSalva = () => {
        if(saveInProgress == true)
        {
            return;
        }
        else
        {
            //Ritorna alla pagina precedente
            navigate(-1);
        }
    } 

    const handleClose = () => {
        if(saveResult == true)
        {
            if(isUnitModule(location))
            {
                if(isMobile)
                {
                    navigate(`/units/${unitId}/jobs`);
                }
                else
                {
                    navigate(`/units/${unitId}/jobs/${idMacchina}/viewJob/${newWorkCycleViewModel.id}`);
                }
            }
            else
            {
                navigate(`/produzione/${idMacchina}/${machineCode}/lavorazione/${newWorkCycleViewModel.id}`);
            }
        }
        else
        {
            setModalShow(false);
        }
    }
    
    const handleConfirmationClose = () => {
        setConfirmationModalShow(false);
    }      

    const confirmCreate = async () => {
        setConfirmationModalShow(false);

        //Se c'è un salvataggio in corso non possono essere inoltrare ulteriori richieste
        if(saveInProgress)
        {
            return;
        }

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if(checkValidationErrors() == true)
        {
            return;
        }        

        setLoadingSave(true);
        setSaveInProgress(true);

        //Impostazione dell'id macchina per cui creare il ciclo di lavoro
        
        try {
            const response = await axiosPrivate.post(`/workcycles/mcCreate`, 
                JSON.stringify(newWorkCycleViewModel), 
                {
                    headers: {'Content-Type':'application/json'},
                    withCredentials: true,
                }
            );

            setNewWorkCycleViewModel({...newWorkCycleViewModel, id:response.data});

            setModalMessage("Operazione avvenuta con successo");
            setLoadingSave(false);
            setSaveInProgress(false);
            setSaveResult(true);
            setModalShow(true);
        }
        catch(err) 
        {
            console.error(err);
            setSaveResult(false);
            setModalMessage(`${err}<br>${err?.response?.data?.message}`);
            setLoadingSave(false);
            setSaveInProgress(false);
            setModalShow(true);

            //navigate('/login', {state : {from: location }, replace: true});
            
            // if(err.response?.status === 403) 
            // {
            //     navigate('/login', {state : {from: location }, replace: true});
            // }
        }           
    }       

    const validateProperty = (property) => {

         //Validazione
         if(property.isMandatory)
         {
             if(property.value?.trim() == "")
             {
                 property.isError = true;
                 property.errorMessage = "Il campo non può essere vuoto";
             }
             else
             {
                 property.isError = false;
                 property.errorMessage = "";
             }
         }

        return property;
    }

    //Validazione completa dell'oggetto Lavorazione
    const validateWorkCycle = (newWorkCycle) => {

        newWorkCycle.orderNumber = validateProperty(newWorkCycle.orderNumber);
        newWorkCycle.customer = validateProperty(newWorkCycle.customer);
        newWorkCycle.articleCode = validateProperty(newWorkCycle.articleCode);
        newWorkCycle.articleColor = validateProperty(newWorkCycle.articleColor);
        newWorkCycle.leathersNumber = validateProperty(newWorkCycle.leathersNumber);
        
        
        newWorkCycle.propertySections.forEach(function(propertySection) {
            propertySection.properties.forEach(function(property) {
                property = validateProperty(property);
            });
        });      
        
        return newWorkCycle;
    }

    const checkValidationErrors = () => {

        var output = false;

        if(newWorkCycleViewModel.orderNumber.isError
        || newWorkCycleViewModel.customer.isError
        || newWorkCycleViewModel.articleCode.isError
        || newWorkCycleViewModel.articleColor.isError
        || newWorkCycleViewModel.leathersNumber.isError)
        {
            output = true;
        }


        newWorkCycleViewModel.propertySections.forEach(function(propertySection) {
            propertySection.properties.forEach(function(property) {
                if(property.isError)
                {
                    output = true;
                }
            });
        });     


        return output;
    }    

    const onCodeScanned = (code) =>
    {
        code = ""+code;

        //Setting order number scanned and validation
        newWorkCycleViewModel.orderNumber.value = code;
        newWorkCycleViewModel.orderNumber = validateProperty(newWorkCycleViewModel.orderNumber);

        //Resetting order info result before requesting order info
        setGetOrderDetailResult(0);

        //Stop scanning
        setIsScanning(false);

        //Requesting order info
        getOrderDetailV2(code);
    }

    const getOrderDetailV2 = (orderNumber) =>
    {
        let isMounted = true;
        const controller = new AbortController();

        const getProductionOrder = async () => 
        {
            setIsGettingOrderDetail(true);

            try {
                const response = await axiosPrivate.post(`/production/getProductionOrderByNumber`, 
                {"orderNumber":orderNumber},
                {
                    headers: { 'Content-Type': 'application/json' }, 
                    withCredentials: true,
                    signal:controller.signal
                }
            );

                if (isMounted) 
                {
                    console.log(response.data)
                    //Se il cartellino non viene trovato, il server risponde con string vuota
                    //Quindi basta controllare questo valore per capire se è stato trovato o meno

                    var customer = "";
                    var article = "";
                    var color = "";
                    var leathersNumber = "";
                    var quantityUM = "";

                    var spessore = "";
                    var quantity = "";
                    var lotto = "";
                    var note = "";

                    if(response.data != "")
                    {
                        customer = response.data.customer;
                        article = response.data.article;
                        color = response.data.color;
                        
                        if (! (typeof response.data.leathersNumber  === 'undefined'))
                        {
                            leathersNumber = ""+response.data.leathersNumber;
                        }

                        if (! (typeof response.data.quantityUM  === 'undefined'))
                        {
                            quantityUM = ""+response.data.quantityUM;
                        }

                        spessore = response.data.spessore;
                        lotto = response.data.lotto;
                        quantity = quantityUM +" "+ response.data.piediQuadri;
                        note = response.data.note;
                        setGetOrderDetailResult(1);
                    }
                    else
                    {
                        //Production Order Not Found
                        //Viene mostrato una notifica 'rossa' per indicare l'assenza del cartellino
                        setGetOrderDetailResult(2);
                    }

                    var wc = {...newWorkCycleViewModel};

                    wc.customer.value = customer;
                    wc.articleCode.value = article;
                    wc.articleColor.value = color;
                    wc.leathersNumber.value = ""+leathersNumber;
                    wc.spessore = spessore;
                    wc.lotto = lotto;
                    wc.quantity = quantity;
                    wc.note = note;

                    //Per la macchina misuratrice (MISU01) è stata fatta una personalizzazione.
                    //Quando si crea una nuova lavorazione per questa macchina, i parametri 'dinamici' vanno valorizzati con i campi del cartellino
                    //Quindi si andranno ad impostare i campi dinamici con quelli letti dal cartellino, per favorire la corretta compilazione
                    //Non è stata fatta una funzionalità dinamica dato che si trattava di un caso isolato
                    var productionOrder = response.data;
                    //wc = setMisuratriceFields(machine, productionOrder, wc);

                    var validatedWorkCycle = validateWorkCycle(wc);

                    setNewWorkCycleViewModel(validatedWorkCycle);
                    // machineName = response.data.name;
                    // setMachine(response.data);
                    // setMachineCode(response.data.code?.toLowerCase());
                }
            }
            catch (err) {

                alert("Errore", JSON.stringify(err));
                //Se il cartellino non viene trovato allora si riceverà un eccezione lato server
                //Quindi gestirla come eccezione
                //@TODO
                console.error(err);
            }

            setIsGettingOrderDetail(false);
        }

        getProductionOrder(orderNumber);

        return () => {
            isMounted = false;
            controller.abort();
        }
    }

    const getOrderDetail = (event) => 
    {
        if (event.key === 'Enter') 
        {
            getOrderDetailV2(event.target.value);

            // let isMounted = true;
            // const controller = new AbortController();

            // const getProductionOrder = async () => 
            // {
            //     setIsGettingOrderDetail(true);

            //     try {
            //         var orderNumber = event.target.value;

            //         const response = await axiosPrivate.get(`/production/getProductionOrderByNumber/${orderNumber}`, {
            //             signal: controller.signal
            //         });

            //         if (isMounted) 
            //         {
            //             console.log(response.data)
            //             //Se il cartellino non viene trovato, il server risponde con string vuota
            //             //Quindi basta controllare questo valore per capire se è stato trovato o meno

            //             var customer = "";
            //             var article = "";
            //             var color = "";
            //             var leathersNumber = "";

            //             if(response.data != "")
            //             {
            //                 customer = response.data.customer;
            //                 article = response.data.article;
            //                 color = response.data.color;
                            
            //                 if (! (typeof response.data.leathersNumber  === 'undefined'))
            //                 {
            //                     leathersNumber = ""+response.data.leathersNumber;
            //                 }

            //                 setGetOrderDetailResult(1);
            //             }
            //             else
            //             {
            //                 //Production Order Not Found
            //                 //Viene mostrato una notifica 'rossa' per indicare l'assenza del cartellino
            //                 setGetOrderDetailResult(2);
            //             }

            //             var wc = {...newWorkCycleViewModel};

            //             wc.customer.value = customer;
            //             wc.articleCode.value = article;
            //             wc.articleColor.value = color;
            //             wc.leathersNumber.value = ""+leathersNumber;

            //             //Per la macchina misuratrice (MISU01) è stata fatta una personalizzazione.
            //             //Quando si crea una nuova lavorazione per questa macchina, i parametri 'dinamici' vanno valorizzati con i campi del cartellino
            //             //Quindi si andranno ad impostare i campi dinamici con quelli letti dal cartellino, per favorire la corretta compilazione
            //             //Non è stata fatta una funzionalità dinamica dato che si trattava di un caso isolato
            //             var productionOrder = response.data;
            //             wc = setMisuratriceFields(machine, productionOrder, wc);

            //             var validatedWorkCycle = validateWorkCycle(wc);

            //             setNewWorkCycleViewModel(validatedWorkCycle);

            //             // machineName = response.data.name;
            //             // setMachine(response.data);
            //             // setMachineCode(response.data.code?.toLowerCase());
            //         }
            //     }
            //     catch (err) {
            //         //Se il cartellino non viene trovato allora si riceverà un eccezione lato server
            //         //Quindi gestirla come eccezione
            //         //@TODO
            //         console.error(err);
            //     }

            //     setIsGettingOrderDetail(false);
            // }

            // getProductionOrder();

            // return () => {
            //     isMounted = false;
            //     controller.abort();
            // }
        }
    }

    const setPropertyValue = (propertyCode,value, event) => {
        
        if(propertyCode == "startDate" || propertyCode == "endDate" || propertyCode == "Date" || event.target.validity.valid)
        {
            var wc = {...newWorkCycleViewModel};

            if(propertyCode == "orderNumber")
            {
                wc.orderNumber.value = value;
                wc.orderNumber = validateProperty(wc.orderNumber);
                setGetOrderDetailResult(0);
            }
            else if(propertyCode == "customer")
            {
                wc.customer.value = value;
                wc.customer = validateProperty(wc.customer);
            }   
            else if(propertyCode == "articleCode")
            {
                wc.articleCode.value = value;
                wc.articleCode = validateProperty(wc.articleCode);
            }            
            else if(propertyCode == "articleColor")
            {
                wc.articleColor.value = value;
                wc.articleColor = validateProperty(wc.articleColor);
            }
            else if(propertyCode == "leathersNumber")
            {
                wc.leathersNumber.value = value;
                wc.leathersNumber = validateProperty(wc.leathersNumber);
            }
            else if(propertyCode == "startDate")
            {
                wc.startDate.value = value;
            }
            else if(propertyCode == "endDate")
            {
                wc.endDate.value = value;
            }
            else if(propertyCode == "autoSend")
            {
                wc.autoSend = !wc.autoSend;
            }
            else if(propertyCode == "notes")
            {
                wc.notes.value = value;
            }            
            else
            {            
                wc.propertySections.forEach(function(propertySection) {
                    propertySection.properties.forEach(function(property) {
                        if(property.code == propertyCode)
                        {
                            property.value = value;
                            property = validateProperty(property);
                        }
                    });
                });
            }

            setNewWorkCycleViewModel(wc);
        }
    }    
    
    const setLoadingSave = (isLoading) => {
        if(isLoading)
        {
            setShowIndicatorProgress('on');            
        }
        else
        {
            setShowIndicatorProgress('off');
        }       
    }    

    //Setting Page BreadCrumb
    useEffect(()=>{ 

        let isMounted = true;
        const controller = new AbortController();
        
        //Se non è stata trovata nessuna macchine viene impostato un nome di defualt 'No Name'
        let machineName = "No Name";

        const getMachine = async () => {
            try {
              const response = await axiosPrivate.get(`/machines/getMachineById/${idMacchina}`, {
                signal: controller.signal
              });
      
              if (isMounted) {
                machineName = response.data.name;
                setMachine(response.data);
                setMachineCode(response.data.code?.toLowerCase());
              }
            }
            catch (err) {
              console.error(err);
            }
      
            setPageTitle(machineName);
            setPageBreadcrumbs([`Nuova Lavorazione`]);
          }
      
          getMachine();


        return () => {
            isMounted = false;
            controller.abort();
        }
  
      },[])    
      
    //Lettura lista ricette e validazione iniziale per mostrare i campi obbligatori
    useEffect(() => {

        setIsLoading(true);

        let isMounted = true;
        const controller = new AbortController();

         const getNewWorkCycleModel = async () => {
            try {
                const response = await axiosPrivate.get(`/workcycles/getNewMachineWorkCycleModel/${idMacchina}`, {
                    signal: controller.signal
                });

                if (isMounted) {

                    response.data.createdBy = auth.userId;
                    response.data.machineId = idMacchina;

                    //Validazione iniziale
                    var validatedWorkCycle = validateWorkCycle(response.data);
                   
                    //Compilazione id utente
                    setNewWorkCycleViewModel(validatedWorkCycle);

                    setIsLoading(false);
                    setIsError(false);
                } 
                
            }
            catch(err) 
            {
                console.error(err);
                setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
                setIsLoading(false);
                setIsError(true);

                // if(err.response?.status === 403) 
                // {
                //     navigate('/login', {state : {from: location }, replace: true});
                // } 
            }
        }

        getNewWorkCycleModel();

    }, [])      
    
    const handleSubmit = (e) =>{

        e.preventDefault(); 

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if(checkValidationErrors() == true)
        {
            return;
        }  

        setConfirmationModalShow(true);     
    }

    return (
        <>
            <div id="kt_content_container" className="container-xxl py-2">

          {/* begin::Spinner */}
          {isLoading &&
            <div className="row d-flex justify-content-center m-20">
              <Spinner animation="border" variant="primary" />
            </div>
          }
          {/* end::Spinner */}

          {/* begin::Error message */}
          {!isLoading && isError &&

            <div className="row d-flex justify-content-center m-20">

              <div className="alert alert-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

                <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                    <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                  </svg>
                </span>                

                <div className="text-center">
                  <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                  <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                  <div className="mb-9 text-dark" dangerouslySetInnerHTML={{__html: errorMessage}}></div>
                </div>
              </div>
            </div>

          }
          {/* end::Error message */}

          {!isLoading && !isError && 

            <form 
                className="form w-100" 
                noValidate="novalidate" 
                id="kt_sign_in_form" 
                onSubmit={handleSubmit}>

                    {/* Sezione comune a tutte le macchine */}

                    <div className='card mb-5 mb-xl-8 mt-5'>

                        <div className='card-header border-0 pt-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>Nuova Lavorazione</span>
                            </h3>
                        </div>

                            <div className='card-body py-3'>

                                {/* START Campo catellino per dispositivi non mobile */}

                                <div className="row d-md-none mb-10">

                                <label className="form-label">Cartellino(M)</label>
                                
                                    <div className="d-table w-100">
                                        <div className="table-row">
                                            <div className="d-table-cell w-100">
                                            
                                            <div className="input-group mb-3">
                                               
                                            <span
                                              onClick={(e) => getOrderDetailV2(newWorkCycleViewModel?.orderNumber.value)}
                                                className={clsx({'input-group-text' : true, 'bg-success': getOrderDetailResult == 1 , 'bg-danger': getOrderDetailResult == 2  })}>
                                                {!isGettingOrderDetail && 
                                                    <i className="fas fa-file-alt text-dark" style={{paddingLeft: "1px",paddingRight: "2px"}}></i>
                                                }

                                                {isGettingOrderDetail && 
                                                <div className="spinner-border spinner-border-sm text-warning" >
                                                </div>
                                                }
                                                                                            
                                            </span>
                                            <input type="text"
                                                className={clsx('form-control', newWorkCycleViewModel?.orderNumber.isError ? 'bg-light-danger border-danger' : '')}
                                                placeholder=""
                                                value={newWorkCycleViewModel?.orderNumber.value}
                                                title={newWorkCycleViewModel?.orderNumber.errorMessage}
                                                autoComplete="off"
                                                onChange={(e) => setPropertyValue('orderNumber', e.target.value, e)}
                                                onKeyDown={(e) => getOrderDetail(e)}
                                                {...(isGettingOrderDetail) && {disabled: true}}
                                            /></div>

                                            </div>
                                            <div className="d-table-cell">
                                                <div className="btn btn-secondary" onClick={(e) => setIsScanning(!isScanning)}>
                                                    <i className="fas fa-camera p-0"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {isScanning &&
                                        <div className="col-12">
                                            <MobileView>
                                                <div className="mb-10 col-12 col-md-2">
                                                    <BarcodeScannerComponent
                                                        //facingMode = "user"
                                                        width="100%"
                                                        onUpdate={(err, result) => {
                                                            if (result) {
                                                                onCodeScanned(result);
                                                                // setData(result.text);
                                                                // getOrderDetailV2(result.text); 
                                                            }
                                                            else setData("Not Found");
                                                        }}
                                                    />
                                                    <p>{data}</p>
                                                </div>
                                            </MobileView>
                                        </div>
                                    }


                                </div>

                                {/* END Campo catellino per dispositivi non mobile */}  

                                <div className='row'>

                                {/* START Campo catellino per dispositivi non mobile */}

                                <div className="mb-10 col-md-2 d-none d-md-block">
                                    <label className="form-label">Cartellino</label>

                                    <div className="input-group mb-3">
                                        <span  className={clsx({'input-group-text' : true, 'bg-success': getOrderDetailResult == 1 , 'bg-danger': getOrderDetailResult == 2  })}>
                                        
                                            {!isGettingOrderDetail && 
                                                <i className="fas fa-file-alt text-dark" style={{paddingLeft: "1px",paddingRight: "2px"}}></i>
                                            }

                                            {isGettingOrderDetail && 
                                               <div className="spinner-border spinner-border-sm text-warning" >
                                               </div>
                                            }
                                                                                        
                                        </span>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.orderNumber.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.orderNumber.value}
                                            title={newWorkCycleViewModel?.orderNumber.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('orderNumber', e.target.value, e)}
                                            onKeyDown={(e) => getOrderDetail(e)}
                                            {...(isGettingOrderDetail) && {disabled: true}}
                                        /></div>
                                </div> 

                                 {/* END Campo catellino per dispositivi non mobile */}

                                <div className="mb-10 col-12 col-md-4">
                                    <label className="form-label">Cliente</label>
                                    <input type="text" 
                                    className={clsx('form-control', newWorkCycleViewModel?.customer.isError ? 'bg-light-danger border-danger' : '')} 
                                    placeholder="" 
                                    value={newWorkCycleViewModel?.customer.value}
                                    title={newWorkCycleViewModel?.customer.errorMessage} 
                                    autoComplete="off"
                                    onChange={(e) => setPropertyValue('customer', e.target.value, e)}
                                    />
                                </div>
                                <div className="mb-10 col-12 col-md-3">
                                    <label className="form-label">Articolo</label>
                                    <input type="text" 
                                    className={clsx('form-control', newWorkCycleViewModel?.articleCode.isError ? 'bg-light-danger border-danger' : '')} 
                                    placeholder="" 
                                    value={newWorkCycleViewModel?.articleCode.value}
                                    title={newWorkCycleViewModel?.articleCode.errorMessage} 
                                    autoComplete="off"
                                    onChange={(e) => setPropertyValue('articleCode', e.target.value, e)}
                                    />
                                </div>
                                <div className="mb-10 col-12 col-md-3">
                                    <label className="form-label">Colore</label>
                                    <input type="text" 
                                    className={clsx('form-control', newWorkCycleViewModel?.articleColor.isError ? 'bg-light-danger border-danger' : '')} 
                                    placeholder="" 
                                    value={newWorkCycleViewModel?.articleColor.value}
                                    title={newWorkCycleViewModel?.articleColor.errorMessage} 
                                    autoComplete="off"
                                    onChange={(e) => setPropertyValue('articleColor', e.target.value, e)}
                                    />
                                </div>

                                <div className="mb-10 col-6 col-md-4">
                                    <label className="form-label">Spessore</label>
                                    <input type="text" 
                                    className='form-control' 
                                    value={newWorkCycleViewModel?.spessore}
                                    disabled
                                    />
                                </div>
                                <div className="mb-10 col-6 col-md-4">
                                    <label className="form-label">Quantità</label>
                                    <input type="text" 
                                    className='form-control' 
                                    value={newWorkCycleViewModel?.quantity}
                                    disabled
                                    />
                                </div>

                                <div className="mb-10 col-12 col-md-4">
                                    <label className="form-label">Lotto</label>
                                    <input type="text" 
                                    className='form-control' 
                                    value={newWorkCycleViewModel?.lotto}
                                    disabled
                                    />
                                </div>

                                <div className="mb-10 col-12 col-md-12">
                                    <label className="form-label">Note Cartellino</label>
                                    <textarea 
                                    rows="4"
                                    className='form-control' 
                                    value={newWorkCycleViewModel?.note}
                                    disabled
                                    />
                                </div>                                
                                
                            </div>
                            <div className='row'>
                                <div className="mb-10 col-12 col-md-2">
                                    <label className="form-label">Numero Pelli</label>
                                    <input type="text" 
                                    className={clsx('form-control', newWorkCycleViewModel?.leathersNumber.isError ? 'bg-light-danger border-danger' : '')} 
                                    pattern="[0-9]*"
                                    placeholder="" 
                                    value={newWorkCycleViewModel?.leathersNumber.value}
                                    title={newWorkCycleViewModel?.leathersNumber.errorMessage} 
                                    autoComplete="off"
                                    onChange={(e) => setPropertyValue('leathersNumber', e.target.value, e)}
                                    />
                                </div>

                           

                                <div className="mb-10 col-12 col-md-4">
                                    <label className="form-label">Data Inizio</label>

                                        <div className="d-table w-100">
                                            <div className="table-row">
                                                <div className="d-table-cell w-100">
                                                <DatePicker
                                                placeholderText="Scegli Data"
                                                locale="it"
                                                className={clsx('form-control form-control-solid', newWorkCycleViewModel?.startDate.isError ? 'bg-light-danger border-danger' : '')}

                                                name="startDate"
                                                dateFormat="dd/MM/yyyy HH:mm:ss"
                                                autoComplete="off"
                                                showTimeSelect
                                                timeFormat="p"
                                                timeIntervals={15}

                                                selected={newWorkCycleViewModel?.startDate.value}
                                                onChange={(date) => setPropertyValue('startDate', date, null)}
                                            />
                                                    
                                                </div>
                                                <div className="d-table-cell">
                                                    <div className="btn btn-secondary" onClick={(e) => setPropertyValue('startDate', new Date(), null) }>
                                                        <i className="fas fa-clock p-0"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>

                                <div className="mb-10 col-12 col-md-6">
                                    <label className="form-label">Data Fine</label>
                                        <div className="d-table w-100">
                                            <div className="table-row">
                                                <div className="d-table-cell w-100">
                                                    <DatePicker
                                                        placeholderText="Scegli Data"
                                                        locale="it"
                                                        className={clsx('form-control form-control-solid', newWorkCycleViewModel?.endDate.isError ? 'bg-light-danger border-danger' : '')}
                                                        autoComplete="off"

                                                        name="endDate"
                                                        dateFormat="dd/MM/yyyy HH:mm:ss"

                                                        showTimeSelect
                                                        timeFormat="p"
                                                        timeIntervals={15}

                                                        selected={newWorkCycleViewModel?.endDate.value}
                                                        onChange={(date) => setPropertyValue('endDate', date, null)}
                                                    />

                                                </div>
                                                <div className="d-table-cell">
                                                    <div className="btn btn-secondary" onClick={(e) => setPropertyValue('endDate', new Date(), null) }>
                                                        <i className="fas fa-clock p-0"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                </div>

                                {/* <div className="mb-10 col-6 col-md-3">
                                    <label className="form-label">Stato</label>
                                </div> */}

                            </div>
                        </div>

                    </div> 

                    {/* <ul class="nav nav-pills">
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="#">Input</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#">Output</a>
                        </li>
                    </ul> */}


                    {/* Sezioni specifiche per singola macchina */}
                    {newWorkCycleViewModel?.propertySections.map((propertySection, i) =>
                                
                        <div className='card mb-5 mb-xl-8 mt-5' key={i}>
                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>{propertySection.name}</span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>
                            <div className='row'>

                                {propertySection?.properties.map((property, j) =>
                                
                                <div className={clsx("mb-10", property?.colCssClass)} key={j}>

                                {property?.type?.toLowerCase() == "string" &&
                                    <>
                                        <label className="form-label">
                                            {property?.name}
                                            {!(typeof property?.um === 'undefined') && property?.um != "" && <>&nbsp;({property?.um})</>}
                                        </label>
                                        <input type="text" 
                                        className={clsx('form-control', property?.isError ? 'bg-light-danger border-danger' : '')} 
                                        placeholder="" 
                                        value={property?.value} 
                                        title={property?.ErrorMessage} 
                                        autoComplete="off"
                                        onChange={(e) => setPropertyValue(property?.code, e.target.value, e)}
                                        />
                                    </> 
                                }

                                {property?.type?.toLowerCase() == "stringarea" &&
                                    <>
                                        <label className="form-label">
                                            {property?.name}
                                            {!(typeof property?.um === 'undefined') && property?.um != "" && <>&nbsp;({property?.um})</>}
                                        </label>
                                        <textarea
                                            className={clsx('form-control', property?.isError ? 'bg-light-danger border-danger' : '')} 
                                            placeholder="" 
                                            value={property?.value} 
                                            title={property?.ErrorMessage} 
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue(property?.code, e.target.value, e)}
                                            rows="5"
                                        />
                                    </> 
                                }

                                {property?.type?.toLowerCase() == "select" &&
                                    <>
                                        <label className="form-label">
                                            {property?.name}
                                            {! (typeof property?.um  === 'undefined') && property?.um  != "" &&<>&nbsp;({property?.um})</>}
                                        </label>

                                        <select
                                            className={clsx('form-select', property?.isError ? 'bg-light-danger border-danger' : '')}
                                            value={property?.value}
                                            title={property?.ErrorMessage}
                                            onChange={(e) => setPropertyValue(property?.code, e.target.value, e)}>
                                            <option value=''>Scegli</option>

                                            {property?.selectValues.map(item => {
                                                return (<option key={item.key} value={item.key}>{item.value}</option>);
                                            })}

                                        </select>

                                    </> 
                                }                                


                                {property?.type?.toLowerCase() == "int" &&
                                    <>
                                        <label className="form-label">
                                            {property?.name}
                                            {! (typeof property?.um  === 'undefined') && property?.um  != "" &&<>&nbsp;({property?.um})</>}
                                        </label>
                                        <input type="text"
                                            className={clsx('form-control', property?.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            pattern="[0-9]*"
                                            value={property?.value}
                                            title={property?.ErrorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue(property?.code, e.target.value, e)}
                                        />
                                    </>
                                }              

                                {property?.type?.toLowerCase() == "float" &&
                                    <>
                                        <label className="form-label">
                                            {property?.name} 
                                            {! (typeof property?.um  === 'undefined') && property?.um  != "" &&<>&nbsp;({property?.um})</>}
                                        </label>
                                        <input type="text"
                                            className={clsx('form-control', property?.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            pattern="[0-9]*([.][0-9]{0,5})?"
                                            value={property?.value}
                                            title={property?.ErrorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue(property?.code, e.target.value, e)}
                                        />
                                    </>
                                }

                                {property?.type?.toLowerCase() == "bool" &&
                                    <>
                                        <label className="form-label">
                                            {property?.name} 
                                            {! (typeof property?.um  === 'undefined') && property?.um  != "" &&<>&nbsp;({property?.um})</>}
                                        </label>
                                        <select 
                                            className={clsx('form-select', property?.isError ? 'bg-light-danger border-danger' : '')} 
                                            value={property?.value}
                                            onChange={(e) => setPropertyValue(property?.code, e.target.value, e)}>
                                                <option value=''>Scegli</option>
                                                <option value='true'>SI</option>
                                                <option value='false'>NO</option>
                                            </select>                                        
                                    </>
                                        }

                                {property?.type?.toLowerCase() == "date" &&
                                    <>
                                        <label className="form-label">
                                            {property?.name}
                                            {!(typeof property?.um === 'undefined') && property?.um != "" && <>&nbsp;({property?.um})</>}
                                        </label>

                                        <DatePicker
                                            placeholderText="Scegli Data"
                                            locale="it"
                                            className={clsx('form-control form-control-solid', property?.isError ? 'bg-light-danger border-danger' : '')}

                                            dateFormat="dd/MM/yyyy"
                                            autoComplete="off"
                                            title={property?.ErrorMessage}

                                            selected={property?.value}
                                            onChange={(date) => setPropertyValue(property?.code, date, null)}
                                        />

                                            </>
                                }

                                </div>      

                                )}

                                    
                            </div>

                            </div>

                        </div>

                        )}

                        <div className='card mb-5 mb-xl-8 mt-5'>
                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Note</span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>
                                <div className='row mb-5'>
                                    {/* <label className="form-label">Note</label> */}
                                    <textarea
                                        className={clsx('form-control', newWorkCycleViewModel?.notes?.isError ? 'bg-light-danger border-danger' : '')}
                                        placeholder=""
                                        value={newWorkCycleViewModel?.notes?.value}
                                        title={newWorkCycleViewModel?.notes?.ErrorMessage}
                                        autoComplete="off"
                                        onChange={(e) => setPropertyValue("notes", e.target.value, e)}
                                        rows="5"
                                    />
                                </div>
                            </div>
                        </div>                        

                        <div className="row justify-content-md-center">

                            <div className="col col-12 col-md-4 my-5">

                                <a className="btn btn-lg btn-primary w-100" onClick={(e) => annullaSalva()} >
                                    Annulla
                                </a>                                

                            </div>

                            <div className="col col-12 col-md-4 my-5">

                            <div className="d-table mx-auto">

                                    <div className="table-row w-100">

                                        <div className="d-table-cell">
                                            <div className="input-group mx-auto">
                                                <div className="input-group-text">

                                                    <InfoTooltip text="Quando si salva una lavorazione, questa viene inserita nell'archivio. Se si sceglie l'opzione di invio automatico, la lavorazione verrà anche trasmessa alla macchina."></InfoTooltip>

                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={newWorkCycleViewModel?.autoSend}
                                                            onChange={(e) => setPropertyValue('autoSend', e.target.value, e)} />
                                                        <label className="form-check-label">
                                                            Invio Automatico
                                                        </label>
                                                    </div>
                                                </div>
                                                <button
                                                    type="submit"
                                                    id="kt_sign_in_submit"
                                                    className="btn btn-lg btn-primary"
                                                    data-kt-indicator={showIndicatorProgress}>
                                                    <span className="indicator-label">Salva</span>
                                                    <span className="indicator-progress" >Caricamento...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            </div>

                                        </div>


                                    </div>

                                </div>                                


                            </div>

                        </div>
         
                </form>
          }
            </div>

            <Modal show={modalShow} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Esito Salvataggio</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
                {saveResult &&
                    <div className="row d-flex justify-content-center">

                        <div className="d-flex flex-center flex-column py-10 px-10 px-lg-20">

                            <span className="svg-icon svg-icon-2hx svg-icon-success mb-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                    <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                </svg>
                            </span>

                            <div className="text-center">
                                {/* <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div> */}
                                <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: modalMessage }}></div>
                            </div>
                        </div>

                    </div>         
                }

                {!saveResult &&
                    <div className="row d-flex justify-content-center">

                        <div className="d-flex flex-center flex-column py-10 px-10 px-lg-20">

                            <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                    <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                </svg>
                            </span>

                            <div className="text-center">
                                <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: modalMessage }}></div>
                            </div>
                        </div>

                    </div>                
                }                
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Chiudi
            </Button>
          </Modal.Footer>
        </Modal>     


        {/* START - Confirmation Modal */}     
        <Modal show={confirmationModalShow} onHide={handleConfirmationClose}>
          <Modal.Header closeButton>
            <Modal.Title>Conferma Operazione</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <p>Sei sicuro di voler continuare?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleConfirmationClose}>Annulla</Button>
            <Button variant="primary" onClick={confirmCreate}>Conferma</Button>            
          </Modal.Footer>
        </Modal>     

        {/* END - Confirmation Modal */}       
        </>

    )
}

export default MachineProdCreateV3