import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { KTSVG } from '../../../_metronic/helpers'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { usePageData } from '../../../_metronic/layout/core';
import { Spinner, Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DatePicker, {registerLocale} from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it"; // the locale you want
import {PageErrorMessage} from "./Components";

registerLocale("it", it); // register it with the name you want

const InventoryArticleList = () => {
  const [workCycles, setWorkCycles] = useState();

  let { entityType } = useParams();
  let navPrefix = "profiles";

  //const [modalShow, setModalShow] = useState();  
  //const [modalMessage, setModalMessage] = useState();  

  const [isError, setIsError] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  let { idMacchina } = useParams();

  const { setPageTitle, pageMachines } = usePageData();
  const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();
  const [machineCode, setMachineCode] = useState();

  const [modalFilterShow, setModalFilterShow] = useState(false);  

  const [tempFilter, setTempFilter]= useState();
  const [showExportProgress, setShowExportProgress] = useState('off');

  let emptyFilter = {
    id:'',
    code:'',
    name:'',
    category:'',
    sortField:'Id',
    sortDirection:'desc'
  };


  const [filter, setFilter] = useState(emptyFilter);    

  //Pagination Handling
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  //Sorting handling
  const [defaultSortAsc, setDefaultSortAsc] = useState(false);
  const [defaultSortFieldId, setDefaultSortFieldId] = useState(5);  

  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);

  const [modalShow, setModalShow] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [saveInProgress, setSaveInProgress] = useState();
  const [showIndicatorProgress, setShowIndicatorProgress] = useState('');
  const [saveResult, setSaveResult] = useState();

  const paginationComponentOptions = {
    rowsPerPageText: 'Elementi per pagina',
    rangeSeparatorText: 'di',
    selectAllRowsItem: false
  };

  const columns = [
    {
      name: 'Codice',
      maxWidth: '150px',
      selector: row => row.code,
      sortable: true,
      sortFieldId:1,
      sortField:'Code',
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.code);        
      }
    },
    {
      name: 'Nome',
      selector: row => row.name,
      sortable: true,
      sortField:'Name',
      sortFieldId:2,
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.name);        
      }
    }, 
    {
      name: 'Colore',
      selector: row => row.color,
      sortable: true,
      sortField:'Color',
      sortFieldId:3,
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.color);        
      }
    }, 
    {
      name: 'Categoria',
      selector: row => row.category,
      sortable: true,
      sortFieldId:4,
      sortField:'Category',
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.categoryStr);        
      }
    }, 
    {
      name: 'Azioni',
      maxWidth: '100px',
      right: true,
      ignoreRowClick: true,
      cell: (row, index, column, id) => {
        return (
          <div className='row'>

            <Link to={`/${navPrefix}/article/${entityType}/view/${row?.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <i className="fas fa-eye"></i>
            </Link>

            <Link to={`/${navPrefix}/article/${entityType}/edit/${row?.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <i className="fas fa-pencil-alt"></i>
            </Link>
          </div>)
      }
    }
  ];

  //Setting Page BreadCrumb
  useEffect(() => {

      setPageTitle("Anagrafiche");
      
      setPageBreadcrumbs(["Articoli"]);
  }, [])

  useEffect(() => {

    getEntityList(1, perPage, filter);

  }, [])

  const getProdViewLink = (rowId, text) => {
    return <Link to={`/${navPrefix}/article/${entityType}/view/${rowId}`} className='text-dark text-hover-primary fs-6'>{text}</Link>;    
  }    

  const handleSort = async (column, sortDirection) => {

    if(sortDirection == "asc" || sortDirection == "")
    {
      setDefaultSortAsc(true);
    }
    else
    {
      setDefaultSortAsc(false);
    }

    setDefaultSortFieldId(column.sortFieldId);

    let sortedFilter = {...filter, sortField:column.sortField, sortDirection, sortDirection};
    setFilter(sortedFilter);

    getEntityList(1, perPage, sortedFilter);
  };

  const handleFilterClose = () => {
    
    //Se la finestra viene chiusa senza ne applicare ne resettare il filtro, allora i filtri impostati dal momento dell'apertura 
    //della finestra del filtro vengono persi e reimpostati quelli precedentemente applicati
    setFilter(tempFilter);
 
   setModalFilterShow(false);
  }    

  const showFilterModal = () => {

    //Salvataggio in memoria del filtro attuale. 
    //Questo viene fatto perchè se non viene applicato o resettato, il filtro viene reimpostato
    //A quello al momento dell'apertura della finestra

    setTempFilter(filter);

    setModalFilterShow(true);
   }  

   const resetFilter = () => {

    setFilter(emptyFilter);
    getEntityList(1, perPage, emptyFilter);

    setModalFilterShow(false);
   }       

   const applyFilter = () => {
    getEntityList(1, perPage, filter);
    setModalFilterShow(false);
  }  

   const setFilterProperty = (attribute, value, event) => {

      if (attribute == "id") 
      {
        if(event.target.validity.valid)
        {
          setFilter({...filter, id:value});
        }
      }
      else
      {
        var tmpFilter = {...filter}
        tmpFilter[attribute] = value;
        setFilter(tmpFilter);

      }
      // else if (attribute == "code") 
      // {
      //     setFilter({...filter, code:value});
      // }      
      // else if (attribute == "name") 
      // {
      //     setFilter({...filter, name:value});
      // }      
   }

   const getEntityListRequest = (page, pageSize, pageFilter)=> {

    let entityListRequest =
    {
      idMacchina: idMacchina,
      page: page,
      pageSize: pageSize,
      filterId: pageFilter.id,
      filterCode: pageFilter.code,
      filterName: pageFilter.name,
      filterCategory: pageFilter.category,
      filterType: entityType.toUpperCase(),
      sortField: pageFilter.sortField,
      sortDirection: pageFilter.sortDirection
    }

    return entityListRequest;
   }

  const getEntityList = (page, pageSize, pageFilter) => {

    setIsLoading(true);

    let isMounted = true;
    const controller = new AbortController();

    let entityListRequest = getEntityListRequest(page, pageSize, pageFilter);

    const fetchWorkCycleList = async () => {

      try 
      {
        const response = await axiosPrivate.post(`/inventoryArticle/getEntities`,  
        JSON.stringify(entityListRequest), 
        {
            headers: {'Content-Type':'application/json'},
            withCredentials: true,
            signal:controller.signal
        });

        if (isMounted) 
        {
          setWorkCycles(response.data.data);
          setTotalRows(response.data.total);

          //L'elenco delle righe selezionate viene svuotato ogni volta che c'è un aggiornamento delle righe mostrate
          setSelectedRows([]); 

          setIsLoading(false);
          setIsError(false);
        }
      }
      catch (err) 
      {
        console.error(err);
        setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
        setIsLoading(false);
        setIsError(true);
      }
    }

    fetchWorkCycleList();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }

  const handlePageChange = page => {
		getEntityList(page, perPage, filter);
    setCurrentPage(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {

    getEntityList(page, newPerPage, filter);
    setPerPage(newPerPage);
	};  

  
  const onSelectedRowsChange = (state) => {

    var selectedRowsArray = [];

    state.selectedRows.forEach(function (tableRow) {
      selectedRowsArray.push(tableRow.id);
    });

    setSelectedRows(selectedRowsArray);
  };  

  const handleConfirmationClose = () => {
    setConfirmationModalShow(false);
  }


const setLoadingSave = (isLoading) => {
  if (isLoading) {
      setShowIndicatorProgress('on');
  }
  else {
      setShowIndicatorProgress('off');
  }
}

const handleClose = () => {
  if (saveResult == true) 
  {
      setModalShow(false);
      getEntityList(1, perPage, filter);
  }
  else 
  {
      setModalShow(false);
  }
}

  return (
    <div id="kt_content_container" className="mx-5">

      {/* begin::Spinner */}
      {isLoading &&
        <div className="row d-flex justify-content-center m-20">
          <Spinner animation="border" variant="primary" />
        </div>
      }
      {/* end::Spinner */}

      {/* begin::Error message */}
      {!isLoading && isError &&
        <PageErrorMessage message={errorMessage}/>
      }
      {/* end::Error message */}

      {/* begin::Table */}
      {!isLoading && !isError &&
        <>

          <div className='card mb-5 mt-5 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Elenco Articoli</span>
              </h3>
              <div className="card-toolbar">

                <div className='btn btn-primary me-2 mt-1' onClick={(e) => showFilterModal()}>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-search"></i>
                  </span>
                  Filtro
                </div>

                <div className='btn btn-secondary me-2 mt-1' onClick={(e) => getEntityList(1, perPage, filter)}>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-sync"></i>
                  </span>
                  Aggiorna
                </div>
                                
                <Link to={`/${navPrefix}/article/${entityType}/create/0`} className='btn btn-success  mt-1'>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-solid fa-plus"></i>
                  </span>
                  Nuovo
                </Link>

              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>

              <div className='row mb-2'>

                <DataTable
                  columns={columns}
                  data={workCycles}
                  pagination
                  paginationServer

                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationDefaultPage={currentPage}
                  paginationPerPage={perPage}
                  paginationComponentOptions={paginationComponentOptions}
                  paginationRowsPerPageOptions={[10, 25, 50, 100]}

                  onSort={handleSort}
                  sortServer
                  defaultSortFieldId={defaultSortFieldId}
                  defaultSortAsc={defaultSortAsc}

                  highlightOnHover
                  striped
                />
              </div>
            </div>
          </div>
        </>
      }

      <Modal show={modalFilterShow} onHide={handleFilterClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filtri di Ricerca</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">

            <div className="row">
              <div className="mb-10 col-12">

              <label className="form-label">Codice</label>
                  <input type="text"
                    className='form-control form-control-solid'
                    placeholder="Codice"
                    value={filter?.code} 
                    onChange={(e) => setFilterProperty('code', e.target.value, e)}
                    />
                </div> 

              <div className="mb-10 col-12">

              <label className="form-label">Nome</label>
                  <input type="text"
                    className='form-control form-control-solid'
                    placeholder="Nome"
                    value={filter?.name} 
                    onChange={(e) => setFilterProperty('name', e.target.value, e)}
                    />
                </div>              
              </div>

            <div className="mb-10 col-12">
              <label className="form-label">Categoria</label>
              <select
                className='form-select'
                value={filter?.category}
                onChange={(e) => setFilterProperty('category', e.target.value, e)}
              >
                <option value=''>Tutte</option>
                <option value='ALTRO'>Altro</option>
                <option value='CRUST'>Crust</option>
                <option value='FINITO'>Finito</option>
                <option value='PICLATO'>Piclato</option>
                <option value='WB'>WB</option>
              </select>
            </div>  

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetFilter}>
            Reset
          </Button> 
          <Button variant="primary" onClick={applyFilter}>
            Applica
          </Button>         
        </Modal.Footer>
      </Modal>   

    </div>
  )
}

export default InventoryArticleList