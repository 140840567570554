import { useNavigate, Link, useLocation, Outlet, useParams } from "react-router-dom";
import { useState, useEffect, useMemo } from 'react';
import { KTSVG } from '../../../_metronic/helpers'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { usePageData } from '../../../_metronic/layout/core';
import useAuth from "../../../hooks/useAuth";
import DatePicker, { registerLocale } from 'react-datepicker';
import DataTable from 'react-data-table-component';
import clsx from 'clsx'
import FileSaver from "file-saver";
import { setTotals, totalColumns, totalsTableStyles } from './PackingListHelper';

const PackingListView = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    let { packingListId } = useParams();

    const [isError, setIsError] = useState();
    const [isLoading, setIsLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [showIndicatorProgress, setShowIndicatorProgress] = useState('');
    const [modalShow, setModalShow] = useState();
    const [modalMessage, setModalMessage] = useState();
    const [saveInProgress, setSaveInProgress] = useState();
    const [saveResult, setSaveResult] = useState();
    const [workCycleViewModel, setWorkCycleViewModel] = useState();
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const [machine, setMachine] = useState();
    const [machineCode, setMachineCode] = useState();

    const [tableData, setTableData] = useState([]);
    const [rowId, setRowId] = useState(0);
    const [showExportProgress, setShowExportProgress] = useState('off');

    const { setPageTitle, pageMachines } = usePageData();
    const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();


    const columns = [
        {
            name: '',
            width: '40px',
            cell: (row, index, column, id) => {
              return (
                <div className='row'>

                      <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          onClick={(e) => downloadRowPdf(row.id)}
                          data-kt-indicator={showExportProgress}>
                          <i className="far fa-file-alt"></i>
                      </div>

                </div>)
            }
          },        
        {
            name: 'Num. Box',
            selector: row => row.boxNumber,
            width: '100px',
            cell: (row, index, column, id) => {
                return (
                    <>
                    {row.boxNumber >= 0 && <> {row.boxNumber}</>}
                    </>
                  )
              }
        },
        {
            name: 'Num. Ord.',
            selector: row => row.numeroOrdine,
            cell: (row, index, column, id) => {
                return (
                    <>
                    {row.boxNumber >= 0 && <> {row.numeroOrdine}</>}
                    </>
                  )
              }
        },        
        {
            name: 'Articolo',
            selector: row => row.descrizioneArticolo,
            cell: (row, index, column, id) => {
                return (
                    <>
                    {row.boxNumber >= 0 ? <> {row.descrizioneArticolo}</> : <> <strong>{row.descrizioneArticolo}</strong></>}
                    </>
                  )
              }            
        },   
        {
            name: 'Lotto',
            selector: row => row.numeroLotto,
            cell: (row, index, column, id) => {
                return (
                    <>
                    {row.boxNumber >= 0 ? <> {row.numeroLotto}</> : <> <strong>{row.numeroLotto}</strong></>}
                    </>
                  )
              }            
        },               
        {
            name: 'Cod. Art.',
            selector: row => row.codiceArticolo,
            cell: (row, index, column, id) => {
                return (
                    <>
                    {row.boxNumber >= 0 ? <> {row.codiceArticolo}</> : <> <strong>{row.codiceArticolo}</strong></>}
                    </>
                  )
              }            
        },
        {
            name: 'Colore',
            selector: row => row.colore,
            cell: (row, index, column, id) => {
                return (
                    <>
                    {row.boxNumber >= 0 ? <> {row.colore}</> : <> <strong>{row.colore}</strong></>}
                    </>
                  )
              }                  
        },
        {
            name: 'Scelta',
            maxWidth: '100px',
            selector: row => row.scelta,
            cell: (row, index, column, id) => {
                return (
                    <>
                    {row.boxNumber >= 0 ? <> {row.scelta}</> : <> <strong>{row.scelta}</strong></>}
                    </>
                  )
              }               
        },
        {
            name: 'Num. Pacchi',
            selector: row => row.numeroPacchi,
            maxWidth: '110px',
            cell: (row, index, column, id) => {
                return (
                    <>
                    {row.boxNumber >= 0 ? 
                    <div className='text-end w-100'> {row.numeroPacchiStr}</div> 
                    : <div className='text-end w-100'> <strong>{row.numeroPacchiStr}</strong></div>}
                    </>
                  )
              }                 
        },
        {
            name: 'Superficie',
            selector: row => row.superficie,
            maxWidth: '100px',
            cell: (row, index, column, id) => {
                return (
                    <>
                    {row.boxNumber >= 0 ? 
                    <div className='text-end w-100'> {row.superficieStr}</div>
                    : <div className='text-end w-100'> <strong>{row.superficieStr}</strong></div>}
                    </>
                  )
              }             
        },
        {
            name: 'Num. Pelli',
            selector: row => row.numeroPelli,
            maxWidth: '100px',
            cell: (row, index, column, id) => {
                return (
                    <>
                    {row.boxNumber >= 0 ? 
                    <div className='text-end w-100'> {row.numeroPelliStr}</div>
                    : <div className='text-end w-100'> <strong>{row.numeroPelliStr}</strong></div>}
                    </>
                  )
              }  
        }
    ];

    const downloadRowPdf = (rowId) => {

        let isMounted = true;
        const controller = new AbortController();
    
        const fetchReport = () => {
    
            axiosPrivate.get(`/packingList/getRowReport/${rowId}`, 
            {
                headers: {'Content-Type':'application/json'},
                withCredentials: true,
                responseType: "blob", // important
                signal:controller.signal
            })
            .then(res => {
              // let extension = 'zip';
              // let tempFileName = `${this.state['selectedCommunity']}`
              // let fileName = `${tempFileName}.${extension}`;
              let fileName = `PackingList.pdf`;
    
              const blob = new Blob([res.data], {
                type: 'application/octet-stream'
              })
    
              FileSaver.saveAs(blob,fileName);
              setShowExportProgress('off');
            })
            .catch(err => {
                console.log(err.message);
                setShowExportProgress('off');
                alert(err?.response?.data?.message);
            });
        }
    
        fetchReport();
    
        return () => {
          isMounted = false;
          controller.abort();
        }


    }

    const downloadPdf = () => {

        if(showExportProgress == 'on')
        {
          return;
        }
    
        setShowExportProgress('on');
    
        let isMounted = true;
        const controller = new AbortController();
    
        const fetchReport = () => {

            var pdfOptionsRequest = {
                id: packingListId,
                totals: workCycleViewModel?.packingListTotalRow
            }
    
            axiosPrivate.post(`/packingList/getReport/${packingListId}`, 
            JSON.stringify(pdfOptionsRequest),
            {
                headers: {'Content-Type':'application/json'},
                withCredentials: true,
                responseType: "blob", // important
                signal:controller.signal
            })
            .then(res => {
              // let extension = 'zip';
              // let tempFileName = `${this.state['selectedCommunity']}`
              // let fileName = `${tempFileName}.${extension}`;
              let fileName = `PackingList.pdf`;
    
              const blob = new Blob([res.data], {
                type: 'application/octet-stream'
              })
    
              FileSaver.saveAs(blob,fileName);
              setShowExportProgress('off');
            })
            .catch(err => {
                console.log(err.message);
                setShowExportProgress('off');
                alert(err?.response?.data?.message);
            });
        }
    
        fetchReport();
    
        return () => {
          isMounted = false;
          controller.abort();
        }
    
       }
   
    //Setting Page BreadCrumb
    useEffect(() => {

        setPageTitle("Packing List");
        setPageBreadcrumbs(["Dettaglio Packing List"]);
    }, [])

    useEffect(() => {

        setIsLoading(true);

        let isMounted = true;
        const controller = new AbortController();

         const getNewWorkCycleModel = async () => {
            try {
                const response = await axiosPrivate.get(`/packingList/getPackingListViewModel/${packingListId}`, {
                    signal: controller.signal
                });

                if (isMounted) {

                    response.data.createdBy = auth.userId;

                    var wc = setTotals(response.data);
                
                    //Compilazione id utente
                    setWorkCycleViewModel(wc);

                    setIsLoading(false);
                    setIsError(false);
                } 
                
            }
            catch(err) 
            {
                console.error(err);
                setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
                setIsLoading(false);
                setIsError(true);

                // if(err.response?.status === 403) 
                // {
                //     navigate('/login', {state : {from: location }, replace: true});
                // } 
            }
        }

        getNewWorkCycleModel();

    }, [])   
    
    

    return (
        <>
            <div id="kt_content_container" className="mx-5">

                {/* begin::Spinner */}
                {isLoading &&
                    <div className="row d-flex justify-content-center m-20">
                        <Spinner animation="border" variant="primary" />
                    </div>
                }
                {/* end::Spinner */}

                {/* begin::Error message */}
                {!isLoading && isError &&

                    <div className="row d-flex justify-content-center m-20">

                        <div className="alert alert-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

                            <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                    <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                </svg>
                            </span>

                            <div className="text-center">
                                <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
                            </div>
                        </div>
                    </div>

                }
                {/* end::Error message */}

                {!isLoading && !isError &&

                    <form
                        className="form w-100"
                        noValidate="novalidate"
                        id="kt_sign_in_form">

                        {/* Sezione comune a tutte le macchine */}

                        <div className='card mb-5 mb-xl-8 mt-5'>

                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Dettaglio Packing List - Intestazione</span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>
                                <div className='row'>

                                    <div className="mb-10 col-12 col-md-6">
                                        <label className="form-label">Cliente</label>
                                        <input type="text"
                                            className='form-control'
                                            placeholder=""
                                            value={workCycleViewModel?.cliente}
                                            autoComplete="off"
                                            disabled
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-2">
                                        <label className="form-label">Data</label>

                                        <input type="text"
                                            className='form-control'
                                            placeholder=""
                                            value={workCycleViewModel?.dataStr}
                                            disabled
                                        />

                                    </div>

                                    <div className="mb-10 col-23 col-md-4">
                                        <label className="form-label">Rif. DDT</label>
                                        <input type="text"
                                            className='form-control'
                                            placeholder=""
                                            value={workCycleViewModel?.rifDdt}
                                            title={workCycleViewModel?.rifDdt}
                                            disabled
                                        />
                                    </div>

                                    <div className="mb-10 col-23 col-md-6">
                                        <label className="form-label">Via</label>
                                        <input type="text"
                                            className='form-control'
                                            placeholder=""
                                            value={workCycleViewModel?.via}
                                            disabled
                                        />
                                    </div>


                                    <div className="mb-10 col-12 col-md-2">
                                        <label className="form-label">CAP</label>
                                        <input type="text"
                                            className='form-control'
                                            placeholder=""
                                            value={workCycleViewModel?.cap}
                                            disabled
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Città</label>
                                        <input type="text"
                                            className='form-control'
                                            placeholder=""
                                            value={workCycleViewModel?.citta}
                                            disabled
                                        />
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className='card mb-5 mb-xl-8 mt-5'>

                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Elenco Box</span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>

                                <DataTable
                                    columns={columns}
                                    data={workCycleViewModel?.packingListRows}
                                    highlightOnHover
                                />

                            </div>

                        </div>

                        <div className='card mb-5 mb-xl-8 mt-5'>

                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Totali</span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>

                                <DataTable
                                    columns={totalColumns}
                                    data={workCycleViewModel?.packingListTotalRow}
                                    dense
                                    highlightOnHover
                                />

                            </div>

                        </div>

                        <div className='card mb-5 mb-xl-8'>
                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Altro</span>
                                </h3>
                            </div>
                            <div className='card-body py-3'>

                                <div className='row'>

                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Totale</label>
                                        <input type="text"
                                            className='form-control'
                                            placeholder=""
                                            value={workCycleViewModel?.totale}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Destinazione</label>
                                        <input type="text"
                                            className='form-control'
                                            placeholder=""
                                            value={workCycleViewModel?.destinazione}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Peso</label>
                                        <input type="text"
                                            className='form-control'
                                            value={workCycleViewModel?.pesoStr}
                                            disabled
                                        />
                                    </div>
                                    <div className="mb-10 col-12 col-md-12">
                                        <label className="form-label">Note</label>
                                        <input type="text"
                                            className='form-control'
                                            placeholder=""
                                            value={workCycleViewModel?.note}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="text-center">
                            <Link to={`/packingList/list`} className='btn btn-lg btn-secondary w-25 m-5'>
                                Elenco
                            </Link>

                            <Link to={`/packingList/edit/${packingListId}`} className='btn btn-lg btn-primary w-25 m-5'>
                                <span className="svg-icon svg-icon-1">
                                    <i className="fas fa-edit"></i>
                                </span>
                                <span className="indicator-label">Modifica</span>                                
                            </Link>

                            <div
                                className='btn btn-lg btn-primary w-25 m-5'
                                onClick={(e) => downloadPdf()}
                                data-kt-indicator={showExportProgress}
                            >

                                <span className="svg-icon svg-icon-1">
                                    <i className="fas fa-download"></i>
                                </span>

                                <span className="indicator-label" >Download PDF</span>
                                <span className="indicator-progress" > Download PDF
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </div> 
                        </div>

                    </form>
                }
            </div>
        </>

    )
}

export default PackingListView