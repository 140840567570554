import React from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../../helpers'
import {useLayout} from '../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  hasArrow?: boolean
  isFirstLevel?: boolean
  category?: string
}

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  hasArrow,
  isFirstLevel,
  category
}) => {

  const {pathname} = useLocation()
  const isActive = checkIsMenuActive(pathname, to, category, isFirstLevel)
  const {config} = useLayout()
  const {aside} = config

  return (
    <div 
    className={clsx('menu-item', isFirstLevel ? 'py-2' : 'menu-accordion', { here: isActive})} 
    data-kt-menu-trigger="hover" 
    data-kt-menu-placement={clsx(isFirstLevel ? 'right-start' : '')}>

      <span className="menu-link menu-center" 
        // title={title}
        >

        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}

        {icon  && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}

        {fontIcon  && (
          <span className="menu-icon me-0">
            <i className={clsx('menu-item', fontIcon)}></i>
          </span>    
        )}             

        <span className="menu-title text-center">{title}</span>

        {hasArrow&& (
          <span className="menu-arrow"></span>
        )}
      
      </span>

      <div className={clsx(
          'menu-sub',
          isFirstLevel ? 'menu-sub-dropdown w-225px w-lg-275px px-1 py-4' : 'menu-sub-accordion',
        )}>
      {children}
      </div>      

    </div>

  )
}

function checkIsMenuActive(pathname: string, to: string, category?: string, isFirstLevel?: boolean) {

  if(isFirstLevel)
  {
    if(!category)
    {
      return checkIsActive(pathname, to)
    }
    else
    {
        if(category == "macchine")
        {
          if(pathname.indexOf("macchina") > -1)
          {
              return true;
          }
        }
    }
  }
  else
  {
    return checkIsActive(pathname, to);
  }

  return false;
}

export {AsideMenuItemWithSub}
