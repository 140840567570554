import axios from '../api/axios';
const LOGIN_URL = '/users/authenticate';

export const executeLogin = async (username, password)=>{

     let output = {
        errorCode:0,
        errorMessage:'',
        accessToken: '',
        username: '',
        roles: [],
        userId:0,
        frontEndConfig:{}
     };
     
    try
    {
        const response = await axios.post(LOGIN_URL, 
            JSON.stringify({username, password}), 
            {
                headers: {'Content-Type':'application/json'},
                withCredentials: true
            }
        );  

        output.accessToken = response?.data?.accessToken;
        output.roles = response?.data?.roles;
        output.username = response?.data?.username;
        output.userId = response?.data?.id;
        output.frontEndConfig = response?.data?.frontEndConfig;
    }
    catch(err)
    {
        if(!err?.response)
        {
            output.errorCode = 1;       
            output.errorMessage = "Nessuna risposta dal server";
        } 
        else if(err.response?.status === 400)
        {
            output.errorCode = 400;       
            
            if(err.response?.data != null)
            {
                output.errorMessage = err.response?.data?.message;
            }
            else
            {
                output.errorMessage = 'Generic Server Error';
            }
        } 
        else if(err.response?.status === 401)
        {
            output.errorCode = 401;       
            output.errorMessage = "Non autorizzato";
        } 
        else 
        {
            output.errorCode = 2;       
            output.errorMessage = "Login fallito";
        }
    }

    return output;
}