import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { KTSVG } from '../../../_metronic/helpers'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { usePageData } from '../../../_metronic/layout/core';
import { Spinner, Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DatePicker, {registerLocale} from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it"; // the locale you want

registerLocale("it", it); // register it with the name you want

const AccountList = () => {
  const [workCycles, setWorkCycles] = useState();

  let { accountType } = useParams();
  let navPrefix = "profiles";

  //const [modalShow, setModalShow] = useState();  
  //const [modalMessage, setModalMessage] = useState();  

  const [isError, setIsError] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  let { idMacchina } = useParams();

  const { setPageTitle, pageMachines } = usePageData();
  const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();
  const [machineCode, setMachineCode] = useState();

  const [modalFilterShow, setModalFilterShow] = useState(false);  

  const [tempFilter, setTempFilter]= useState();
  const [showExportProgress, setShowExportProgress] = useState('off');

  let emptyFilter = {
    id:'',
    code:'',
    name:'',
    sortField:'Id',
    sortDirection:'desc'
  };


  const [filter, setFilter] = useState(emptyFilter);    

  //Pagination Handling
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  //Sorting handling
  const [defaultSortAsc, setDefaultSortAsc] = useState(false);
  const [defaultSortFieldId, setDefaultSortFieldId] = useState(5);  

  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);

  const [modalShow, setModalShow] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [saveInProgress, setSaveInProgress] = useState();
  const [showIndicatorProgress, setShowIndicatorProgress] = useState('');
  const [saveResult, setSaveResult] = useState();

  const paginationComponentOptions = {
    rowsPerPageText: 'Elementi per pagina',
    rangeSeparatorText: 'di',
    selectAllRowsItem: false
  };

  const columns = [
    {
      name: 'Codice',
      //maxWidth: '150px',
      selector: row => row.code,
      sortable: true,
      sortFieldId:2,
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.code);        
      }
    },
    {
      name: 'Ragione Sociale',
      //maxWidth: '150px',
      selector: row => row.name,
      sortable: true,
      sortFieldId:3,
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.name);        
      }
    },     
    {
      name: 'Indirizzo',
      selector: row => row.address,
      sortFieldId:4,
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.address);        
      }
    }, 
    {
      name: 'CAP',
      selector: row => row.cap,
      sortFieldId:5,
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.cap);        
      }
    }, 
    {
      name: 'Città',
      selector: row => row.city,
      sortFieldId:5,
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.city);        
      }
    },   
    {
      name: 'Azioni',
      maxWidth: '100px',
      right: true,
      ignoreRowClick: true,
      cell: (row, index, column, id) => {
        return (
          <div className='row'>

            <Link to={`/${navPrefix}/account/${accountType}/view/${row?.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <i className="fas fa-eye"></i>
            </Link>

            <Link to={`/${navPrefix}/account/${accountType}/edit/${row?.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <i className="fas fa-pencil-alt"></i>
            </Link>
          </div>)
      }
    }
  ];

  //Setting Page BreadCrumb
  useEffect(() => {

      setPageTitle("Anagrafiche");
      
      setPageBreadcrumbs(["Fornitori"]);
  }, [])

  useEffect(() => {

    getWorkCycleList(1, perPage, filter);

  }, [])

  const getProdViewLink = (rowId, text) => {
    return <Link to={`/${navPrefix}/account/${accountType}/view/${rowId}`} className='text-dark text-hover-primary fs-6'>{text}</Link>;    
  }    

  const handleSort = async (column, sortDirection) => {

    if(sortDirection == "asc" || sortDirection == "")
    {
      setDefaultSortAsc(true);
    }
    else
    {
      setDefaultSortAsc(false);
    }

    setDefaultSortFieldId(column.sortFieldId);

    let sortedFilter = {...filter, sortField:column.sortField, sortDirection, sortDirection};
    setFilter(sortedFilter);

    getWorkCycleList(1, perPage, sortedFilter);
  };  

  const handleFilterClose = () => {
    
    //Se la finestra viene chiusa senza ne applicare ne resettare il filtro, allora i filtri impostati dal momento dell'apertura 
    //della finestra del filtro vengono persi e reimpostati quelli precedentemente applicati
    setFilter(tempFilter);
 
   setModalFilterShow(false);
  }    

  const showFilterModal = () => {

    //Salvataggio in memoria del filtro attuale. 
    //Questo viene fatto perchè se non viene applicato o resettato, il filtro viene reimpostato
    //A quello al momento dell'apertura della finestra

    setTempFilter(filter);

    setModalFilterShow(true);
   }  

   const resetFilter = () => {

    setFilter(emptyFilter);
    getWorkCycleList(1, perPage, emptyFilter);

    setModalFilterShow(false);
   }       

   const applyFilter = () => {
    getWorkCycleList(1, perPage, filter);
    setModalFilterShow(false);
  }  

   const setFilterProperty = (attribute, value, event) => {

      if (attribute == "id") 
      {
        if(event.target.validity.valid)
        {
          setFilter({...filter, id:value});
        }
      }
      else if (attribute == "code") 
      {
          setFilter({...filter, code:value});
      }      
      else if (attribute == "name") 
      {
          setFilter({...filter, name:value});
      }      
   }

   const getWorkCycleListRequest = (page, pageSize, pageFilter)=> {

    let workCycleListRequest =
    {
      idMacchina: idMacchina,
      page: page,
      pageSize: pageSize,
      filterId: pageFilter.id,
      filterCode: pageFilter.code,
      filterName: pageFilter.name,
      filterType: accountType.toUpperCase(),
      sortField: pageFilter.sortField,
      sortDirection: pageFilter.sortDirection
    }

    return workCycleListRequest;
   }

  const getWorkCycleList = (page, pageSize, pageFilter) => {

    setIsLoading(true);

    let isMounted = true;
    const controller = new AbortController();

    let workCycleListRequest = getWorkCycleListRequest(page, pageSize, pageFilter);

    const fetchWorkCycleList = async () => {

      try 
      {
        const response = await axiosPrivate.post(`/customers/getCustomers`,  
        JSON.stringify(workCycleListRequest), 
        {
            headers: {'Content-Type':'application/json'},
            withCredentials: true,
            signal:controller.signal
        });

        //console.log(response.data);
        if (isMounted) {
          setWorkCycles(response.data.data);
          setTotalRows(response.data.total);

          //L'elenco delle righe selezionate viene svuotato ogni volta che c'è un aggiornamento delle righe mostrate
          setSelectedRows([]); 

          setIsLoading(false);
          setIsError(false);
        }

      }
      catch (err) {
        console.error(err);
        setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
        setIsLoading(false);
        setIsError(true);

        // if(err.response?.status === 403) 
        // {
        //     navigate('/login', {state : {from: location }, replace: true});
        // }
      }
    }

    fetchWorkCycleList();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }

  const handlePageChange = page => {
		getWorkCycleList(page, perPage, filter);
    setCurrentPage(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {

    getWorkCycleList(page, newPerPage, filter);
    setPerPage(newPerPage);
	};  

  
  const onSelectedRowsChange = (state) => {

    var selectedRowsArray = [];

    state.selectedRows.forEach(function (tableRow) {
      selectedRowsArray.push(tableRow.id);
    });

    setSelectedRows(selectedRowsArray);
  };  

  const handleConfirmationClose = () => {
    setConfirmationModalShow(false);
  }

  const handleConfirmation = async () => {

    if(selectedRows.length > 0)
    {
      setConfirmationModalShow(false);

      setLoadingSave(true);
      setSaveInProgress(true);
  
      //Impostazione dell'id macchina per cui creare il ciclo di lavoro
  
      try 
      {
  
  
  
          const response = await axiosPrivate.post(`/customers/delete`,
              JSON.stringify(selectedRows),
              {
                  headers: { 'Content-Type': 'application/json' },
                  withCredentials: true,
              }
          );
  
          setModalMessage("Operazione avvenuta con successo");
          setLoadingSave(false);
          setSaveInProgress(false);
          setSaveResult(true);
          setModalShow(true);
      }
      catch (err) 
      {
          console.error(err);
          setSaveResult(false);
          setModalMessage(`${err?.response?.data?.message}`);
          setLoadingSave(false);
          setSaveInProgress(false);
          setModalShow(true);
      }
    }
}

const setLoadingSave = (isLoading) => {
  if (isLoading) {
      setShowIndicatorProgress('on');
  }
  else {
      setShowIndicatorProgress('off');
  }
}

const handleClose = () => {
  if (saveResult == true) 
  {
      setModalShow(false);
      getWorkCycleList(1, perPage, filter);
  }
  else 
  {
      setModalShow(false);
  }
}

  return (
    <div id="kt_content_container" className="mx-5">

      {/* begin::Spinner */}
      {isLoading &&
        <div className="row d-flex justify-content-center m-20">
          <Spinner animation="border" variant="primary" />
        </div>
      }
      {/* end::Spinner */}

      {/* begin::Error message */}
      {!isLoading && isError &&

        <div className="row d-flex justify-content-center m-20">

          <div className="alert alert-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

            <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
              </svg>
            </span>

            <div className="text-center">
              <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
              <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
              <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
            </div>
          </div>

        </div>

      }
      {/* end::Error message */}

      {/* begin::Table */}
      {!isLoading && !isError &&
        <>

          <div className='card mb-5 mt-5 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Elenco Fornitori</span>
              </h3>
              <div className="card-toolbar">
{/* 
              <div
                onClick={(e) => setConfirmationModalShow(true)}
                className="btn btn-danger m-1"
                data-kt-indicator={showIndicatorProgress}>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-solid fa-trash"></i>
                  </span>                  
                <span className="indicator-label">Elimina</span>
                <span className="indicator-progress" >Caricamento...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </div>         */}

                <div className='btn btn-primary me-2 mt-1' onClick={(e) => showFilterModal()}>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-search"></i>
                  </span>
                  Filtro
                </div>

                <div className='btn btn-secondary me-2 mt-1' onClick={(e) => getWorkCycleList(1, perPage, filter)}>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-sync"></i>
                  </span>
                  Aggiorna
                </div>
                                
                <Link to={`/${navPrefix}/account/${accountType}/create/0`} className='btn btn-success  mt-1'>
                  <span className="svg-icon svg-icon-1">
                    <i className="fas fa-solid fa-plus"></i>
                  </span>
                  Nuovo
                </Link>

              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>

              <div className='row mb-2'>
                <DataTable
                  columns={columns}
                  data={workCycles}
                  pagination
                  paginationServer

                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationDefaultPage={currentPage}
                  paginationPerPage={perPage}
                  paginationComponentOptions={paginationComponentOptions}
                  paginationRowsPerPageOptions={[10, 25, 50, 100]}

                  onSort={handleSort}
                  sortServer
                  defaultSortFieldId={defaultSortFieldId}
                  defaultSortAsc={defaultSortAsc}

                  highlightOnHover
                  striped

                  // selectableRows
                  // selectableRowsVisibleOnly
                  // selectableRowsHighlight
                  // selectableRowsNoSelectAll
                  // onSelectedRowsChange={onSelectedRowsChange}

                />
              </div>
            </div>
          </div>
        </>
      }

      <Modal show={modalFilterShow} onHide={handleFilterClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filtri di Ricerca</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">

            <div className="row">
              <div className="mb-10 col-12">
                <label className="form-label">ID</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="ID"
                  pattern="[0-9]*"
                  value={filter?.id} 
                  onChange={(e) => setFilterProperty('id', e.target.value, e)}
                  />
              </div>

            </div>

            <div className="row">
              <div className="mb-10 col-12">

              <label className="form-label">Codice</label>
                  <input type="text"
                    className='form-control form-control-solid'
                    placeholder="Codice"
                    value={filter?.code} 
                    onChange={(e) => setFilterProperty('code', e.target.value, e)}
                    />
                </div> 

              <div className="mb-10 col-12">

              <label className="form-label">Ragione sociale</label>
                  <input type="text"
                    className='form-control form-control-solid'
                    placeholder="Ragione sociale"
                    value={filter?.name} 
                    onChange={(e) => setFilterProperty('name', e.target.value, e)}
                    />
                </div>              
              </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetFilter}>
            Reset
          </Button> 
          <Button variant="primary" onClick={applyFilter}>
            Applica
          </Button>         
        </Modal.Footer>
      </Modal>   

      {/* START - Confirmation Modal */}
      <Modal show={confirmationModalShow} onHide={handleConfirmationClose}>
          <Modal.Header closeButton>
              <Modal.Title>Conferma Operazione</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p>Sei sicuro di voler continuare?</p>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={handleConfirmationClose}>Annulla</Button>
              <Button variant="primary" onClick={handleConfirmation}>Conferma</Button>
          </Modal.Footer>
      </Modal>
      {/* END - Confirmation Modal */}         

<Modal show={modalShow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Esito Salvataggio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {saveResult &&
                            <div className="row d-flex justify-content-center">

                                <div className="d-flex flex-center flex-column py-10 px-10 px-lg-20">

                                    <span className="svg-icon svg-icon-2hx svg-icon-success mb-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                            <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                        </svg>
                                    </span>

                                    <div className="text-center">
                                        {/* <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div> */}
                                        <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: modalMessage }}></div>
                                    </div>
                                </div>

                            </div>
                        }

                        {!saveResult &&
                            <div className="row d-flex justify-content-center">

                                <div className="d-flex flex-center flex-column py-10 px-10 px-lg-20">

                                    <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                            <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                        </svg>
                                    </span>

                                    <div className="text-center">
                                        <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                        <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                        <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: modalMessage }}></div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>           

    </div>
  )
}

export default AccountList