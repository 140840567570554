import { useNavigate, Link } from "react-router-dom";
import {Outlet, useParams} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../../_metronic/helpers'
import clsx from 'clsx'

const MachineNavigation = () => 
{
    const {pathname} = useLocation()
    let { idMacchina } = useParams();
    let { codiceMacchina } = useParams();
    
    let dashBoardUrl = `/macchina/${idMacchina}/${codiceMacchina}/dashboard`;
    //let comandiUrl = `/macchina/${idMacchina}/th004/comandi`;
    let ordiniUrl = `/produzione/${idMacchina}/${codiceMacchina}/lavorazioni`;
    const isDashboardActive = checkIsTabDashboardActive(pathname, dashBoardUrl);
    //const isComandiActive = checkIsTabComandiActive(pathname);
    const isOrdiniActive = checkIsTabOrdiniActive(pathname);

    return (
        <>
            {/* <!--begin::Container--> */}
                <div 
                className="container-fluid d-flex align-items-center bg-white mytoolbar">
                
                    {/* <div className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"> */}
                                
                        <div className="d-flex overflow-auto h-55px">
                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                                <li className="nav-item"><Link to={dashBoardUrl} className={clsx('nav-link text-active-primary me-6', { active: isDashboardActive})}>Dashboard</Link></li>
                                {/* <li className="nav-item"><Link to={comandiUrl} className={clsx('nav-link text-active-primary me-6', { active: isComandiActive})}>Comandi</Link></li> */}
                                <li className="nav-item"><Link to={ordiniUrl} className={clsx('nav-link text-active-primary me-6', { active: isOrdiniActive})}>Lavorazioni</Link></li>
                            </ul>
                        </div>                                    

                    {/* </div> */}
                </div>
            {/* <!--end::Container--> */}
        <Outlet/>
        </>

    )
}

// function checkIsTabComandiActive(pathname) 
// {
//     if (!pathname) 
//     {
//       return false;
//     }

//     if(pathname.indexOf("comandi") > -1 || pathname.indexOf("creaComando") > -1)
//     {
//         return true;
//     }
  
//     return false;
// }

  function checkIsTabOrdiniActive(pathname) {
    if (!pathname) {
      return false;
    }

    if(pathname.indexOf("lavorazioni") > -1 || pathname.indexOf("lavorazione") > -1 || pathname.indexOf("modificaLavorazione") > -1 || pathname.indexOf("nuovaLavorazione") > -1)
    {
        return true;
    }
  
    return false;
  }

  function checkIsTabDashboardActive(pathname) {
    if (!pathname) {
      return false;
    }

    if(pathname.indexOf("dashboard") > -1)
    {
        return true;
    }
  
    return false;
  }


export default MachineNavigation