
export async function getBatchDetails(axiosPrivate, batchNumber)
{
    let output = null;

    const controller = new AbortController();

    try 
    {
        const response = await axiosPrivate.post(`/inventoryBatch/getBatchDetails`,
            {"batchNumber":batchNumber},
            {
                headers: { 'Content-Type': 'application/json' }, 
                withCredentials: true,
                signal:controller.signal
            }
        );

        if(response.data != "")
        {
            output = response.data;
        }
    }
    catch (err) 
    {
        console.error(err);
    }   

    return output;
}

export async function getProductionOrderDetails(axiosPrivate, orderNumber)
{
    let output = null;

    const controller = new AbortController();

    try 
    {
        var payload = { orderNumber: orderNumber};
        const response = await axiosPrivate.post(`/production/getProductionOrderByNumber`,
            JSON.stringify(payload),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
                signal:controller.signal
            }
        );

        if(response.data != "")
        {
            output = response.data;
        }
    }
    catch (err) 
    {
        console.error(err);
    }   

    return output;
}

export async function getArticleBySearchCode(axiosPrivate, searchCode)
{
    let output = null;

    const controller = new AbortController();

    try 
    {
        let payload = {searchCode:searchCode};
        
        const response = await axiosPrivate.post(`/inventoryMovement/getArticleBySearchCode`,
            JSON.stringify(payload),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true,
                signal:controller.signal
            }
        );

        if(response.data != "")
        {
            output = response.data;
        }
    }
    catch (err) 
    {
        console.error(err);
    }   

    return output;
}


export function updateTotalQuantity(entityModel, setQuantityTotal)
{
    var quantity1 = 0;
    var quantity2 = 0;
    var quantity3 = 0;
    var quantity4 = 0;
    var quantity0 = 0;
    var quantitywaste = 0;

    if(entityModel != null)
    {
        quantity1 = getIntValue(entityModel.quantity1.value);
        quantity2 = getIntValue(entityModel.quantity2.value);
        quantity3 = getIntValue(entityModel.quantity3.value);
        quantity4 = getIntValue(entityModel.quantity4.value);
        quantity0 = getIntValue(entityModel.quantity.value);
        quantity0 = getIntValue(entityModel.quantity.value);
        quantitywaste = getIntValue(entityModel.waste.value);
    }

    var output = quantity1 + quantity2 + quantity3 + quantity4 + quantity0 + quantitywaste;

    setQuantityTotal(output);
}

function getIntValue(text)
{
    var output = 0;

    if(!isEmpty(text))
    {
        output = parseInt(text);
    } 

    return output;
}

function isEmpty(value) 
{
    return (value == null || (typeof value === "string" && value.trim().length === 0));
}
